import React, { Component, createRef, forwardRef } from 'react';
import ImagesTypeSelection from './ReusableComp/ImagesTypeSelection';
import circleLeft from '../../icons/prev.png';
import circleRight from '../../icons/next.png';
import { connect } from "react-redux";
import * as API from '../../api.js';
import trashIcon from '../../assets/Icons/trash.svg';
import closeButton from '../../close-button.png';
import detailsIcon from '../../assets/Icons/setDetails.png'
import classesIcon from '../../assets/Icons/setClasses.png'
import summaryIcon from '../../assets/Icons/setSummary.png'
import Popup from "reactjs-popup";
import Loader from '../../icons/loader.svg';
import thumb from "../../assets/map_placeholder.png";
import { addUserAnalytic, updateOutTime, getLocalStorageValue, skipTrainingTaskTour, skipDetectionTaskTour, skipAnnotationTaskTour, generateTIFFUrl, debounceFn } from '../../ReusableComponents/reusableFunctions';
import LocationIcon from '../../res/locationwhite.png';
import ViewImagesOnMap from '../../ReusableComponents/inspectOnMap';
import ViewImageInList from '../../ReusableComponents/viewImageInList';
import menuIcon from "../../assets/Icons/menu.png";
import Dropdown from 'react-bootstrap/Dropdown';
import Noimageset from '../../assets/Images/Noimageset.png';
import MultiFileUploader from "./aeroCaptureMultiFileUploader";
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import Tiff from 'tiff.js'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Search from "../../Icon/Search.png";
import listViewIcon from '../../icons/listview.png'
import listViewActiveIcon from '../../icons/listViewActiveIcon.png'
import gridViewIcon from '../../icons/gridview.png'
import gridViewActiveIcon from '../../icons/gridViewActiveIcon.png'
import defaultThumbnail from '../../icons/defaultthumbnail.png'
import mapViewIcon from '../../assets/sideBarIcons/PlanInactive.png'
import mapViewActive from '../../assets/sideBarIcons/mapView.png'
import AppConstants, { BLOB_URL } from '../../AppConstants';
import ReactJoyride, { STATUS } from 'react-joyride';
import MapAnnotation from './MapAnnotation';
import { v4 as uuid } from 'uuid';
import { PICSTORK_FEATURES } from '../../Teams';
import { StorageLimitExceed, getStorageUserByService } from '../../subscription/UserSubscription';
import MapDSMToTaskImage from './MapDSMToTaskImage.js';
import { Popup as CustomPopup } from "../../ReusableComponents/reusableFunctions.js";
import DetectionArea from './MapAnnotation/DetectionArea.js';
import { load } from 'exifreader';
import VideoPlayer from './ReusableComp/VideoPlayer.js';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={menuIcon} alt="img error" />
    </a>
));

class Images extends Component {
    constructor(props) {
        super()
        this.detectionAreaToolRef = createRef()

        this.state = {
            user: {},
            type: "",
            progress: 0,
            showCheckboxes: false,
            selectedImageCount: 0,
            Idx: 0,
            userperpage: 1000000,
            currentpage: 1,
            outId: "",
            userdisplay: [],
            showDownloadPopup: false,
            loader: true,
            services: [],
            counts: 0,
            projects: [],
            collection_name: '',
            task: [],
            images: [],
            typeimg: '',
            reports: [
            ],
            selecttedImages: [],
            showBackIcon: true,
            cancel: false,
            loading: true,
            listLoading: true,
            imageset: '',
            scale: 0,
            mapView: false,
            listview: false,
            gridView: true,
            imageLoad: false,
            steps: [{
                title: 'Details',
                icon: detailsIcon,
            }, {
                title: 'Accuracy',
                icon: classesIcon

            }, {
                title: 'Summary',
                icon: summaryIcon

            }],
            currentStep: 0,
            isloading: false,
            createTaskMsg: "",
            collection_name: "",
            imageCount: 0,
            taskType: null,
            showAddTaskPopup: false,
            addDetectionTaskPopup: false,
            addTrainingTaskPopup: false,
            models: [],
            videoTask: false,
            video_id: "",
            fps: 1,
            selectedModels: [],
            media_name: "",
            media_id: null,
            scrollHeight: "180px",
            collections: [],
            videos: [],
            itemName: "",
            showProcessPopup: false,
            processPopupIcon: "",
            processAction: "",
            processMessage: "",
            thumbURLs: [],
            imageURL: "",
            uploadPercent: 0,
            hasMoreItems: true,
            key: "",
            sortType: 'Last Modified',
            selectImagePopup: false,
            annotation_set_name: "",
            annotationSets: [],
            accuracy: "",
            classes: [],
            taskStatus: "No Task",
            refresh: false,
            isTaskPresent: false,
            task_name: '',
            addAnnotationTaskPopup: false,
            redirectToTaskPage: false,
            redirectToReportPage: false,
            collectionId: '',
            classArray: [],
            classError: '',
            class: '',
            messageColor: false,
            taskPurpose: "Counting",
            imageFileType: null,
            filteredModels: [],
            unfilteredModels: [],
            imageRideTrigger: false,
            showStorageExtendeedPopup: false,
            showWarningPopup: undefined,
            tourSteps: [{
                disableBeacon: true,
                target: '#uploadImages',
                title: (<div style={AppConstants.TITLESTYLING}>Upload Images</div>),
                content: (<div style={AppConstants.TEXTSTYLING}><p>Click here to upload Images</p></div>),
                placement: 'left',
                styles: {
                    options: {
                        width: 250,
                    },
                },
            },

            ],

        }
        this.debouncedSearch = debounceFn(this.debouncedSearch.bind(this), 300);
    }
    saveDetectionAreas = (calledFromOutside) => {
        if (this.detectionAreaToolRef.current) {
            this.detectionAreaToolRef.current.saveDetectionAreas(calledFromOutside)
        }
    }
    // function to load tiff thumbnails
    loadThumbTiff = async (images, imageFileType) => {
        return new Promise(async (resolve, reject) => {
            for (let i = 0; i < images.length; i++) {
                if (images[i].file_name) {
                    const url = imageFileType === AppConstants.FILETYPES.TIF ?
                        `${AppConstants.tilesServer}/getTifPng/${this.state.collection.id}/${images[i].file_name}/thumb.png?blobContainer=${this.props.permissions?.container}&sas=${encodeURIComponent(this.props.permissions?.st)}` :
                        `${BLOB_URL}/${this.props.permissions?.container}/FlightImages/collection-${this.state.collection.id}/thumb-${images[i].file_name}?${this.props.permissions?.st}`
                    images[i].thumbURL = url
                }
                if (images.length - 1 == i) {
                    resolve(images)
                }
            }

        })

    }

    cancelDelete = () => {
        this.state.images.forEach((image) => {
            image.checked = false
        })
        this.setState((state) => ({
            ...state,
            showCheckboxes: false,
            selectedImageCount: 0,
        }))
    }

    // to load tiff image 
    loadTiffImageSrc = (i) => {
        try {

            let { images, user } = this.state
            var imageURL = ""
            if (images[i].file_name) {

                let name = images[i].file_name.toLowerCase()
                if ((name.indexOf(".tif")) == -1) {
                    let iurl = `${BLOB_URL}/${this.props.permissions?.container}/FlightImages/collection-${this.state.collection.id}/${images[i].file_name}?${this.props.permissions?.st}`
                    imageURL = iurl
                    this.setState((state) => ({
                        ...state,
                        mapLoad: false,
                        imageURL: imageURL,
                    }))
                } else {
                    // for image
                    var xhr = new XMLHttpRequest();
                    xhr.responseType = 'arraybuffer';
                    let url = `${BLOB_URL}/${this.props.permissions?.container}/FlightImages/collection-${this.state.collection.id}/${images[i].file_name}?${this.props.permissions?.st}`
                    xhr.open('GET', url);
                    xhr.onload = function (e) {
                        try {

                            var arraybuffer = e.target.response
                            Tiff.initialize({
                                TOTAL_MEMORY: 16777216 * 10
                            });
                            var tiff = new Tiff({
                                buffer: arraybuffer
                            })
                            var dataUrl = tiff.toDataURL();
                            let url = e.target.responseURL
                            var index = 0
                            this.state.images.map((image, j) => {
                                if (url.indexOf(encodeURIComponent(image.file_name)) != -1) {
                                    index = j
                                }
                            })
                            imageURL = dataUrl   // to insert url at specific position
                            this.setState((state) => ({
                                ...state,
                                mapLoad: false,
                                imageLoad: false,
                                imageURL: imageURL,
                            }))
                        } catch (err) {
                            console.log(err)
                        }
                    }.bind(this);
                    xhr.send()
                }
            }
        } catch (err) {
            console.log(err)
            CustomPopup.alert("ERROR", "Something went wrong while displaying orthomosaic")
        }
    }
    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null
        }))
    }

    zoomIn = () => {
        if (this.state.scale < 15) {
            var myImg = document.getElementById("action-image")
            var currWidth = myImg.clientWidth;
            var currHeight = myImg.clientHeight;
            myImg.style.width = (currWidth + 100) + "px";
            this.setState((state) => ({
                ...state,
                scale: this.state.scale + 1
            }))
            myImg.style.height = (currHeight + 100) + "px";
        }
    }

    zoomOut = () => {
        if (this.state.scale != 0) {
            var myImg = document.getElementById("action-image")
            var currWidth = myImg.clientWidth;
            var currHeight = myImg.clientHeight;
            this.setState((state) => ({
                ...state,
                scale: this.state.scale - 1
            }))
            myImg.style.width = (currWidth - 100) + "px";
            myImg.style.height = (currHeight - 100) + "px";
        }

    }

    checkExtention = (fileName, extArray) => {
        const isMatch = extArray.some(extension => fileName.includes(extension));
        return isMatch
    }

    getImages = async () => {
        return new Promise(async (resolve) => {
            let { user, type, typeimg, userdisplay, currentpage, userperpage, key, counts, requestStatus } = this.state;
            const lastIndex = currentpage * userperpage;
            const firstpage = lastIndex - userperpage;
            const currentuser = userdisplay.slice(firstpage, lastIndex);
            const totalpages = userdisplay.length / userperpage;
            key = key == "" ? " " : key
            API.getImageCollectionImages(this.state.collection.id, key, userperpage, firstpage, typeimg.toLowerCase()).then((data) => {

                if (data.images.length > 0) {

                    let imgName = data?.images[0].file_name
                    let tifExt = ['.tif', '.tiff'];
                    let imgExt = ['.png', '.jpg', '.jpeg', '.jpe', '.jif', '.jfif', '.jfi', '.mp4'];

                    if (this.props?.fromTrainingTask) {
                        if (this.checkExtention(imgName.toLowerCase(), tifExt) && this.props?.fromTrainingTask?.isTiff) {
                            // this.getImgFunc(data)
                        }
                        else if (this.checkExtention(imgName.toLowerCase(), imgExt) && !(this.props?.fromTrainingTask?.isTiff)) {
                            // this.getImgFunc(data)
                        }
                        else {
                            this.setState({
                                showWarningPopup: {
                                    item: "ERROR",
                                    open: true,
                                    icon: 'ERROR',
                                    msg: 'The detector you have selected is not compatible with the type of image you have chosen. Please choose a different detector or image type.'
                                }
                            })
                        }
                    }

                    setTimeout(() => {
                        if ((this.props.taskType === 1 && getLocalStorageValue('imageSetTour1') == 1) || (this.props.taskType === 2 && getLocalStorageValue('imageSetTour2') == 1) || (this.props.taskType === 4 && getLocalStorageValue('imageSetTour3') == 1)) {
                            this.setState({
                                tourSteps: [{
                                    disableBeacon: true,
                                    target: '#button-step2',
                                    content: this.props.taskType === 1 ? (<div style={AppConstants.TEXTSTYLING}><p>Click here to start annotating the images</p></div>) : this.props.taskType === 2 ? (<div style={AppConstants.TEXTSTYLING}><p>Setup various parameters of task</p></div>) : (<div style={AppConstants.TEXTSTYLING}><p>Setup various parameters of task</p></div>),
                                    title: this.props.taskType === 1 ? (<div style={AppConstants.TITLESTYLING}>Start Annotation</div>) : this.props.taskType === 2 ? (<div style={AppConstants.TITLESTYLING}>Setup Parameters</div>) : (<div style={AppConstants.TITLESTYLING}>Setup Parameters</div>),
                                    placement: 'right',
                                    styles: {
                                        options: {
                                            width: 250,
                                        },
                                    },
                                }],
                                imageRideTrigger: true,
                            })
                        }
                    }, AppConstants.TRIGGER_DELAY)

                    if (this.state.uploadPercent >= 100 || this.state.uploadPercent == 0) {
                        const imageFileType = (data.images[0].file_name.toLowerCase()).includes("jpg") || (data.images[0].file_name.toLowerCase()).includes("jpeg") || (data.images[0].file_name.toLowerCase()).includes("png") ? AppConstants.FILETYPES.PNGJPEG : ((data.images[0].file_name.toLowerCase()).includes("tif") || (data.images[0].file_name.toLowerCase()).includes("tiff") ? AppConstants.FILETYPES.TIF : (data.images[0].file_name.toLowerCase()).includes(".mp4") ? AppConstants.FILETYPES.VIDEO : undefined)
                        this.props.updateImagesCount(true, data && data.images ? data.images.length : 0, imageFileType === AppConstants.FILETYPES.TIF ? (this.state.map_task_id || uuid()) : null)
                        this.loadThumbTiff(data.images, imageFileType).then((tiffImg) => {
                            this.setState((state) => ({
                                ...state,
                                isOrthoFile: imageFileType === AppConstants.FILETYPES.TIF,
                                loading: false,
                                images: tiffImg,
                                sasToken: data.sasToken,
                                counts: data.totalCount,
                                showCheckboxes: false,
                                selectedImageCount: 0,
                                imageFileType,
                                isTaskPresent: data.tasksCount > 0,
                                showProcessPopup: this.state.keepOpenPopup ? true : false,
                            }), () => {
                                if (this.state.sortType === 'A-Z') {
                                    this.sortByName()
                                } else if (this.state.sortType === 'Z-A') {
                                    this.reverseByName()
                                } else if (this.state.sortType === 'First Modified') {
                                    this.sortByDate()
                                } else if (this.state.sortType === 'Last Modified') {
                                    this.reverseByDate()
                                }
                                if (this.state.mapView) {
                                    this.setState((state) => ({
                                        ...state,
                                        mapView: false
                                    }), () => {
                                        this.setState((state) => ({
                                            ...state,
                                            mapView: true
                                        }))
                                    })
                                }
                                resolve()
                            })
                        })
                    }
                } else {
                    this.props.updateImagesCount(data && data.images.length > 0 ? true : false, data && data.images ? data.images.length : 0, null)
                    this.props.updateTaskState(2)
                    this.setState((state) => ({
                        ...state,
                        loading: false,
                        showProcessPopup: this.state.keepOpenPopup ? true : false,
                        images: [],
                        normalImages: false,
                        imageFileType: undefined,
                        // sasToken: '',
                        counts: 0,
                        // showCheckboxes: false,
                        selectedImageCount: 0,
                    }), () => {
                        setTimeout(() => {
                            if (this.props.taskType === 1 && getLocalStorageValue('imageSetTour1') == 1) {
                                this.setState((state) => ({
                                    ...state,
                                    imageRideTrigger: true
                                }))
                            } else if (this.props.taskType === 2 && getLocalStorageValue('imageSetTour2') == 1) {
                                this.setState((state) => ({
                                    ...state,
                                    imageRideTrigger: true
                                }))
                            }
                            else if (this.props.taskType === 4 && getLocalStorageValue('imageSetTour3') == 1) {
                                this.setState((state) => ({
                                    ...state,
                                    imageRideTrigger: true
                                }))
                            }
                            resolve()
                        }, AppConstants.TRIGGER_DELAY)
                    })
                }

            }, (e) => {
                console.log(e);
                alert('Something went wrong')
                this.setState((state) => ({
                    ...state,
                    loading: false,
                    listLoading: false,
                    showProcessPopup: this.state.keepOpenPopup ? true : false,
                }))
                resolve()
            })
        })
    }


    onChangeSortEvent = (sortType) => {
        if (sortType === 'Last Modified') {
            this.reverseByDate()
        } else if (sortType === 'First Modified') {
            this.sortByDate()
        } else if (sortType === 'A-Z') {
            this.sortByName()
        } else if (sortType === 'Z-A') {
            this.reverseByName()
        }
    }

    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.images.sort((a, b) => a.file_name.localeCompare(b.file_name))
        this.setState((state) => ({
            ...state,
            images: sort,
        }));

    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.images.sort((a, b) => b.file_name.localeCompare(a.file_name))
        this.setState((state) => ({
            ...state,
            images: sort,
        }));
    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        let sort = this.state.images.sort((a, b) => a.created_on.localeCompare(b.created_on))
        this.setState((state) => ({
            ...state,
            images: sort,
        }));
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        let sort = this.state.images.sort((a, b) => b.created_on.localeCompare(a.created_on))
        this.setState((state) => ({
            ...state,
            images: sort,
        }));
    }


    nextImg = () => {
        this.setState({
            ...this.state,
            imageLoad: true,
            mapLoad: true,
            Idx: this.state.Idx + 1
        }, () => this.loadTiffImageSrc(this.state.Idx))
    }

    prevImg = () => {
        this.setState({
            ...this.state,
            imageLoad: true,
            mapLoad: true,
            Idx: this.state.Idx - 1
        }, () => this.loadTiffImageSrc(this.state.Idx))
    }
    openImage = (idx) => {
        if (this.state.task.tasktype == 2 && this.state.imageFileType !== AppConstants.FILETYPES.VIDEO) {
            this.setState({ enableDetectionAreaDrawingTool: true })
        } else {
            this.setState((state) => ({
                ...state,
                Idx: idx,
                imageLoad: this.state.imageFileType === AppConstants.FILETYPES.TIF || this.state.imageFileType == AppConstants.FILETYPES.VIDEO ? false : true,
                scale: 0,

            }), () => {
                this.loadTiffImageSrc(this.state.Idx)
                var style = document.createElement('style');
                style.type = 'text/css';
                style.innerHTML = '.popup-content { width: 800px !important; border-radius:15px; }';
                document.getElementsByTagName('head')[0].appendChild(style);
                document.getElementById("view-image-popup-button").click()
            })
        }

    }

    deleteImages = async () => {
        this.setState((state) => ({
            ...state,
            Idx: 0,
            itemName: "Images",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
            loading: true,
            listLoading: true,
        }))
        let temp = [];
        let counter = 0;
        await this.state.images.forEach(img => {
            if (img.checked == true) {
                temp.push(img);
            }
            counter++;
            if (counter == this.state.images.length) {
                return temp
            }
        });
        let images = temp
        let collection_id = this.state.collection.id;
        API.deleteImages(collection_id, images, AppConstants.SERVICES.PICSTORK).then((data) => {
            this.setState((state) => ({
                ...state,
                itemName: "Images",
                processPopupIcon: "COMPLETE",
                processAction: "DELETE",
                processMessage: null,
                showProcessPopup: true,
                showCheckboxes: false,
                imageFileType: this.props.data.task.tasktype === 4 ? AppConstants.FILETYPES.PNGJPEG : undefined
            }), async () => {
                // this.props.updateImagesCount(false, data && data.images ? data.images.length : 0, null)
                await this.getImages()
                await this.getDSMImages();
                this.setState({ loading: false, listLoading: false, })
                // setTimeout(() => {
                //     document.getElementById("delete-images-popup-button").click();
                // }, 3000);
            })
        }, (e) => {
            this.setState((state) => ({
                ...state,
                itemName: "Images",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }

    //get dsm images
    getDSMImages = () => {
        return new Promise((resolve, reject) => {
            if (this.state.task.tasktype == 2 || this.state.task.tasktype == 4) {

                API.getDsmImages(this.state.collection.id, this.state.key).then(res => {
                    if (this.state.isOrthoFile) {
                        this.setState(state => {

                            let newTask = { ...state.task };
                            let newImages = [...state.images];
                            let oldTaskParameters = { ...state.task.task_parameters };

                            // Append DSM parameters
                            if (res.dsm_images?.length > 0) {
                                const dsm_url = generateTIFFUrl(res.dsm_images[0]?.url, "dsm");
                                oldTaskParameters.dsm_url = dsm_url;

                                if (newImages.length == 0 || newImages.find(i => i.id !== res.dsm_images[0].id)) {
                                    newImages.push({ ...res.dsm_images[0], isDsmImage: true });
                                }
                            } else {
                                oldTaskParameters.dsm_url = null;
                            }

                            // Append DTM parameters
                            if (res.dtm_images?.length > 0) {
                                const dtm_url = generateTIFFUrl(res.dtm_images[0]?.url, "dtm");
                                oldTaskParameters.dtm_url = dtm_url;
                                if (newImages.length == 0 || newImages.find(i => i.id !== res.dtm_images[0].id)) {
                                    newImages.push({ ...res.dtm_images[0], isDtmImage: true });
                                }
                            } else {
                                oldTaskParameters.dtm_url = null;
                            }
                            // Update the task and state
                            newTask.task_parameters = oldTaskParameters;
                            newTask.linked_map = (this.state.map_task_id || uuid())
                            return ({
                                ...state,
                                task: newTask,
                                images: newImages,
                                isloading: false,
                                dsm_available: res.dsm_images?.length > 0,
                                dtm_available: res.dtm_images?.length > 0,
                                counts: newImages.length
                            })
                        }, () => {

                            this.props.updateTask(this.state.task);
                            this.props.updateImagesCount(false, this.state.images.length, (this.state.map_task_id || uuid()), this.state.task)
                            resolve()
                        })
                    } else resolve()

                }).catch((err) => {
                    console.log("err", err);
                    this.setState({ isloading: false });
                    CustomPopup.alert("ERROR", "An error occur while getting dsm images");
                    reject(err)
                })
            } else resolve()

        });

    }
    componentWillUnmount() {
        updateOutTime(this.state.outId)
    }

    componentWillMount() {
        addUserAnalytic("picstork/taskCreationImages")
            .then(id => {
                this.setState((state) => ({
                    outId: id
                }))
            })
        document.title = "PicStork - Images";
        if (this.props.data) {
            let { user, type, task, project, plan, collection } = this.props.data;
            this.setState((state) => ({
                ...state,
                user,
                type,
                task,
                project,
                plan,
                collection,
                loading: true,
                listLoading: true,
            }), async () => {
                await this.getImages();
                if (this.props.data.task.tasktype == 2 || this.props.data.task.tasktype == 4) {
                    this.setState({ isloading: true })
                    await this.getDSMImages()

                }
                this.setState({ loading: false, listLoading: false, })
                API.getUserSubbedServices().then(
                    (response) => {
                        let services = response[0];
                        if (services.length == 1 && services[0].title == 'AeroStream') {
                            this.setState((state) => ({
                                ...state,
                                showBackIcon: false
                            }))
                        } else {
                            this.setState((state) => ({
                                ...state,
                                showBackIcon: true
                            }))
                        }
                    }
                )
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type,
                    loading: true,
                    listLoading: true,
                }), async () => {
                    await this.getImages()
                    this.setState({ loading: false, listLoading: false, })
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }
    async debouncedSearch() {
        await this.getImages();
        await this.getDSMImages();
        this.setState({
            loading: false,
            listLoading: false,
        });
    }
    //Get SearchKey Function Start Here-----
    search(key) {
        if (key != '.') {
            if (key == '') {
                this.setState((state) => ({
                    ...state,
                    hasMoreItems: true
                }))
            }
            this.setState((state) => ({
                ...state,
                key,
                currentpage: 1,
                listLoading: true,
            }),
                () => {
                    this.debouncedSearch();
                });
        }
    }
    //Get SearchKey Function Ends Here-----
    //Get on First page
    firstpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1
            }), async () => {
                await this.getImages();
            });
        } else {
        }
    }
    // Prev Page function
    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            })
                , async () => {
                    await this.getImages();

                });
        }
    }
    // Next page Function
    nextpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), async () => {
                await this.getImages();
            });
        } else {
        }
    }
    // Get Last Page Function Start here----------------------
    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.counts / this.state.userperpage)
            }), async () => {
                await this.getImages();
            });
        } else {
        }
    }

    updateTourState = (data) => {
        const { action, index, status, type } = data;
        if (this.props.taskType === 1) {
            if ([STATUS.FINISHED].includes(status)) {

                //For existing image set selection
                if (getLocalStorageValue('imageSetTour1') == 1 && this.state.images.length > 0) {
                    localStorage.setItem('imageSetTour1', 3)
                    this.setState({ imageRideTrigger: false })
                }

                //For new Images upload
                else if (getLocalStorageValue('imageSetTour1') == 2 && this.state.uploadPercent >= 100) {
                    localStorage.setItem('imageSetTour1', 3)
                    this.setState({ imageRideTrigger: false })

                }

                //For creation of new image set
                else if (getLocalStorageValue('imageSetTour1') == 1 && this.state.images && this.state.images.length < 1) {
                    localStorage.setItem('imageSetTour1', 2)
                    this.setState({ imageRideTrigger: false })
                }
            } else if ([STATUS.SKIPPED].includes(status)) {
                skipTrainingTaskTour()
                this.setState({ imageRideTrigger: false })
            }
        } if (this.props.taskType === 2) {
            if ([STATUS.FINISHED].includes(status)) {
                if (getLocalStorageValue('imageSetTour2') == 1 && this.state.images.length > 0) {
                    localStorage.setItem('imageSetTour2', 3)
                    this.setState({ imageRideTrigger: false })
                }
                else if (getLocalStorageValue('imageSetTour2') == 2 && this.state.uploadPercent >= 100) {
                    localStorage.setItem('imageSetTour2', 3)
                    this.setState({ imageRideTrigger: false })

                } else if (getLocalStorageValue('imageSetTour2') == 1 && this.state.images && this.state.images.length < 1) {
                    localStorage.setItem('imageSetTour2', 2)
                    this.setState({ imageRideTrigger: false })
                }
            } else if ([STATUS.SKIPPED].includes(status)) {
                skipDetectionTaskTour()
                this.setState({ imageRideTrigger: false })
            }
        } if (this.props.taskType === 4) {
            if ([STATUS.FINISHED].includes(status)) {
                if (getLocalStorageValue('imageSetTour3') == 1 && this.state.images.length > 0) {
                    localStorage.setItem('imageSetTour3', 3)
                    this.setState({ imageRideTrigger: false })
                }
                else if (getLocalStorageValue('imageSetTour3') == 2 && this.state.uploadPercent >= 100) {
                    localStorage.setItem('imageSetTour3', 3)
                    this.setState({ imageRideTrigger: false })

                } else if (getLocalStorageValue('imageSetTour3') == 1 && this.state.images && this.state.images.length < 1) {
                    localStorage.setItem('imageSetTour3', 2)
                    this.setState({ imageRideTrigger: false })
                }
            } else if ([STATUS.SKIPPED].includes(status)) {
                skipAnnotationTaskTour()
                this.setState({ imageRideTrigger: false })
            }
        }
    }


    // Get Last Page Function End here ----------------------------
    // Get Changenumber Page Function Starts here ----------------------------
    changepage(num) {
        if (isNaN(num)) {

            return null;
        }
        else {
            this.setState((state) => ({
                ...state,
                currentpage: num,
            }), async () => await this.getImages());
        }

    }
    handleChange = (event) => {
        this.setState({ collection_name: event.target.value });
    }


    handleChangeCopy = (e) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(e.target.value)) {
            // setError("Spacial characters are not allowed.")
        } else
            if (e.target.value.includes(' ')) {
                // setError("Spaces are not allowed.")
            } else {
                // setError(undefined)
                this.setState({ task_name: e.target.value });
            }
    }


    imageSelectDeleted = (key) => {
        this.state.images[key].checked = !this.state.images[key].checked
        if (this.state.images[key].checked == true) {
            let cnt = this.state.selectedImageCount + 1
            this.setState((state) => ({
                ...state,
                selectedImageCount: cnt
            }))
        } else {
            let cnt = this.state.selectedImageCount - 1
            this.setState((state) => ({
                ...state,
                selectedImageCount: cnt
            }))
        }

    }

    changeClass = (event) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (format.test(event.target.value)) {
            // setError("Spacial characters are not allowed.")
        } else
            if (event.target.value.includes(' ')) {
                // setError("Spaces are not allowed.")
            } else {
                // setError(undefined)
                this.setState({ class: event.target.value.toLowerCase(), classError: "" });
            }
    }
    addClass = () => {
        let arr = this.state.classArray
        let temp = this.state.class
        temp = temp.toLowerCase().trim().replaceAll(" ", "_");
        temp = temp.replaceAll(",", "_")
        let isExist = arr.findIndex(data => data == temp)
        if (arr.length == 10)
            this.setState({ classError: "Maximum 10 classes can be added", class: "" });
        else if (isExist != -1)
            this.setState({ classError: "Class already added", class: "" });
        else if (this.state.class) {
            arr.push(temp)
            this.setState({ classArray: arr, class: "", messageColor: false });
        }
    }
    isTaskInProgress = () => {
        return this.state.task.status !== AppConstants.TASK.STATUS.CREATING && this.state.task.status !== AppConstants.TASK.STATUS.FAILED
    }
    removeClass = (i) => {
        let arr = this.state.classArray
        arr.splice(i, 1)
        this.setState({ classArray: arr, class: "" });
    }

    onSelectTypeForUpload = (selectedFileType, importFrom) => {

        this.setState({
            map_task_id: undefined,
            itemName: undefined,
            showProcessPopup: false,
            processPopupIcon: undefined,
            processMessage: undefined,
            selectedFileType: selectedFileType
        }, () => {

            if (importFrom === "GDRIVE") {

                this.setState({ startGoogleDriveSelection: true })
            }
            else {
                document.getElementById("hiddenFileInput").click()
            }

        })
    }

    canImportFromDN = async (map) => {
        const selectedDataSize = Number(map[0].img_size);
        const serviceStorageObj = await getStorageUserByService(AppConstants.SERVICES.PICSTORK);
        const availableStorage = serviceStorageObj.storageLimit - serviceStorageObj.storageUsed;
        return !(availableStorage < selectedDataSize);
    }

    importSelectedOrtho = async (maps) => {
        return new Promise(async (resolve) => {

            if (await this.canImportFromDN(maps)) API.importMapsIntoCollection(maps, this.state.collection.id).then(data => {
                this.setState({ map_task_id: maps[0].task_id }, async () => {
                    await this.getImages()
                    resolve()
                })
            })
            else this.setState({ showStorageExtendeedPopup: true }, () => {
                resolve()
            })
        })
    }

    isImagesetEditable = () => {
        return ((!(this.isTaskInProgress()) && this.props.permissions?.[PICSTORK_FEATURES.IMAGECOLLECTIONS].DELETE && this.state.images.length !== 0))
    }
    getCards = () => {
        let cards = {
            upload_images: true,
            upload_tiff: true,
            import_from_dn: true,
            import_zip: false,
            import_from_googledrive: false,
            upload_video: true,
        }
        if (this.state.task) {

            if (this.state.task.tasktype == 1) { //Training Task

            } else if (this.state.task.tasktype == 2) { //Detection Task

            }
            else if (this.state.task.tasktype == 4) {//Annotation Task

            }
            else if (this.state.task.tasktype == 3) { //DN Task

            }
        }
        if (this.state.imageFileType === AppConstants.FILETYPES.TIF || this.props.fromTrainingTask?.isTiff) {
            cards.upload_images = false;
            cards.import_zip = false;
            cards.upload_video = false
        } else if (this.state.imageFileType === AppConstants.FILETYPES.PNGJPEG || (this.props.fromTrainingTask && !this.props.fromTrainingTask?.isTiff)) {
            cards.upload_tiff = false;
            cards.import_from_dn = false
            cards.import_from_googledrive = true;
            cards.upload_video = false
        }
         if (this.props.fromTrainingTask ? (!this.props.fromTrainingTask?.isTiff) : this.state.imageFileType === AppConstants.FILETYPES.VIDEO) {
            cards.upload_video = true
        }
        return cards
    }
    render() {
        let { user, type, images, sidebarMode, showBackIcon, collection, plan, project, steps, currentStep, task_name, addDetectionTaskPopup, selectedModels, addAnnotationTaskPopup, media_name, models, annotation_set_name, addTrainingTaskPopup, annotationSets, classes, accuracy, task, classArray, classError, taskPurpose } = this.state
        const listLoader = <div style={{ height: "10vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={Loader} style={{ height: "10vh", width: "50vw", margin: "auto" }} />
        </div>

        return (
            user.username ? <div className="wrapper">
                {
                    this.state.enableDetectionAreaDrawingTool ?
                        <DetectionArea
                            close={() => {
                                this.setState({ enableDetectionAreaDrawingTool: false })
                            }}
                            ref={this.detectionAreaToolRef}
                            isDisabled={this.state.task.state > 3}
                            task={this.state.task}
                            collection={this.props.data.collection}
                            showDetectionArea={true}
                            permissions={this.props.permissions}
                            onOrthoLoad={() => this.setState((state) => ({
                                ...state,
                                imageLoad: false,
                            }))}
                            updateTask={(newTask) => {
                                if (newTask) {
                                    this.setState({ task: newTask })
                                }
                            }}
                            selectedImageForAnnotation={this.state.images[this.state.Idx]}
                            user={this.state.user}
                            goToNextStep={this.props.goToNextStep}
                        />
                        :
                        <div id='tiffloaded' style={{ width: "100%", padding: "10px 20px" }}
                            onClick={
                                () => {
                                    this.setState((state) => ({
                                        ...state, sidebarMode: 'shrunk'
                                    }))
                                }
                            }
                        >
                            <Popup
                                style={{ width: "min-content", zIndex: 99 }}
                                overlayStyle={{
                                    zIndex: 99
                                }}
                                trigger={<button id="view-image-popup-button" style={{
                                    display: "none",
                                }}
                                ></button>}
                                modal
                            ><img
                                    id="close-popup"
                                    src={closeButton} style={{
                                        width: "auto",
                                        height: "25px",
                                        position: "absolute",
                                        top: "-12px",
                                        right: "-12px",
                                        cursor: "pointer"

                                    }} onClick={() => {
                                        var style = document.createElement('style');
                                        style.type = 'text/css';
                                        style.innerHTML = '.popup-content { width: 800px !important; border-radius:15px;}';
                                        document.getElementsByTagName('head')[0].appendChild(style);
                                        document.getElementById("view-image-popup-button").click();
                                        this.setState((state) => ({
                                            ...state,
                                            regions: [],
                                            scale: 0,
                                            imageLoad: false,
                                        }));

                                    }}
                                />

                                <div style={{ display: 'flex' }} >
                                    <div className='flex-center' style={{ alignItems: 'end', background: "#666666", width: "790px", height: '567px', borderRadius: '15px', overflow: "hidden" }}>
                                        {this.state.imageLoad ?
                                            <img className='image-popup' src={Loader} style={{ position: "absolute", zIndex: 5, height: "100%", width: "8vw", marginLeft: "10%" }} /> : ''
                                        }
                                        {this.state.imageFileType === AppConstants.FILETYPES.TIF ?
                                            !this.state.mapLoad && <MapAnnotation
                                                task={this.state.task}
                                                permissions={this.props.permissions}
                                                onOrthoLoad={() => this.setState((state) => ({
                                                    ...state,
                                                    imageLoad: false,
                                                }))}

                                                selectedImageForAnnotation={this.state.images[this.state.Idx]}
                                                user={this.state.user} />
                                            :
                                            this.state.imageFileType === AppConstants.FILETYPES.VIDEO ?
                                                <VideoPlayer
                                                    url={this.state.imageURL}
                                                />
                                                :
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, centerView, resetTransform, ...rest }) => (
                                                        <TransformComponent>
                                                            <img id="action-image" style={{ zIndex: 0, opacity: this.state.imageLoad ? 0 : 1 }} className='image-popup' loading='lazy'
                                                                src={this.state.images && this.state.images.length ? this.state.imageURL : ''}
                                                                onLoad={((load) => {
                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        imageLoad: false,
                                                                    }),
                                                                        centerView(),
                                                                        resetTransform())

                                                                })} />
                                                        </TransformComponent>
                                                    )}
                                                </TransformWrapper>}



                                        {this.state.images && this.state.images.length ?
                                            <div className='imageinfo'>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div className="imagePopupTitle">{images[this.state.Idx].file_name.length > 50 ? images[this.state.Idx].file_name.substring(0, 50) : images[this.state.Idx].file_name}</div>
                                                    <div className="imagePopupSubTitleTile">
                                                        <div className="imagePopupSubTitle">{!images[this.state.Idx].resolution ? "" : this.state.task.linked_map == null || images[this.state.Idx].resolution != '0.00' ? images[this.state.Idx].resolution + 'MP' : ''}</div>
                                                    </div>

                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    {this.state.imageFileType !== AppConstants.FILETYPES.TIF ? <div className="imagePopupSubTitle">
                                                        {images[this.state.Idx].latitude == null || images[this.state.Idx].latitude == "" ? '' : `${images[this.state.Idx].latitude.length > 11 ? `${images[this.state.Idx].latitude.substring(0, 11)}` : `${images[this.state.Idx].latitude}`}`}, {images[this.state.Idx].longitude == null || images[this.state.Idx].longitude == "" ? "-" : `${images[this.state.Idx].longitude.length > 11 ? `${images[this.state.Idx].longitude.substring(0, 11)}` : `${images[this.state.Idx].longitude}`}`}
                                                    </div> : <></>}
                                                    <div className="imagePopupSubTitleTile">
                                                        <div className="imagePopupSubTitle">{!(images[this.state.Idx].file_size) && images[this.state.Idx].file_size == '0.00' ? '' : images[this.state.Idx].file_size + 'MB'} </div>
                                                    </div>
                                                </div>
                                            </div> : ''}

                                        {this.state.images && this.state.images.length > 1 && !this.state.task?.task_parameters?.dsm_url ? < div className='nextprevfunction'>
                                            {
                                                this.state.Idx == 0 ?
                                                    <div className='prevDiv'>
                                                        <img className='prev' src={circleLeft} style={{ opacity: '0.5' }} />
                                                    </div> :
                                                    <div className='prevDiv' onClick={this.prevImg}>
                                                        <img className='prev' src={circleLeft} style={{ ursor: 'pointer' }} />
                                                    </div>

                                            }
                                            {
                                                this.state.Idx == this.state.images.length - 1 ?
                                                    <div className='nextDiv'> <img className='next' src={circleRight} style={{ opacity: '0.5' }}
                                                    /> </div> :
                                                    <div className='nextDiv' onClick={this.nextImg} >
                                                        <img className='next' src={circleRight} style={{ cursor: 'pointer' }} />
                                                    </div>
                                            }

                                        </div> : ''
                                        }
                                    </div>
                                </div>
                            </Popup>

                            {this.state.imageRideTrigger ?
                                <ReactJoyride
                                    continuous={true}
                                    run={true}
                                    hideBackButton={true}
                                    hideCloseButton={true}
                                    showSkipButton={true}
                                    steps={this.state.tourSteps}
                                    disableOverlayClose={true}
                                    floaterProps={AppConstants.FLOATER_PROPS}
                                    locale={AppConstants.LOCALE_BUTTONS}
                                    callback={(e) => this.updateTourState(e)}
                                    styles={{
                                        buttonNext: AppConstants.NEXT_BUTTON,
                                        buttonSkip: AppConstants.SKIP_BUTTON,
                                        spotlight: AppConstants.SPOTLIGHT,
                                        options: AppConstants.MAIN_OPTIONS
                                    }}
                                /> : <></>}

                            {/* showWarningPopup  */}
                            <ActivityPopup
                                item={this.state.showWarningPopup?.item}
                                open={this.state.showWarningPopup?.open}
                                icon={this.state.showWarningPopup?.icon}
                                msg={this.state.showWarningPopup?.msg}
                                close={() => {
                                    document.getElementById("imagesetsClick").click()
                                }}
                            />
                            {/* end showWarningPopup  */}

                            {/* -------------- Activity Popup ----------------------- */}
                            <ActivityPopup
                                item={this.state.itemName}
                                open={this.state.showProcessPopup}
                                icon={this.state.processPopupIcon}
                                action={this.state.processAction}
                                msg={this.state.processMessage}
                                close={() => this.closeActivityPopup()}
                                onClickOk={() => this.state.showCheckboxes ? this.deleteImages() : this.onSelectTypeForUpload(AppConstants.FILETYPES.TIF)}
                            />
                            {/* --------------------------------------------------------------- */}

                            {/* ----------------------------- showStorageExtendeedPopup -------------------- */}
                            {
                                this.state.showStorageExtendeedPopup ?
                                    <StorageLimitExceed seletedDataSize={null} totalStorage={null} onClickOk={() => this.setState({ showStorageExtendeedPopup: false })} /> : <></>
                            }
                            {/* ---------------------------------------------------------------------------- */}

                            {this.state.loading ? <div style={{ height: "85vh", width: "100%", display: "flex", textAlign: "center" }}>
                                <img src={Loader} style={{ height: "30vh", width: "100%", margin: "auto" }} />
                            </div> :
                                <>
                                    {/* <SnackBar user={user} sidebarMode={sidebarMode} type={type} /> */}
                                    <div id='tiffloaded' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "5px" }}>
                                        <div className="services-content-title" style={{ display: "flex", alignItems: "center" }}>
                                            <span className={(this.props.data.task.state == 2 && this.props.permissions?.[PICSTORK_FEATURES.TASKS].EDIT) ? "onhoverext hoverPointer" : ""}
                                                id='imagesetsClick'
                                                onClick={() => {
                                                    if (this.props.data.task.state == 2) {
                                                        if (this.state.task?.task_parameters?.detection_areas?.length > 0) {
                                                            CustomPopup.alert("WARNING", "If you choose another imageset, your drawn detection areas will be cleared,do you want to continue?", () => {
                                                                let newTask = this.state.task;
                                                                const task_parameters = { ...this.state.task.task_parameters, detection_areas: [] }
                                                                newTask.task_parameters = task_parameters
                                                                API.updateTaskDetails(this.state.task.task_id, { task_parameters }).then(res => {
                                                                    this.setState({ task: newTask })
                                                                    if (this.props.updateTask) {
                                                                        this.props.updateTask(newTask)
                                                                    }
                                                                    this.props.setCollection(undefined);
                                                                }).catch(err => {
                                                                    console.log(err)
                                                                })
                                                            })
                                                        } else {
                                                            this.props.setCollection(undefined);
                                                        }
                                                    }
                                                }}
                                                style={{
                                                    color: "#2989cf",
                                                    fontSize: "12px",
                                                }}>{'Imagesets '}&nbsp;</span>
                                            <span style={{
                                                fontSize: "12px",
                                            }}>/</span>&nbsp;{'Images'}
                                            <span className='data-count'>({this.state.counts})</span>
                                            {/* this.state.collection && this.state.collection.collection_name ? this.state.collection.collection_name :  "Images" */}
                                        </div>
                                        <div className="add-user-form-text-wrapper" style={{ display: 'flex', alignItems: 'center', height: '35px' }}
                                        >
                                            <div style={{ marginRight: '20px' }}>
                                                <img src={Search} style={{ height: "20px", width: "20px", position: 'relative', left: '25px' }} />
                                                <input type="text" value={this.state.key} className="add-user-form-text2" placeholder="Search images" style={{ height: '28px', width: "230px", paddingLeft: '28px', border: '1px solid rgba(102, 102, 102, 0.3)' }}
                                                    onChange={(event) => this.search(event.target.value)} />
                                            </div>

                                            <div className="imageViewButton" style={{ display: 'flex', height: '24px', marginRight: '20px' }}  >
                                                <div className={this.state.listview ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ width: "55px", borderRadius: '2px 0px 0px 2px', borderRight: 'solid 1px #2989cf', height: '24px' }}
                                                    onClick={() => {
                                                        this.setState((state) => ({
                                                            ...state,
                                                            listview: true,
                                                            gridView: false,
                                                            mapView: false,

                                                        }))
                                                    }}>
                                                    <div> <img src={this.state.listview ? listViewActiveIcon : listViewIcon} style={{ height: '16px', width: '16px', cursor: 'pointer', margin: '0px 5px' }} /></div>
                                                </div>
                                                <div className={this.state.gridView ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ width: "55px", borderRight: 'solid 1px #2989cf', borderLeft: 'solid 1px #2989cf', height: '24px' }}
                                                    onClick={() => {
                                                        this.setState((state) => ({
                                                            ...state,
                                                            listview: false,
                                                            gridView: true,
                                                            mapView: false,

                                                        }))
                                                    }}
                                                >
                                                    <div><img src={this.state.gridView ? gridViewActiveIcon : gridViewIcon} style={{ height: '16px', width: '16px', cursor: 'pointer', margin: '0px 5px' }} /></div>
                                                </div>

                                                <div className={this.state.mapView ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ opacity: this.state.task.linked_map ? 0.5 : 1, cursor: this.state.task.linked_map ? 'default' : 'pointer', width: "55px", borderRadius: '0px 2px 2px 0px', borderLeft: 'solid 1px #2989cf', height: '24px' }}
                                                    onClick={() => {
                                                        if (!this.state.task.linked_map) {
                                                            this.cancelDelete()
                                                            this.setState((state) => ({
                                                                ...state,
                                                                listview: false,
                                                                gridView: false,
                                                                mapView: true,
                                                            }))
                                                        }
                                                    }}>
                                                    <div><img src={this.state.mapView ? mapViewActive : mapViewIcon} style={{ height: '18px', width: '18px', cursor: 'pointer', margin: '0px 5px' }} /></div>
                                                </div>
                                            </div>

                                            {this.state.showCheckboxes ?
                                                <div className="addcustomer-button-empty-dashboard" style={{ fontSize: '10px', marginRight: '25px', padding: '6px 20px', cursor: 'pointer' }}
                                                    onClick={() => { this.cancelDelete() }}
                                                >Cancel</div>
                                                : <></>
                                            }
                                            {this.state.showCheckboxes ?
                                                <>{this.state.selectedImageCount > 0 ?
                                                    < img src={trashIcon} style={{ height: '24px', width: '24px', cursor: 'pointer', marginRight: '20px', marginBottom: "1px" }}
                                                        onClick={() => {
                                                            this.setState((state) => ({
                                                                ...state,
                                                                itemName: "Images",
                                                                processPopupIcon: "WARNING",
                                                                processAction: "DELETE",
                                                                processMessage: "Are you sure you want to delete these Images ?",
                                                                showProcessPopup: true,
                                                            }))
                                                        }}
                                                    /> : ''}
                                                </> :
                                                <>

                                                    {/* checked validTiff, if yes we are not allowing upload image */}
                                                    {!this.props.permissions?.[PICSTORK_FEATURES.IMAGECOLLECTIONS].EDIT || ((this.isTaskInProgress() || this.state.imageFileType === AppConstants.FILETYPES.TIF) && !this.props.allowMultipleOrtho) ? <></>
                                                        : <>
                                                            <div style={{ display: "none" }}>
                                                                <MultiFileUploader
                                                                    serviceName={AppConstants.SERVICES.PICSTORK}
                                                                    acceptFileType={this.state.imageFileType || this.state.selectedFileType}
                                                                    acceptSingleFileOnly={this.state.selectedFileType == AppConstants.FILETYPES.VIDEO ? true : this.props.allowMultipleOrtho ? false : (this.state.selectedFileType === AppConstants.FILETYPES.TIF)}
                                                                    collection={collection}
                                                                    totalImageCount={this.state.counts}
                                                                    oldFiles={this.state.images}
                                                                    user={this.state.user.userid}
                                                                    isResize={this.state.task.tasktype === 1 && this.props.data.annotationSet && this.state.imageFileType !== AppConstants.FILETYPES.TIF}
                                                                    startJoyrideTour={() => {
                                                                        this.setState({
                                                                            tourSteps: this.props.taskType === 1 ? [{
                                                                                disableBeacon: true,
                                                                                target: '#button-step2',
                                                                                content: (<div style={AppConstants.TEXTSTYLING}><p>Click here to start annotating the images</p></div>),
                                                                                title: (<div style={AppConstants.TITLESTYLING}>Start Annotation</div>),
                                                                                placement: 'right',
                                                                                styles: {
                                                                                    options: {
                                                                                        width: 250,
                                                                                    },
                                                                                },
                                                                            }] : [{
                                                                                disableBeacon: true,
                                                                                target: '#button-step2',
                                                                                content: (<div style={AppConstants.TEXTSTYLING}><p>Setup various parameters of task</p></div>),
                                                                                title: (<div style={AppConstants.TITLESTYLING}>Setup Parameters</div>),
                                                                                placement: 'right',
                                                                                styles: {
                                                                                    options: {
                                                                                        width: 250,
                                                                                    },
                                                                                },
                                                                            }],
                                                                            imageRideTrigger: (
                                                                                (this.props.taskType === 1 && getLocalStorageValue('imageSetTour1') != 3) ||
                                                                                (this.props.taskType === 2 && getLocalStorageValue('imageSetTour2') != 3) ||
                                                                                (this.props.taskType === 4 && getLocalStorageValue('imageSetTour3') != 3)
                                                                            ) ? true : false,
                                                                        })
                                                                    }}
                                                                    openImportPopup={({ showProcessPopup, processPopupIcon, processMessage, keepOpenPopup }) => {
                                                                        this.setState({
                                                                            showProcessPopup,
                                                                            processPopupIcon,
                                                                            processMessage,
                                                                            keepOpenPopup
                                                                        })
                                                                    }}
                                                                    startGoogleDriveSelection={this.state.startGoogleDriveSelection}
                                                                    clearGoogleDriveStates={() => {
                                                                        this.setState({ startGoogleDriveSelection: false })
                                                                    }}
                                                                    callback={(n, files) => { this.setState((prevState) => ({ ...prevState, fileCount: n, images: files })) }}
                                                                    onImageUpload={(uploadPercent) => {
                                                                        if (this.state.task.state <= 2)
                                                                            this.props.updateTaskState(2)
                                                                        this.setState((state) => ({
                                                                            ...state,
                                                                            uploadPercent: uploadPercent
                                                                        }), async () => {
                                                                            await this.getImages()
                                                                            await this.getDSMImages()
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                            {this.state.images.length > 0 && this.state.imageFileType == AppConstants.FILETYPES.VIDEO ? <></> : <ImagesTypeSelection
                                                                AnimatedModalStyle={{ width: 'max-content' }}
                                                                fromTrainingTask={this.props?.fromTrainingTask}
                                                                uploadedImageCount={this.state.images.length}
                                                                collectionId={this.props.collectionId || this.state.collection.id}
                                                                permissions={this.props.permissions}
                                                                disableOrthomosaic={false}
                                                                disableApplicationType={this.props.taskType !== 4}
                                                                uploadOk={() => this.getImages()}
                                                                onSelectTypeForUpload={
                                                                    (fileType, importFrom) => {
                                                                        if (fileType === AppConstants.FILETYPES.TIF) {
                                                                            this.setState({
                                                                                itemName: "WARNING",
                                                                                showProcessPopup: true,
                                                                                processPopupIcon: "WARNING",
                                                                                processMessage: "Make sure you are uploading Cloud Optimized GeoTIFF (COG). Do you want to continue ?",
                                                                            })
                                                                        } else {
                                                                            this.onSelectTypeForUpload(fileType, importFrom)
                                                                        }
                                                                    }}
                                                                importSelectedOrtho={this.importSelectedOrtho}
                                                                isDisabled={!(this.props.permissions?.[PICSTORK_FEATURES.IMAGECOLLECTIONS]?.EDIT) || this.state.task?.state > 4}
                                                                user={this.state.user}
                                                                normalImages={this.state.imageFileType === AppConstants.FILETYPES.PNGJPEG}
                                                                droneNakshaCards={this.props.taskType === 1 ? false : this.state.images.length != 0}
                                                                cards={this.getCards()}
                                                            />}
                                                        </>
                                                    } </>
                                            }
                                            {!this.state.mapView ? <Dropdown style={{}}>
                                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" >
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu size="sm" title=""
                                                    className={this.state.images == 0 ? "custom-dropdown-settings1nodata" : "custom-dropdown-settings1"}
                                                >
                                                    <Dropdown.Item
                                                        style={{
                                                            padding: '0px !important',
                                                            opacity: (this.isImagesetEditable() && !this.props.deleteImgPermission) ? undefined : "0.5",
                                                            cursor: (this.isImagesetEditable() && !this.props.deleteImgPermission) ? "pointer" : "not-allowed"
                                                        }}
                                                        className={images.length != 0 ? "dropdown-set-padding" : "option-disabled"}
                                                    >{<div style={{ fontSize: "12px", color: '#3c3c3c' }}
                                                        onClick={
                                                            () => {
                                                                if (this.isImagesetEditable() && !this.props.deleteImgPermission) {
                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        showCheckboxes: true
                                                                    }))
                                                                }
                                                            }
                                                        }>Delete Images</div>}
                                                    </Dropdown.Item>

                                                </Dropdown.Menu>
                                            </Dropdown> : <></>}
                                        </div>
                                    </div>
                                    {this.state.isOrthoFile && this.state.task?.tasktpye == 2 && <div style={{ color: "#2989CF", fontSize: "13px", fontFamily: "poppins", fontWeight: 500 }}>Note : If you want to perform measurement upload DSM and DTM file </div>}
                                    {this.state.listLoading ? <div style={{ height: "85vh", width: "100%", display: "flex", textAlign: "center" }}>
                                        <img src={Loader} style={{ height: "30vh", width: "100%", margin: "auto" }} />
                                    </div> :
                                        this.state.mapView ?
                                            <div>
                                                <ViewImagesOnMap
                                                    service={AppConstants.SERVICES.PICSTORK}
                                                    height={'82vh'}
                                                    width={'100%'}
                                                    inspect={this.state.images}
                                                    collection_id={this.state.collection.id}
                                                    plan_id={this.state.plan.id}
                                                    project_id={this.state.project.id}
                                                    getImages={async () => await this.getImages()}
                                                    onClickOnPoint={idx => this.openImage(idx)}
                                                    cannotDelete={this.isTaskInProgress()}
                                                    hideToolbar={true}
                                                />
                                            </div>
                                            : <>{this.state.listview && images.length > 0 ?
                                                <div style={{ height: '82vh', overflow: 'auto' }}>
                                                    {
                                                        this.state.listLoading ? <div style={{ height: "85vh", width: "100%", display: "flex", textAlign: "center" }}>
                                                            <img src={Loader} style={{ height: "30vh", width: "100%", margin: "auto" }} />
                                                        </div>
                                                            :
                                                            <ViewImageInList task={this.state.task} user={this.state.user} showCheckboxes={this.state.showCheckboxes} collection_id={this.state.collection.id} inspect={this.state.images} onClickOnCheckBox={key => this.imageSelectDeleted(key)} sortType={this.state.sortType}
                                                                onClickOnPoint={idx => {
                                                                    this.openImage(idx)
                                                                }}
                                                                onClickSort={sortType => this.onChangeSortEvent(sortType)} />
                                                    }
                                                </div>
                                                : <>   {images.length ?
                                                    <div style={{ height: '82vh', overflow: 'auto' }} >
                                                        {
                                                            this.state.listLoading ? <div style={{ height: "85vh", width: "100%", display: "flex", textAlign: "center" }}>
                                                                <img src={Loader} style={{ height: "30vh", width: "100%", margin: "auto" }} />
                                                            </div> : <>

                                                                {this.props.taskType === 1 && this.state.imageFileType !== AppConstants.FILETYPES.TIF && images.length < 20 && <div style={{ margin: "5px 2px", fontSize: '11px', color: "red", fontWeight: 600 }}>Please upload atleast 20 images to continue training.</div>}
                                                                <div className="aerocapture-cards-tray-images1" style={{ columnGap: '15px' }} >
                                                                    {
                                                                        images.map((image, key) => {
                                                                            // let name = image.file_name.toLowerCase()
                                                                            return (
                                                                                <div>
                                                                                    <div>
                                                                                        {< div key={key} className="customer-cards-dashboard card" style={{ boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)', width: '170px' }}>
                                                                                            <div className="card-header customer-cards-dashboard-logo project-card-header"
                                                                                                style={{}}


                                                                                            >
                                                                                                {/* {name.indexOf(".tif") == -1 ? */}
                                                                                                <img className="image-cards-dashboard" id={image.file_name}
                                                                                                    src={image.thumbURL || defaultThumbnail}
                                                                                                    loading='lazy'
                                                                                                    onClick={
                                                                                                        () => {
                                                                                                            if (this.state.showCheckboxes) {
                                                                                                                this.imageSelectDeleted(key)
                                                                                                            } else {
                                                                                                                if (image?.isDsmImage || image.isDtmImage) {
                                                                                                                    return;
                                                                                                                } else {
                                                                                                                    this.openImage(key)
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    } style={{ objectFit: 'cover', cursor: 'pointer' }}
                                                                                                    onError={(e) => { e.target.onerror = null; e.target.src = thumb }} />
                                                                                                {/* :
                                                                                    <img className="image-cards-dashboard" id={image.file_name}
                                                                                        src={image.thumbURL}
                                                                                        loading='eager'
                                                                                        onClick={
                                                                                            () => {
                                                                                                if (this.state.showCheckboxes) {
                                                                                                    this.imageSelectDeleted(key)
                                                                                                } else {
                                                                                                    this.openImage(key)
                                                                                                }
                                                                                            }
                                                                                        } style={{ objectFit: 'cover', cursor: 'pointer' }}
                                                                                        onError={(e) => { e.target.onerror = null; e.target.src = thumb }} />
                                                                                } */}
                                                                                                {this.state.showCheckboxes ? <input type='checkbox' id={image.file_name} checked={image.checked} style={{ height: '15px', width: '15px', marginLeft: 'auto', marginRight: "-155px", marginTop: '-40px', position: 'absolute' }}
                                                                                                    onClick={() => { this.imageSelectDeleted(key) }}
                                                                                                /> : <> </>
                                                                                                }
                                                                                            </div>
                                                                                            <div style={{ paddingTop: "3px !important" }}
                                                                                                className="card-body change-color customer-cards-dashboard-text project-card-body2"
                                                                                            >
                                                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                                    <span style={{ fontSize: '11px' }}>{
                                                                                                        image.file_name.length > 20 ?
                                                                                                            `${image.file_name.substring(0, 20)}...` :
                                                                                                            `${image.file_name}`
                                                                                                    }</span>
                                                                                                    <div style={{ display: 'flex' }}>
                                                                                                        <div style={{ fontSize: '11px', height: '12px' }}>
                                                                                                            {image.latitude == "" || image.longitude == "" || image.latitude == null || image.longitude == null || image.latitude == '0' || image.longitude == '0' ? <></> : <img style={{ marginTop: '-2px', height: '12px', width: '12px', color: '#3c3c3c' }} src={LocationIcon} />}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                                    <div style={{ height: "11px", overflow: "hidden", fontSize: '10px' }}>{image.file_size}MB </div>
                                                                                                    <div style={{ height: "11px", overflow: "hidden", fontSize: '10px' }}>{!image.resolution ? " " : this.state.imageFileType !== AppConstants.FILETYPES.TIF || image.resolution != '0.00' ? image.resolution + 'MP' : ''}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                    {((!this.props.allStepComplete && this.state.imageFileType === AppConstants.FILETYPES.TIF && (this.state.task.tasktype === 2 || this.state.task.tasktype === 4) && !this.state.task?.task_parameters?.dsm_url)) && (
                                                                        !this.state.isloading ?
                                                                            <MapDSMToTaskImage
                                                                                image={this?.state?.images[0]}
                                                                                fileType={"dsm"}
                                                                                uploadEndPoint={"AeroMegh/AeroCapture/linkDSMToImageAndDetectionTask"}
                                                                                isDisabled={this.state.task?.task_parameters?.dtm_url}
                                                                                onUpload={(data) => {
                                                                                    let updatedTaskParameters = null;
                                                                                    updatedTaskParameters = { ...this.state.task?.task_parameters, dsm_url: generateTIFFUrl(data?.url, "dsm") }
                                                                                    const newTask = { ...this.state.task, task_parameters: updatedTaskParameters }
                                                                                    this.setState({ task: newTask, images: [...this.state.images, { ...data, isDsmImage: true }] }, () => {
                                                                                        this.setState({ counts: this.state.images.length })
                                                                                        this.props.updateImagesCount(true, this.state.images.length, this.state.isOrthoFile ? (this.state.map_task_id || uuid()) : null)

                                                                                    })
                                                                                    this.props.updateTask(newTask)
                                                                                    if (updatedTaskParameters) {
                                                                                        API.updateTaskDetails(this.state.task?.task_id, {
                                                                                            task_parameters: updatedTaskParameters,
                                                                                        }).then((res) => {

                                                                                        }).catch((err) => {
                                                                                            console.log("error", err);
                                                                                            CustomPopup.alert("ERROR", "An error occur while update task !", () => { }, undefined);
                                                                                        })
                                                                                    }
                                                                                }}
                                                                            /> : <div style={{ height: "65px", width: "80px", display: "flex", textAlign: "center" }}>
                                                                                <img src={Loader} style={{ width: "100%", marginLeft: "8px" }} />
                                                                            </div>
                                                                    )}
                                                                    {(!this.props.allStepComplete && this.state.imageFileType === AppConstants.FILETYPES.TIF
                                                                        && (this.state.task.tasktype === 2 || this.state.task.tasktype === 4)
                                                                        && this.state.task?.task_parameters?.dsm_url
                                                                        && !this.state.task?.task_parameters?.dtm_url) && (
                                                                            !this.state.isloading ?
                                                                                <MapDSMToTaskImage
                                                                                    image={this?.state?.images[0]}
                                                                                    fileType={"dtm"}
                                                                                    uploadEndPoint={"AeroMegh/AeroCapture/linkDSMToImageAndDetectionTask"}
                                                                                    isDisabled={this.state.task?.task_parameters?.dtm_url}
                                                                                    onUpload={(data) => {
                                                                                        let updatedTaskParameters = null;
                                                                                        updatedTaskParameters = { ...this.state.task?.task_parameters, dtm_url: generateTIFFUrl(data?.url, "dtm") }
                                                                                        const newTask = { ...this.state.task, task_parameters: updatedTaskParameters }
                                                                                        this.setState({ task: newTask, images: [...this.state.images, { ...data, isDtmImage: true }] }, () => {
                                                                                            this.setState({ counts: this.state.images.length })
                                                                                            this.props.updateImagesCount(true, this.state.images.length, this.state.isOrthoFile ? (this.state.map_task_id || uuid()) : null)
                                                                                        })
                                                                                        this.props.updateTask(newTask)
                                                                                        if (updatedTaskParameters) {
                                                                                            API.updateTaskDetails(this.state.task?.task_id, {
                                                                                                task_parameters: updatedTaskParameters,
                                                                                            }).then((res) => {

                                                                                            }).catch((err) => {
                                                                                                console.log("error", err);
                                                                                                CustomPopup.alert("ERROR", "An error occur while update task !", () => { }, undefined);
                                                                                            })
                                                                                        }
                                                                                    }}
                                                                                /> : <div style={{ height: "65px", width: "80px", display: "flex", textAlign: "center" }}>
                                                                                    <img src={Loader} style={{ width: "100%", marginLeft: "8px" }} />
                                                                                </div>
                                                                        )}
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    <div className="rmp-dashboard-empty-data-message" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                                                        <img style={{ height: '150px', width: '150px' }} src={Noimageset} />
                                                        <p style={{ marginTop: '10px' }}>No images available</p>
                                                    </div>
                                                }
                                                </>}</>}
                                </>
                            }
                        </div>
                }
            </div > : <div />
        );
    }
}

const mapStateToProps = state => {
    return {
        SubscriptionData: state.subscription.subscriptionData,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Images);
