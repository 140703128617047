/*
- this component is used to assign the drone

*/

import React, { Component, useEffect } from "react";
import planIcon from '../icons/map-marker.svg'
import Geocode from "react-geocode";
import LeafletMap from "../ReusableComponents/map";
import L, { layerGroup } from "leaflet";
import "leaflet-editable"
import * as turf from '@turf/turf'
import * as API from "../api";
import { ActivityPopup } from "./ActivityPopup";
import {
  editEnableEvent,
  drawingEndEvent,
  onUpdateLayer
} from "../ReusableComponents/map/leafletMeasure.js"
import {
  GoogleApiWrapper,
  LoadingContainer,
} from "google-maps-react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
Geocode.setApiKey("AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI");
Geocode.setLanguage("en");

let marker = [];
class inspectOnMap extends Component {

  constructor(props) {
    super(props);
    //this.initMap = this.initMap.bind(this);
    this.state = {
      baseLayer: '',
      polygons: [],
      map: '',
      pointer: false,
      selectedMarkers: [],
      showProcessPopup: false,
      processPopupIcon: "",
      processAction: "",
      processMessage: "",
      polygon: false,
      currentlySelectedImages: [],
      showAddImagesetPopup: false,
      collection_name: "",
      error: null
    };
  }

  setBaseLayerToState = (baseLayer) => {
    this.setState({ baseLayer })
  }

  drawPlanFence = () => {
    if (this.props.planGeofence) {
      if (this.props.planGeofence.length > 0) {
        for (let i = 0; i < this.props.planGeofence.length; i++) {
          if (this.props.planGeofence[i].length > 2) {
            var polygon = L.polygon(this.props.planGeofence[i], {
              color: 'red',
              strokeOpacity: 1.0,
              strokeWeight: 3,
              fillOpacity: 0.2,
              fillColor: '#3c3c3c',
              zIndex: 0
            }).addTo(this.state.map);
          }
        }
      }
    }
  }

  mapInitialized = () => {
    const { map } = this.state
    drawingEndEvent(map, this.savePolygonToFeauture)
    onUpdateLayer(map, this.selectImages)
    this.imagesGroup = L.featureGroup().addTo(map);
    this.drawfeatureGroup = L.featureGroup()
    this.inspectOnMap(true)
    if (this.props.planGeofence) this.drawPlanFence();
    editEnableEvent(map, ((activePolygon) => {
      if (activePolygon.getLatLngs()[0].length < 2) {
        if (this.state.activePolygon) {
          this.deselectImages(this.state.activePolygon, false)
        }
        this.setState({ activePolygon, polygon: true })
      }
    }))
  }

  setMapCenterFromImage = () => {
    const { map } = this.state
    var i = 0;
    let inspect = this.props.inspect
    if (!inspect || inspect.length == 0)
      map.setView(new L.LatLng(20.008482, 73.756834), 16);
    else {
      for (; i < inspect.length; i++) {
        if (!inspect[i].latitude || !inspect[i].longitude) {
        } else {
          map.setView(new L.LatLng(inspect[i].latitude, inspect[i].longitude), 16);
          break;
        }
      }
      if (i == inspect.length)
        map.setView(new L.LatLng(20.008482, 73.756834), 16);
    }
  }

  deleteMarkers = async () => {
    if (marker.length > 0) {
      marker.map((sMarker) => {
        sMarker.remove(null)
      })
    }
  }

  clickOnImageMarker = async (index) => {
    if (this.state.pointer) { //this will select the images
      if (this.selectedImages[index]) {
        await this.deselectImage(index)
        this.selectedImagesThroughPointer[index] = undefined
      } else {
        await this.selectImage(index)
        this.selectedImagesThroughPointer[index] = this.props.inspect[index]
      }
      this.selectedImages.forEach((a) => console.log(a))
    } else { //this will open the image
      this.props.onClickOnPoint(Number(index))
    }
  }

  inspectOnMap = async (firstTime = false) => {
    if (firstTime) this.setMapCenterFromImage();
    await this.deleteMarkers()
    let { map } = this.state
    let count = 0;
    if (this.props.inspect && this.props.inspect.length > 0) {
      this.props.inspect.forEach((image, index) => {
        if (image.latitude == null || image.longitude == null || image.latitude === "" || image.longitude === "" || image.latitude == undefined || image.longitude == undefined) {
        } else {
          let lat = Number(image.latitude)
          let lng = Number(image.longitude)
          let latlng = { lat, lng };
          let icon = {
            iconUrl: planIcon,
            iconSize: [45, 45],
            iconAnchor: [22, 22]
          };

          let iconstyle = this.props.icon == 'plan' ? icon : {
            color: 'black',
            fillColor: image.checked == true ? 'red' : '#2989Cf',
            fillOpacity: 1,
            radius: 7,
            weight: 1,
          }
          let label = this.props.icon == 'plan' ? image.plan_name ? image.plan_name : "" : image.file_name

          var tempMarker = null
          if (this.props.icon == 'plan') {
            let marker = L.icon(iconstyle);
            const tempMarker = L.marker([lat, lng], { icon: marker }).addTo(map).on('click', () => {
              this.props.onClickOnPoint(Number(index))
            });
            tempMarker.bindTooltip(label)
          }
          else {
            tempMarker = L.circleMarker(latlng, iconstyle).on('click', () => this.clickOnImageMarker(index));
            tempMarker.bindTooltip(label)
            this.imagesGroup.addLayer(tempMarker)
          }
          marker[count++] = tempMarker
        }
      })
    }
  }

  confirmDelete = () => {
    let selected = []
    this.selectedImages.forEach((image) => {
      if (image) selected.push(image.file_name);
    })
    if (selected.length > 0)
      this.setState((state) => ({
        ...state,
        itemName: "Images",
        processPopupIcon: "WARNING",
        processAction: "DELETE",
        processMessage: "Are you sure you want to delete these Images ?",
        showProcessPopup: true,
      }))
  }

  deleteImages = async () => {
    this.setState((state) => ({
      ...state,
      itemName: "Images",
      processPopupIcon: "WAIT",
      processAction: "DELETE",
      processMessage: null,
      showProcessPopup: true,
    }))
    let selected = []
    this.selectedImages.forEach((image) => {
      if (image) selected.push(image.file_name);
    })
    API.deleteImages(this.props.collection_id, selected, this.props.service).then((data) => {
      this.state.selectedMarkers.forEach((key) => {
        marker[key].remove()
      });
      if (this.state.activePolygon)
        this.state.activePolygon.remove()
      this.setState((state) => ({
        ...state,
        itemName: "Images",
        processPopupIcon: "COMPLETE",
        processAction: "DELETE",
        processMessage: null,
        showProcessPopup: true,
      }))
      this.props.getImages()
    }, (e) => {
      this.setState((state) => ({
        ...state,
        itemName: "Images",
        processPopupIcon: "ERROR",
        processAction: "DELETE",
        processMessage: e,
        showProcessPopup: true,
      }))
    })
  }

  savePolygonToFeauture = (e) => {
    this.drawfeatureGroup.addLayer(e.layer)
    e.layer.on("click", () => {
      if (this.state.activePolygon) {
        this.state.activePolygon.setStyle({ fillColor: '#2989Cf', color: "#2989Cf" });
      }
      e.layer.enableEdit()
      this.selectImages(e.layer)
    })
    e.layer.on("contextmenu", async () => {
      if (this.state.activePolygon) {
        const layer = this.state.activePolygon
        this.deselectImages(layer, false)
      }
      this.setState({ activePolygon: e.layer }, () => {
        this.deselectImages(e.layer)
      })
    })
    this.selectImages(e.layer)
    this.setState({ polygon: false })
  }

  selectImage = async (key) => {
    marker[key].setStyle({ fillColor: 'red' });
    let markersData = this.state.selectedMarkers
    markersData.push(key)
    this.setState({ selectedMarkers: markersData })
    this.selectedImages[key] = this.props.inspect[key]
  }

  deselectImage = async (key) => {
    marker[key].setStyle({ fillColor: '#2989Cf' });
    let markersData = this.state.selectedMarkers
    markersData.splice(markersData.indexOf(key), 1)
    this.setState({ selectedMarkers: markersData })
    this.selectedImages[key] = null
  }

  deselectImages = (layer, removePolygon = true) => {
    if (layer.getLatLngs()[0].length > 2) {
      let key = 0
      marker.forEach(async (point) => {
        let pointsInsidePolygon = turf.within(point.toGeoJSON(), layer.toGeoJSON())
        if (pointsInsidePolygon.features.length > 0) {
          this.deselectImage(key)
          ++key
        } else {
          ++key
        }
      })
      if (removePolygon) {
        layer.remove()
        this.setState({ activePolygon: false })
      }
      else {
        layer.disableEdit()
        layer.setStyle({ fillColor: '#2989Cf', color: "#2989Cf" });
      }
    }
  }

  selectImages = (layer) => {
    if (layer.getLatLngs()[0].length > 2) {
      layer.setStyle({ fillColor: 'red', color: "red" });
      this.setState({ activePolygon: layer })
      this.selectedImages = []
      let key = 0
      marker.forEach(async (point) => {
        let pointsInsidePolygon = turf.within(point.toGeoJSON(), layer.toGeoJSON())
        if (pointsInsidePolygon.features.length > 0) {
          this.selectImage(key)
          ++key
        } else {
          if (!(this.selectedImagesThroughPointer[key])) marker[key].setStyle({ fillColor: '#2989Cf' });
          else this.selectImage(key)
          ++key
        }
      })
    } else {
      this.state.activePolygon.enableEdit()
    }
  }

  handleChange = (e) => {
    let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (format.test(e.target.value)) {
      this.setState({ error: "Spacial characters are not allowed." });
    } else if (e.target.value.includes(' ')) {
      this.setState({ error: "Spaces are not allowed." });
    } else {
      this.setState({ error: null, collection_name: e.target.value });
    }
  }

  closeActivityPopup = () => {
    this.setState((state) => ({
      ...state,
      showProcessPopup: false,
      processMessage: null
    }))
  }

  componentWillMount() {
    marker = []
    this.selectedImages = []
    this.selectedImagesThroughPointer = []
  }
  render() {
    return (
      <>
        <div style={{ height: this.props.height ? this.props.height : '100vh', width: this.props.width ? this.props.width : '100vw', position: 'relative' }}>
          {/* -------------- Activity Popup ----------------------- */}
          <ActivityPopup
            item={this.state.itemName}
            open={this.state.showProcessPopup}
            icon={this.state.processPopupIcon}
            action={this.state.processAction}
            msg={this.state.processMessage}
            close={() => this.closeActivityPopup()}
            onClickOk={() => this.deleteImages()}
          />
          {/* --------------------------------------------------------------- */}

          <LeafletMap
            setBaseLayerToState={this.setBaseLayerToState}
            initCenter={this.state.center ? this.state.center : [20.008482, 73.756834]}
            bounds={this.state.bounds}
            map={this.state.map}
            maxZoom={24}
            initZoom={16}
            handleMapClick={this.handleMapClick}
            handleMoveStart={this.handleMoveStart}
            setMap={(map) => {
              this.setState({ map: map }, () => {
                this.mapInitialized()
              })
            }}
          />
        </div>
      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI",
  libraries: ["drawing", "places"],
  LoadingContainer: LoadingContainer,
})(inspectOnMap);