import L from "leaflet";
import { POLYGON, POINTER, BOX, CIRCLE, REGIONHIGHLIGHTSTYLE, COPYINGREGIONSTYLE, UPDATEREGION, FIRENOTALLOWEDERROR, UPDATECLASS, UPDATEREGIONSANDCLASSES, POINTSONCIRCLE, OUTSIDEORTHOREGIONERROR, TRAININGTOOL, TESTINGTOOL, ACCURACYTOOL, TRAININGCOLOR, TESTINGCOLOR, ACCURACYCOLOR, AREAOVERLAP, LINEINTERSECTITSELF, CLEARDETECTIONS, EDITDETECTION, DELETEDETECTION, DETECTIONTOOL, DETECTIONCOLOR, CLEARAREAS, EDITDETECTIONS, POLYLINE, MARKER } from "./Constants"
import { v4 as uuid } from "uuid"
import AppConstants, { BLOB_URL } from "../../../AppConstants";
import * as turf from '@turf/turf'
import { exportShapeFile, getElevationOfPoint, getPicstorkMeasure } from "../../../api"
import { Popup, calculateCenterOfRegion, generateRandomAlphanumeric, getLimitedName, getRegionNameAfterUnderscore, getRegionNameBeforeUnderscore, triggerBlobDownload } from "../../../ReusableComponents/reusableFunctions";
import { pushAnnotationToSocketRoom } from "../../SocketIo";


var d2r = Math.PI / 180; // degrees to radians
var earthsradius = 3963; // 3963 is the radius of the earth in miles


export default class Annotation {

    constructor(setSelectedAreaId, changeZIndex, mapData, tool, classes, updateParent) {
        this.updateRegions = []
        this.setSelectedAreaId = setSelectedAreaId
        this.changeZIndex = changeZIndex
        this.task = null
        this.esc_pressed = false
        this.history = []
        this.currentHistoryIndex = -1;
        this.detectionArr = []
        this.trainingArr = []
        this.testingArr = []
        this.accuracyArr = []
        this.map = mapData.map
        this.imageBounds = mapData.imageBounds
        this.mapPanes = mapData.mapPanes
        this.update = updateParent
        this.activeTool = tool
        this.regions = []
        this.classes = classes
        this.activeDrawingClass = classes[0]
        this.disableUpdate = false
        this.areaLayers = new L.layerGroup().addTo(mapData.map)
        this.annotationLayers = new L.layerGroup().addTo(mapData.map)
        this.selectedRegion = undefined
        this.selectedArea = undefined
        this.activeNewLayer = undefined

        this.defaultTooltip = new L.popup({ direction: "bottom", className: "annotationToolTip", autoClose: false, offset: [32, 48], autoPan: false }).setContent(`
        <div style= "display:flex; background:rgb(255 255 255 / 80%); padding:3px 0 ; justify-content:space-evenly;align-items:center; width:65px; borderRadius:5px; z-index:1;" >
           <img src="/annotationToolIcons/copy.png" onclick='if(${!this.disableUpdate}) document.getElementById("copySelectedAnnotation").click()' style="height:14px; cursor:${this.disableUpdate ? `auto` : `pointer`};opacity:${this.disableUpdate ? 0.5 : 1}"/>
           <img src="/annotationToolIcons/deleteIcon.svg" onclick='if(${!this.disableUpdate}) document.getElementById("deleteSelectedAnnotation").click()' style = "height:16px;cursor:${this.disableUpdate ? `auto` : `pointer`};opacity:${this.disableUpdate ? 0.5 : 1}"/>
           <img src="/annotationToolIcons/pencil.png" onclick='if(${!this.disableUpdate}) document.getElementById("editSelectedAnnotation").click()' style="height:13px;cursor:${this.disableUpdate ? `auto` : `pointer`};opacity:${this.disableUpdate ? 0.5 : 1}"/>
         </div>
        `)
        this.editEnableToolTip = new L.popup({ direction: "bottom", className: "annotationToolTip", autoClose: false, offset: [32, 48], autoPan: false }).setContent(`
        <div style= "display:flex; background:rgb(255 255 255 / 80%); padding:3px 0 ; justify-content:space-evenly;align-items:center; width:65px; borderRadius:5px; z-index:1;" >
           <img src="/annotationToolIcons/copy.png" style="height:14px;opacity:0.5"/>
           <img src="/annotationToolIcons/deleteIcon.svg" style = "height:16px;opacity:0.5;"/>
           <img src="/annotationToolIcons/tick.png" onclick='document.getElementById("editSelectedAnnotation").click()' style="height:16px;cursor:pointer;"/>
         </div>
        `)

        this.copyEnableToolTip = new L.popup({ direction: "bottom", className: "annotationToolTip", autoClose: false, offset: [32, 48], autoPan: false }).setContent(`
        <div style= "display:flex; background:rgb(255 255 255 / 80%); padding:3px 0 ; justify-content:space-evenly;align-items:center; width:65px; borderRadius:5px; z-index:1;" >
           <img src="/annotationToolIcons/tick.png" onclick='document.getElementById("copySelectedAnnotation").click()' style="height:14px; cursor:pointer;"/>
           <img src="/annotationToolIcons/deleteIcon.svg" style = "height:16px;opacity:0.5;"/>
           <img src="/annotationToolIcons/pencil.png" style="height:13px;opacity:0.5;"/>
         </div>
        `)
        this.defaultTooltipArea = new L.popup({ direction: "bottom", className: "annotationToolTip", autoClose: false, offset: [32, 48], autoPan: false, }).setContent(`
        <div style= "display:flex; background:rgb(255 255 255 / 80%); padding:3px 0 ; justify-content:space-evenly;align-items:center; width:50px; borderRadius:5px; z-index:1;" >
           <img src="/annotationToolIcons/deleteIcon.svg" onclick='if(${!this.disableUpdate}) document.getElementById("deleteSelectedAnnotationArea").click()' style = "height:16px;cursor:${this.disableUpdate ? `auto` : `pointer`};opacity:${this.disableUpdate ? 0.5 : 1}"/>
           <img src="/annotationToolIcons/pencil.png" onclick='if(${!this.disableUpdate}) document.getElementById("editSelectedAnnotationArea").click()' style="height:13px;cursor:${this.disableUpdate ? `auto` : `pointer`};opacity:${this.disableUpdate ? 0.5 : 1}"/>
         </div>
        `)
        this.editEnableToolTipArea = new L.popup({ direction: "bottom", className: "annotationToolTip", autoClose: false, offset: [32, 48], autoPan: false }).setContent(`
        <div style= "display:flex; background:rgb(255 255 255 / 80%); padding:3px 0 ; justify-content:space-evenly;align-items:center; width:65px; borderRadius:5px; z-index:1;" >
           <img src="/annotationToolIcons/deleteIcon.svg" style = "height:16px;opacity:0.5;"/>
           <img src="/annotationToolIcons/tick.png" onclick='document.getElementById("editSelectedAnnotationArea").click()' style="height:16px;cursor:pointer;"/>
         </div>
        `)
        this.setImageBounds = (bounds) => {
            this.imageBounds = bounds
        }
        this.enableTool(tool)

        //init listener for drawing 
        this.editEnableEvent(this.onEnableEdit)
        this.editDisableEvent(this.onDisableEdit, () => { })
        this.drawingCommitEvent(this.onDisableEdit, () => { })
        this.drawingEndEvent(this.newDrawingRegionEnd) //commit on last point ny user only 
        this.onUpdateLayer(this.onUpdatedSelectedLayer)
        this.onVertexDragStart(this.hideAllToolTips)
        mapData.map.on("click", this.onClickMap)
        mapData.map.on('mousemove', this.onMouseMove)
        this.addButtonListeners();
    }
    addToHistory(action, state) {
        // Clear any redo states
        this.history.splice(this.currentHistoryIndex + 1);
        this.history.push({ action, state });
        this.currentHistoryIndex = this.history.length - 1;
    }
    performHistoryOperation(action, state) {
        switch (action) {
            case "ADD_REGION":
                if (state.regions) {
                    const toBeDeletedRegion = this.regions.find(a => state.regions[0].id === a.id)
                    if (toBeDeletedRegion) {
                        this.annotationLayers.removeLayer(toBeDeletedRegion.leafletLayer)
                        this.regions = this.regions.filter(reg => reg.id !== toBeDeletedRegion.id)
                        if (this.task.tasktype == 2 || this.task.tasktype == 4) {
                            this.update({ ...this, selectedRegion: toBeDeletedRegion }, DELETEDETECTION)
                        } else {
                            this.update({ ...this, selectedRegion: undefined, leafletLayer: undefined }, UPDATEREGION)
                        }
                    }
                }
                break;
            case "DELETE_REGION":
                if (state.region) {
                    this.drawRegions([state.region], false, true)
                }
                break;
        }
    }
    undo() {
        if (this.currentHistoryIndex >= 0) {
            const { action, state } = this.history[this.currentHistoryIndex];
            this.performHistoryOperation(action, state)
            this.currentHistoryIndex--;
        }
    }
    redo() {
        if (this.currentHistoryIndex < this.history.length - 1) {
            this.currentHistoryIndex++;
            return this.history[this.currentHistoryIndex];
        }
        return null; // No more redo steps
    }
    onPressESC = () => {
        this.esc_pressed = true;
        if (this.selectedRegion || this.selectedArea) {
            this.enableTool()
        }
        if (this.selectedRegion?.copying) {
            this.enableSelectedLayerCopy(false)
        } else if (this.activeDrawingRegion) {
            this.enableDrawingForTool(this.activeTool)
        }

    }
    onReleasedEsc = () => {
        this.esc_pressed = false
    }
    onPressDELETE = () => {
        if (this.activeDrawingRegion) {
            this.enableDrawingForTool(this.activeTool)
        }
        if (this.selectedRegion) {
            this.deleteSelectedRegion()
        }
        if (this.selectedArea) {
            this.deleteSelectedArea()
        }
    }

    onPress_CTRL_Z = () => {
        // this.undo()
    }

    onPress_CTRL_Y = () => {

    }

    onPress_CTRL_C = () => {
        this.enableSelectedLayerCopy(true)
    }

    onPressEnter = () => {
        if (this.activeDrawingRegion && this.activeTool === POLYGON) {
            this.enableDrawingForTool(this.activeTool)
        } else if (this.activeDrawingRegion && !this.selectedRegion) {
            this.onClickMap()
        }
    }


    onKeyPressDown = (event) => {
        event.preventDefault();
        const { key } = event
        if (event.ctrlKey && event.key === 'c') {
            this.onPress_CTRL_C()
        }
        if (event.ctrlKey && event.key === 'z') {
            this.onPress_CTRL_Z()
        }
        if (event.ctrlKey && event.key === 'y') {
            this.onPress_CTRL_Y()
        }
        switch (key) {
            case 'Enter':
                this.onPressEnter()
                break;
            case 'Escape':
                this.onPressESC()
                break;
            case 'Delete':
                this.onPressDELETE()
                break;
        }
    }
    onKeyPressUp = (event) => {
        event.preventDefault();
        const { key } = event
        switch (key) {
            case 'Escape':
                this.onReleasedEsc()
                break;
        }
    }
    addButtonListeners = () => {
        const container = document.getElementById("map");
        if (container) {
            container.focus()
            this.keyDownListener = this.onKeyPressDown.bind(this);
            container.addEventListener('keydown', this.keyDownListener);
            this.keyUpListener = this.onKeyPressUp.bind(this);
            container.addEventListener('keyup', this.keyUpListener);
        }
    }

    removeButtonListeners = () => {
        const container = document.getElementById("map");
        if (container) {
            container.removeEventListener('keydown', this.keyDownListener)
            container.removeEventListener('keyup', this.keyUpListener)
        }
    }

    destroy = () => {
        this.removeButtonListeners()
    }

    saveSevirity = (newSelectedRegion) => {
        this.update({
            ...this, regions: this.regions.map(reg => {
                if (reg.id === newSelectedRegion.id) return newSelectedRegion;
                else return reg
            })
        }, EDITDETECTION)
    }


    onClickMap = (e) => {
        if (this.selectedRegion && this.selectedRegion.copying) this.copyLayer(e);
        if (this.activeBox && this.activeTool === BOX) this.onDisableEdit(this.activeBox.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }), this.activeBox, BOX)
        if (this.activeBox && this.activeTool === CIRCLE) this.onDisableEdit({ latLng: { lat: this.activeBox.getLatLng().lat, lng: this.activeBox.getLatLng().lng }, radius: this.activeBox.getRadius() }, this.activeBox, CIRCLE)
        if (this.activeBox && this.activeTool === TRAININGTOOL) this.onDisableEdit(this.activeBox.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }), this.activeBox, TRAININGTOOL)
        if (this.activeBox && this.activeTool === DETECTIONTOOL) this.onDisableEdit(this.activeBox.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }), this.activeBox, DETECTIONTOOL)
        if (this.activeBox && this.activeTool === TESTINGTOOL) this.onDisableEdit(this.activeBox.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }), this.activeBox, TESTINGTOOL)
        if (this.activeBox && this.activeTool === ACCURACYTOOL) this.onDisableEdit(this.activeBox.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }), this.activeBox, ACCURACYTOOL)

    }


    onMouseMove = (e) => {
        if (this.activeBox && this.activeTool === BOX) {
            this.activeBox.setLatLngs([
                {
                    lat: this.activeBox.getLatLngs()[0][0].lat,
                    lng: this.activeBox.getLatLngs()[0][0].lng
                },
                {
                    lat: this.activeBox.getLatLngs()[0][0].lat,
                    lng: e.latlng.lng
                },
                {
                    lat: e.latlng.lat,
                    lng: e.latlng.lng
                },
                {
                    lat: e.latlng.lat,
                    lng: this.activeBox.getLatLngs()[0][0].lng,
                }

            ])
        }
        if (this.activeBox && this.activeTool === CIRCLE) {
            this.activeBox.setRadius((this.activeBox.getLatLng()).distanceTo(e.latlng))
        }
        if (this.activeBox && (this.activeTool === DETECTIONTOOL || this.activeTool === TRAININGTOOL || this.activeTool === TESTINGTOOL || this.activeTool === ACCURACYTOOL)) {
            this.activeBox.setLatLngs([
                {
                    lat: this.activeBox.getLatLngs()[0][0].lat,
                    lng: this.activeBox.getLatLngs()[0][0].lng
                },
                {
                    lat: this.activeBox.getLatLngs()[0][0].lat,
                    lng: e.latlng.lng
                },
                {
                    lat: e.latlng.lat,
                    lng: e.latlng.lng
                },
                {
                    lat: e.latlng.lat,
                    lng: this.activeBox.getLatLngs()[0][0].lng,
                }

            ])
        }
    }

    setUpdating = (trueOrFalse) => {
        this.disableUpdate = !trueOrFalse
        this.defaultTooltip = new L.popup({ direction: "bottom", className: "annotationToolTip", autoClose: false, offset: [32, 48] }).setContent(`
        <div style= "display:flex; background:rgb(255 255 255 / 80%); padding:3px 0 ; justify-content:space-evenly;align-items:center; width:65px; borderRadius:5px; z-index:1;" >
           <img src="/annotationToolIcons/copy.png" onclick='document.getElementById("copySelectedAnnotation").click()' style="height:14px; cursor:${this.disableUpdate ? `auto` : `pointer`};opacity:${this.disableUpdate ? 0.5 : 1}"/>
           <img src="/annotationToolIcons/deleteIcon.svg" onclick='document.getElementById("deleteSelectedAnnotation").click()' style = "height:16px;cursor:${this.disableUpdate ? `auto` : `pointer`};opacity:${this.disableUpdate ? 0.5 : 1}"/>
           <img src="/annotationToolIcons/pen.svg" onclick='document.getElementById("editSelectedAnnotation").click()' style="height:16px;cursor:${this.disableUpdate ? `auto` : `pointer`};opacity:${this.disableUpdate ? 0.5 : 1}"/>
         </div>
        `)
    }

    onEditClassNameInClassList = async (oldClassName, newClassName, overlayOnly) => {
        const classIndex = this.classes.findIndex(cls => cls.name === oldClassName)
        if (classIndex >= 0) {
            (this.classes[classIndex]).name = newClassName
        }
        const iterationPromises = this.regions.map(async region => {
            if (region.className === oldClassName) {
                region.className = newClassName;
                if (region.name) {
                    region.name = `${newClassName}_${getRegionNameAfterUnderscore(region.name)}`
                    region.leafletLayer.closePopup()
                    region.leafletLayer.unbindPopup()
                    const popup = new L.popup(region.leafletLayer.getBounds().getCenter(), {
                        pane: 'tooltips',
                        content: `${getLimitedName(getRegionNameBeforeUnderscore(region.name), 5)}_${getRegionNameAfterUnderscore(region.name)}`,

                    })
                    popup.setLatLng(region.leafletLayer.getBounds().getCenter())
                    region.leafletLayer.bindPopup(popup)
                }
            }
        })
        await Promise.all(iterationPromises)
        this.update({ ...this, classNameEdited: { oldClassName, newClassName } }, overlayOnly ? false : UPDATEREGIONSANDCLASSES)
        this.setActiveDrawingClass(this.classes[classIndex])
    }

    onEditClassColor = async (className, color, overlayOnly) => {
        if (this.activeDrawingRegion) {
            if (this.activeDrawingClass) {
                this.activeDrawingClass.color = color
            }

            this.activeDrawingRegion.setStyle({
                color
            })

        }
        this.highlightClasses()
        this.selectRegion()
        this.selectArea()
        this.classes[this.classes.findIndex(cls => cls?.name === className)].color = color
        const iterationPromises = this.regions.map(async region => {
            if (region.className === className) {
                region.color = color;
                if (region.type == MARKER) {
                    const icon = L.divIcon({
                        html: `<div class='mapMarker' style='border-radius:50%; position:absolute; top:-3px; left:-3px; width:15px; height:15px; background: ${region.color};'></div>`,
                        iconSize: [32, 32],
                        iconAnchor: [16, 32],
                    })
                    region.leafletLayer.setIcon(icon)
                } else {

                    region.leafletLayer.setStyle({
                        color,
                        fillColor: color
                    })


                }

            };
        })
        await Promise.all(iterationPromises)

        this.update({ ...this, editedClass: { className, color } }, overlayOnly ? false : UPDATEREGIONSANDCLASSES)
    }

    onDeleteClass = (className, overlayOnly) => {
        this.selectArea()
        this.classes = this.classes.filter(cls => cls.name !== className)
        this.regions = this.regions.filter(cls => {
            if (cls.className === className) {
                this.annotationLayers.removeLayer(cls.leafletLayer)
            }
            else return cls
        })
        if (this.selectedRegion && this.selectedRegion.className === className) this.selectRegion()
        if (this.classes.length === 0) this.enableTool(POINTER)
        if (this.activeDrawingClass && this.activeDrawingClass.name === className) this.activeDrawingClass = this.classes[0];
        this.update({ ...this, deletedClass: className }, overlayOnly ? false : UPDATEREGIONSANDCLASSES)
    }

    copyLayer = async (e) => {
        const options = { units: 'miles' }
        const center = this.selectedRegion.type == MARKER ? this.selectedRegion.leafletLayer.getLatLng() : this.selectedRegion.leafletLayer.getBounds().getCenter();
        let coordinates = this.selectedRegion.coordinates.map((coord) => {
            const destPoint = turf.rhumbDestination(turf.point([e.latlng.lng, e.latlng.lat]), turf.rhumbDistance(turf.point([center.lng, center.lat]), turf.point([coord.lng, coord.lat]), options), turf.bearing(turf.point([center.lng, center.lat]), turf.point([coord.lng, coord.lat])), options)
            return ({
                lat: destPoint.geometry.coordinates[1],
                lng: destPoint.geometry.coordinates[0]
            })
        })

        let region = {
            id: uuid(),
            color: this.selectedRegion.color,
            type: this.selectedRegion.type,
            className: this.selectedRegion.className,
            coordinates,
            point: calculateCenterOfRegion(this.selectedRegion),

        }
        if (this.selectedRegion.name) {
            const regionsWithSameClass = this.regions.filter(r => r.className == this.selectedRegion.className).sort((a, b) => {
                let numA = parseInt(getRegionNameAfterUnderscore(a.name));
                let numB = parseInt(getRegionNameAfterUnderscore(b.name));
                return numB - numA; // For descending order
            });
            let biggestRegionNumber = 0;
            if (regionsWithSameClass.length > 0) {
                biggestRegionNumber = Number(getRegionNameAfterUnderscore(regionsWithSameClass[0].name))
            }
            region.name = `${this.selectedRegion.className}_${biggestRegionNumber + 1}`
        }
        if (await this.isRegionInsideImageBounds(region.coordinates)) {
            this.drawRegions([region])
        }
        setTimeout(() => {
            const center = this.selectedRegion.type == MARKER ? this.selectedRegion.leafletLayer.getLatLng()
                : this.selectedRegion.leafletLayer.getBounds().getSouthWest()
            this.copyEnableToolTip.setLatLng(center).openOn(this.map)
        }, 300)
    }

    hideAllToolTips = () => {

        this.editEnableToolTip.remove()
        this.defaultTooltip.remove()
        this.copyEnableToolTip.remove()
        this.defaultTooltipArea.remove()
        this.editEnableToolTipArea.remove()
        this.setCursor("auto")
    }

    getCoordinates = (layer, type) => {
        if (type === POLYGON || type === BOX) {
            return layer.getLatLngs()[0].map(latLng => { return { lat: latLng.lat, lng: latLng.lng } })
        }
        else if (type === POLYLINE) {
            return layer.getLatLngs().map(latLng => { return { lat: latLng.lat, lng: latLng.lng } })
        }
        else if (type === MARKER) {
            return [layer.getLatLng()]
        }
        else if (type === CIRCLE) {
            return ({ latLng: { lat: layer.getLatLng().lat, lng: layer.getLatLng().lng }, radius: layer.getRadius() })
        }
    }

    onUpdatedSelectedLayer = async (layer) => {
        try {

            if (this.selectedRegion) {
                const coordinates = this.selectedRegion.type == MARKER ? [layer.getLatLng()] : this.selectedRegion.type == POLYLINE ? layer.getLatLngs() : layer.getLatLngs()[0]
                if (await this.isRegionInsideImageBounds(coordinates)) {
                    if (this.selectedRegion.type == POLYGON && this.isPolygonSelfIntersecting(coordinates)) {
                        this.selectedRegion.leafletLayer.setLatLngs(this.selectedRegion.coordinates)
                        this.selectedRegion.leafletLayer.disableEdit()
                        this.selectedRegion.leafletLayer.enableEdit()
                    }
                    else {
                        const updatedCoords = this.getCoordinates(layer, this.selectedRegion.type)
                        this.selectedRegion.coordinates = updatedCoords
                        let point = [0, 0]
                        if (this.selectedRegion.type == MARKER) {
                            point = [this.selectedRegion.coordinates[0].lat, this.selectedRegion.coordinates[0].lng]
                        } else {
                            point = calculateCenterOfRegion({ type: this.selectedRegion.type, coordinates: updatedCoords })
                        }
                        this.selectedRegion.point = point
                        this.selectedRegion.area = null;
                        this.selectedRegion.volume = null;
                        this.selectedRegion.elevation = null;
                        this.selectedRegion.temperature = null;
                        if (this.task.tasktype === 2 || this.task.tasktype === 4) {
                            this.update({ ...this, selectedRegion: { ...this.selectedRegion, coordinates: updatedCoords, point } }, EDITDETECTION)
                        } else {
                            this.update({ ...this, selectedRegion: { ...this.selectedRegion, coordinates: updatedCoords, point } }, UPDATEREGION)
                        }
                        if (this.selectedRegion.type == MARKER) {
                            this.editEnableToolTip.setLatLng(layer.getLatLng()).openOn(this.map)
                        } else {
                            this.editEnableToolTip.setLatLng(layer.getBounds().getSouthWest()).openOn(this.map)
                        }
                    }
                } else {
                    this.selectedRegion.leafletLayer.setLatLngs(this.selectedRegion.coordinates)
                    this.selectedRegion.leafletLayer.disableEdit()
                    this.selectedRegion.leafletLayer.enableEdit()
                }

            }
            else if (this.selectedArea) {
                const isIntersect = [...this.detectionArr, ...this.trainingArr, ...this.testingArr, ...this.accuracyArr].some(area => area.id !== this.selectedArea.id && turf.booleanIntersects(area.leafletLayer.toGeoJSON(), this.selectedArea.leafletLayer.toGeoJSON()))
                if (isIntersect) {
                    this.update(this, AREAOVERLAP)
                    this.selectedArea.leafletLayer.setLatLngs(this.selectedArea.coordinates)
                    this.selectedArea.leafletLayer.disableEdit()
                    this.selectedArea.leafletLayer.enableEdit()
                } else {

                    if (await this.isRegionInsideImageBounds(layer.getLatLngs()[0])) {
                        const updatedCoords = this.getCoordinates(layer, this.selectedArea.subType == DETECTIONTOOL ? POLYGON : this.selectedArea.type)
                        this.selectedArea.coordinates = updatedCoords
                        this.update({ ...this, selectedArea: { ...this.selectedArea, coordinates: updatedCoords } }, UPDATEREGION)
                        this.editEnableToolTipArea.setLatLng(layer.getBounds().getSouthWest()).openOn(this.map)
                    } else {
                        this.selectedArea.leafletLayer.setLatLngs(this.selectedArea.coordinates)
                        this.selectedArea.leafletLayer.disableEdit()
                        this.selectedArea.leafletLayer.enableEdit()
                    }
                }

            }
        } catch (err) {
            console.log(err);
        }
    }

    isRegionInsideImageBounds = (coordinates, throwError = true) => {
        return new Promise(async (resolve, reject) => {
            const boundsPolygon = turf.bboxPolygon(this.imageBounds.drawingBounds.flat());
            let points = await Promise.all(coordinates.map(async latLng => {
                return [latLng.lat, latLng.lng]
            }))
            points = turf.points(points);
            if ((turf.pointsWithinPolygon(points, boundsPolygon)).features.length === points.features.length) {
                resolve(true)
            } else {
                this.imageBounds.boundsLayer.addTo(this.map)
                setTimeout(() => {
                    this.imageBounds.boundsLayer.remove()
                }, 3000)
                if (throwError) this.update(this, OUTSIDEORTHOREGIONERROR)
                resolve(false)
            }
        })
    }

    newDrawingRegionEnd = (e) => {
        // this calls only new region completed by user
        e.layer.disableEdit()
        if (this.activeTool) {
            this.enableTool(this.activeTool)
        }
    }



    updateSelectedRegionClass = (id, cls) => {
        if (this.selectedRegion) {
            let oldClassName = this.selectedRegion.className;
            const updatedClass = this.classes.find((c) => c.name === cls)
            this.selectedRegion.className = updatedClass.name
            this.selectedRegion.name = `${updatedClass.name}_${this.regions.filter(r => r.className == updatedClass.name).length + 1}`
            this.selectedRegion.color = updatedClass.color
            if (this.selectedRegion.type == MARKER) {
                const icon = L.divIcon({
                    html: `<div class='mapMarker' style='border-radius:50%; position:absolute; top:-3px; left:-3px; width:15px; height:15px; background: ${updatedClass.color};'></div>`,
                    iconSize: [32, 32],
                    iconAnchor: [16, 32],
                })
                this.selectedRegion.leafletLayer.setIcon(icon)
            } else {

                this.selectedRegion.leafletLayer.setStyle({
                    fillColor: updatedClass.color
                })
            }
            this.update({ ...this, oldClassName, selectedRegion: { ...this.selectedRegion, className: updatedClass.name, color: updatedClass.color }, }, EDITDETECTION)

        }
    }

    clearAllAnnotations = () => {
        this.hideAllToolTips()
        if (this.disableUpdate) this.update(this, FIRENOTALLOWEDERROR);
        else if (this.regions.length) {
            this.annotationLayers.clearLayers()
            this.regions = []
            if (this.task.tasktype == 2 || this.task.tasktype == 4) {
                this.update(this, CLEARDETECTIONS)
            } else {
                this.update({ ...this, clearAllAnnotations: true }, UPDATEREGION)
                
            }
            this.selectedRegion = null;
        }
    }
    removePrevAnnotations = () => {
        this.annotationLayers.clearLayers()
        this.areaLayers.clearLayers()
    }

    clearAllAreas = () => {
        this.hideAllToolTips()
        if (this.disableUpdate) this.update(this, FIRENOTALLOWEDERROR);
        else if (this.detectionArr.length) {
            this.areaLayers.clearLayers()
            this.detectionArr = []
            this.update(this, CLEARAREAS)
        }
    }
    exportAnnotations = async (type, successCallback, failedCallback) => {
        if (this.regions.length) {
            const geoJSON = {
                "type": "FeatureCollection",
                "features": await Promise.all(this.regions.map(async (region, key) => {
                    return ({
                        "type": "Feature",
                        "properties": {
                            "id": region.id,
                            "className": region.className,
                            "type": region.type,
                            "name": region?.name || undefined,
                            "elevation": (region?.elevation == null || region?.elevation == undefined) ? undefined : region.elevation,
                            "volume": (region?.volume == null || region?.volume == undefined) ? undefined : region.volume,
                            "area": (region?.area == null || region?.area == undefined) ? undefined : region.area,
                            "center": region?.point || null
                        },
                        "geometry": {
                            "type": region.type == MARKER ? MARKER : region.type == POLYLINE ? POLYLINE : POLYGON,
                            "coordinates": [
                                region.type !== CIRCLE ?
                                    await Promise.all(region.coordinates.map(async latLng => [latLng.lng, latLng.lat])) :
                                    await Promise.all(this.getLatLngsOfCircle(region.coordinates.latLng, region.coordinates.radius, POINTSONCIRCLE).map(async latLng => [latLng.lng, latLng.lat]))
                            ]
                        }
                    })
                }))
            }
            if (type === "geoJson") {
                // create file in browser
                const json = JSON.stringify(geoJSON, null, 2);
                const blob = new Blob([json], { type: "application/json" });
                if (successCallback) successCallback()
                triggerBlobDownload(blob, "geojson.json")

            } else if (type === "shapeFile") {
                exportShapeFile(geoJSON).then(blob => {
                    if (successCallback) successCallback()
                    triggerBlobDownload(blob, "shapeFile.zip")
                }).catch(e => {
                    if (failedCallback) failedCallback("Something went wrong while exporting shape file.")
                })
            }
        }
    }

    randomColor = () => {
        let newColor = false
        do {
            let clr = "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase()
            if (!(this.classes.find(cls => cls.color === clr))) {
                newColor = clr
                return clr
            }
        }
        while (newColor)
    }

    addNewClass = (className, overlayOnly) => {
        if (this.disableUpdate) this.update(this, FIRENOTALLOWEDERROR);
        else {
            const newClass = {
                name: className,
                color: this.randomColor()
            }
            this.update({ ...this, newClasses: [newClass], activeDrawingClass: newClass, classes: [...this.classes, newClass] }, overlayOnly ? false : UPDATECLASS)
            this.classes.push(newClass)
            this.activeDrawingClass = newClass
            this.highlightClasses()
            this.selectRegion()
            this.selectArea()
        }
    }

    addNewClasses = (classes, overlayOnly) => {
        if (this.disableUpdate) this.update(this, FIRENOTALLOWEDERROR);
        else {
            this.classes = [...(this.classes || []), ...classes]
            this.update({ ...this, newClasses: classes }, overlayOnly ? false : UPDATECLASS)
            this.highlightClasses()
            this.selectRegion()
            this.selectArea()

        }
    }

    setActiveDrawingClass = (cls) => {
        this.update({ ...this, activeDrawingClass: cls })
        this.activeDrawingClass = cls
    }

    drawAreas = async (drawingRegions, overlayOnly) => {
        let overLappingRegion = false
        await Promise.all(drawingRegions.map(async (region, index) => {
            return new Promise((resolve) => {
                let polygon = new L.Polygon(region.coordinates, {
                    name: region.id,
                    color: region.color,
                    fillColor: region.color,
                    pane: "Areas",
                    fillOpacity: 0.1,
                    weight: 3,
                    dashArray: '5,5',
                    dashOffset: '20'
                })
                if (turf.booleanIntersects({ type: "FeatureCollection", features: [polygon.toGeoJSON()] }, this.areaLayers.toGeoJSON())) {
                    overLappingRegion = true
                    this.update(this, AREAOVERLAP)
                    resolve()
                    return;

                } else {
                    if (region.subType === DETECTIONTOOL) {
                        this.detectionArr.push({ ...region, leafletLayer: polygon })
                    } else if (region.subType === TRAININGTOOL) {
                        this.trainingArr.push({ ...region, leafletLayer: polygon })
                    } else if (region.subType === TESTINGTOOL) {
                        this.testingArr.push({ ...region, leafletLayer: polygon })
                    } else if (region.subType === ACCURACYTOOL) {
                        this.accuracyArr.push({ ...region, leafletLayer: polygon })
                    }
                    polygon.addTo(this.areaLayers).on('click', () => {
                        this.areaClicked(region.id, polygon, region.subType)
                        polygon.bringToBack()
                    })
                    resolve(region)
                }


            })
        })
        )


        this.update({ ...this, drawnAreas: overLappingRegion ? undefined : drawingRegions, overlayOnly }, UPDATEREGION)


    }

    //function to check polygons lines are slef intersecting
    isPolygonSelfIntersecting = (polygonCoordinates) => {
        const arrayOfArrays = polygonCoordinates.map(obj => [obj.lat, obj.lng]);
        const coordinatesArr = [...arrayOfArrays, arrayOfArrays[0]]
        const poly = turf.polygon([coordinatesArr])
        var kinks = turf.kinks(poly);
        return kinks.features.length > 0
    }
    checkForSmallAnnotation = (layer) => {
        if (layer instanceof L.Polyline || layer instanceof L.Marker || this.activeTool == POLYLINE || this.activeTool == MARKER) return false
        var latlngs = layer.getLatLngs()[0];

        // Minimum area in square meters
        // Calculate area using Leaflet.GeometryUtil
        var area = L.GeometryUtil.geodesicArea(latlngs);
        return ("" + area).includes("0.0")
    }
    enableLastSelectedTool = () => {
        if (this.activeTool === POLYGON) this.enableTool(POLYGON)
        else if (this.activeTool === POLYLINE) this.enableTool(POLYLINE)
        // else if (this.activeTool === MARKER) this.enableTool(MARKER)
        else if (this.activeTool === BOX) this.enableTool(BOX)
        else if (this.activeTool === CIRCLE) this.enableTool(CIRCLE)
        else if (this.activeTool === DETECTIONTOOL) this.enableTool(DETECTIONTOOL)
        else if (this.activeTool === TRAININGTOOL) this.enableTool(TRAININGTOOL)
        else if (this.activeTool === TESTINGTOOL) this.enableTool(TESTINGTOOL)
        else if (this.activeTool === ACCURACYTOOL) this.enableTool(ACCURACYTOOL)
    }
    onDisableEdit = async (coordinates, layer, type) => {
        if ((this.activeTool === BOX || this.activeTool === DETECTIONTOOL || this.activeTool === TRAININGTOOL || this.activeTool === TESTINGTOOL || this.activeTool === ACCURACYTOOL) && this.checkForSmallAnnotation(layer)) {
            Popup.alert("ERROR", "The drawn annotation is too small. Please draw a larger one.", () => {
                layer.remove();
                this.enableLastSelectedTool()
            }, () => {
                layer.remove()
                this.enableLastSelectedTool()
            })
            return;
        }
        if (this.esc_pressed) {
            return;
        }
        if (!(this.selectedRegion || this.selectedArea || this.activeTool == POINTER)) {
            if (this.activeTool === DETECTIONTOOL || this.activeTool === TRAININGTOOL || this.activeTool === TESTINGTOOL || this.activeTool === ACCURACYTOOL) {
                if (await this.isRegionInsideImageBounds(coordinates)) {
                    if (L.GeometryUtil.geodesicArea(coordinates)) {
                        this.activeBox = layer
                        let areaColor;
                        if (this.activeTool === DETECTIONTOOL) {
                            areaColor = DETECTIONCOLOR
                        }
                        else if (this.activeTool === TRAININGTOOL) {
                            areaColor = TRAININGCOLOR
                        } else if (this.activeTool === TESTINGTOOL) {
                            areaColor = TESTINGCOLOR
                        } else if (this.activeTool === ACCURACYTOOL) {
                            areaColor = ACCURACYCOLOR
                        }
                        let region = {
                            id: uuid(),
                            color: areaColor,
                            type: POLYGON,
                            subType: this.activeTool,
                            coordinates: coordinates,
                        }
                        if (areaColor) {
                            this.drawAreas([region])
                        }
                        layer.remove()
                        if (this.activeTool === POLYGON) this.enableTool(POLYGON)
                        else if (this.activeTool === BOX) this.enableTool(BOX)
                        else if (this.activeTool === CIRCLE) this.enableTool(CIRCLE)
                        else if (this.activeTool === DETECTIONTOOL) this.enableTool(DETECTIONTOOL)
                        else if (this.activeTool === TRAININGTOOL) this.enableTool(TRAININGTOOL)
                        else if (this.activeTool === TESTINGTOOL) this.enableTool(TESTINGTOOL)
                        else if (this.activeTool === ACCURACYTOOL) this.enableTool(ACCURACYTOOL)
                    } else {
                        this.activeBox = layer
                    }
                }
                else {
                    layer.remove();
                    if (this.activeTool === POLYGON) this.enableTool(POLYGON)
                    else if (this.activeTool === BOX) this.enableTool(BOX)
                    else if (this.activeTool === CIRCLE) this.enableTool(CIRCLE)
                    else if (this.activeTool === DETECTIONTOOL) this.enableTool(DETECTIONTOOL)
                    else if (this.activeTool === TRAININGTOOL) this.enableTool(TRAININGTOOL)
                    else if (this.activeTool === TESTINGTOOL) this.enableTool(TESTINGTOOL)
                    else if (this.activeTool === ACCURACYTOOL) this.enableTool(ACCURACYTOOL)
                }
            } else {
                // if there is no any selected region means new measurement disabled and this should be save
                coordinates = (type === CIRCLE ? this.getLatLngsOfCircle(coordinates.latLng, coordinates.radius, POINTSONCIRCLE) : coordinates)
                if (await this.isRegionInsideImageBounds(coordinates)) { //checked if region inside image bounds or not And area of circle is greater than 0 or not
                    if (type == MARKER || type == POLYLINE || L.GeometryUtil.geodesicArea(coordinates)) {
                        if (type !== MARKER && type !== POLYLINE && this.isPolygonSelfIntersecting(coordinates)) {
                            this.update(this, LINEINTERSECTITSELF)
                            layer.remove()
                            this.enableTool(POLYGON)
                        }
                        else {
                            this.activeBox = undefined;

                            // converted circle to polygon
                            let region = {
                                id: uuid(),
                                color: this.activeDrawingClass.color,
                                type: type === MARKER ? MARKER : type === POLYLINE ? POLYLINE : type === BOX ? BOX : POLYGON,
                                className: this.activeDrawingClass.name,
                                coordinates: coordinates,
                            }
                            if (this.dontShowRegionName) { //if name is not present in region ignore all
                            } else {
                                const regionsWithSameClass = this.regions.filter(r => r.className == this.activeDrawingClass.name).sort((a, b) => {
                                    let numA = parseInt(getRegionNameAfterUnderscore(a.name));
                                    let numB = parseInt(getRegionNameAfterUnderscore(b.name));
                                    return numB - numA; // For descending order
                                });
                                let biggestRegionNumber = 0;
                                if (regionsWithSameClass.length > 0) {
                                    biggestRegionNumber = Number(getRegionNameAfterUnderscore(regionsWithSameClass[0].name))
                                }
                                region.name = `${this.activeDrawingClass.name}_${(biggestRegionNumber) + 1}`

                                // Transform the array into [longitude, latitude] pairs
                                const pointsArray = coordinates.map(coord => [coord.lng, coord.lat]);

                                // Create a FeatureCollection of points
                                const points = turf.featureCollection(pointsArray.map(coord => turf.point(coord)));

                                // Get the bounding box of the FeatureCollection
                                const bbox = turf.bbox(points);
                                const bboxCorners = [
                                    [bbox[1], bbox[0]],  // bottom-left (minY, minX)
                                    [bbox[1], bbox[2]],  // bottom-right (minY, maxX)
                                    [bbox[3], bbox[2]],  // top-right (maxY, maxX)
                                    [bbox[3], bbox[0]]   // top-left (maxY, minX)
                                ];
                                region.bounding_box = bboxCorners
                            }
                            this.drawRegions([region])
                            layer.remove()
                            if (this.activeTool === POLYGON) this.enableTool(POLYGON)
                            else if (this.activeTool === POLYLINE) this.enableTool(POLYLINE)
                            // else if (this.activeTool === MARKER) this.enableTool(MARKER)
                            else if (this.activeTool === BOX) this.enableTool(BOX)
                            else if (this.activeTool === CIRCLE) this.enableTool(CIRCLE)
                        }
                    } else {
                        this.activeBox = layer
                    }
                } else {
                    layer.remove()
                    if (this.activeTool === POLYGON) this.enableTool(POLYGON)
                    else if (this.activeTool === POLYLINE) this.enableTool(POLYLINE)
                    // else if (this.activeTool === MARKER) this.enableTool(MARKER)
                    else if (this.activeTool === BOX) this.enableTool(BOX)
                    else if (this.activeTool === CIRCLE) this.enableTool(CIRCLE)
                }
            }
        }
    }

    selectRegion = (regionId) => {
        if (regionId) {
            this.setSelectedAreaId("")

            this.selectArea()
        }
        const prevSelected = this.selectedRegion
        if (prevSelected) {
            prevSelected.leafletLayer.disableEdit()
            prevSelected.editable = false
            prevSelected.copying = false
        }
        const selectedRegion = regionId ? this.regions.find(reg => reg.id === regionId) : undefined
        this.selectedRegion = selectedRegion
        if (selectedRegion) {
            if (selectedRegion.type == MARKER) {
                this.defaultTooltip.setLatLng(selectedRegion.leafletLayer.getLatLng()).openOn(this.map)
            } else {
                selectedRegion.leafletLayer.bringToFront()
                this.defaultTooltip.setLatLng(selectedRegion.leafletLayer.getBounds().getSouthWest()).openOn(this.map)
            }
            this.highlightClasses()
            if (selectedRegion.type == MARKER) {
                const icon = L.divIcon({
                    html: `<div class='mapMarker' style='border-radius:50%; position:absolute; top:-3px; left:-3px; width:15px; height:15px; background: #fff;'></div>`,
                    iconSize: [32, 32],
                    iconAnchor: [16, 32],
                })
                selectedRegion.leafletLayer.setIcon(icon)
                selectedRegion.leafletLayer.openPopup()
            } else {
                this.selectedRegion.leafletLayer.setStyle(REGIONHIGHLIGHTSTYLE)
                selectedRegion.leafletLayer.openPopup(selectedRegion.leafletLayer.getBounds().getCenter())
            }
        } else {
            this.hideAllToolTips()
        }
        this.update({ ...this, selectedRegion })
    }
    selectArea = (areaId, areaSubType) => {
        // this.setSelectedAreaId("")
        this.changeZIndex && this.changeZIndex(1001)
        if (areaId) {
            this.setSelectedAreaId(areaId)
            // this.mapPanes.accuracyPane.style.zIndex=1003;
            this.highlightArea()
            this.selectRegion()
            this.highlightClasses()
        } else {
            // this.mapPanes.annotationPane.style.zIndex=1001;
        }
        const prevSelected = this.selectedArea

        if (prevSelected) {
            prevSelected.leafletLayer.disableEdit()
            prevSelected.editable = false
            // prevSelected.leafletLayer.setStyle({
            //     color: prevSelected.color,
            //     fillColor: prevSelected.color
            // })

        }
        const selectedArea = areaId
            ? (areaSubType === DETECTIONTOOL ?
                this.detectionArr.find(reg => reg.id === areaId)
                : areaSubType === TRAININGTOOL ?
                    this.trainingArr.find(reg => reg.id === areaId)
                    : (areaSubType === TESTINGTOOL ?
                        this.testingArr.find(reg => reg.id === areaId)
                        : (areaSubType === ACCURACYTOOL ?
                            this.accuracyArr.find(reg => reg.id === areaId) : undefined))
            ) : undefined

        this.selectedArea = selectedArea
        if (selectedArea) {
            this.selectedArea.leafletLayer.bringToFront()
            this.defaultTooltipArea.setLatLng(this.selectedArea.leafletLayer.getBounds().getSouthWest()).openOn(this.map)
            if (prevSelected) prevSelected.leafletLayer.setStyle({
                color: prevSelected.color,
                fillColor: prevSelected.color
            });
            this.selectedArea.leafletLayer.setStyle(REGIONHIGHLIGHTSTYLE)
        } else {
            this.highlightArea()
            this.hideAllToolTips()
        }
        this.update({ ...this, selectedArea: this.selectedArea })
    }
    regionClicked = (regionId, layer,) => {
        if (this.activeTool !== DETECTIONTOOL && this.activeTool !== TRAININGTOOL && this.activeTool !== TESTINGTOOL && this.activeTool !== ACCURACYTOOL)
            this.selectRegion(regionId)
    }
    areaClicked = (areaId, layer, areaSubType) => {
        if (!(this.selectedRegion && (this.selectedRegion.copying || this.selectedRegion.editable)))
            this.selectArea(areaId, areaSubType);
    }

    enableSelectedLayerCopy = (enable) => {
        if (this.disableUpdate) this.update(this, FIRENOTALLOWEDERROR);
        else if (enable && this.selectedRegion) {
            this.defaultTooltip.remove()
            this.setCursor("copy")
            if (this.selectedRegion.type == MARKER) {
                const icon = L.divIcon({
                    html: `<div class='mapMarker' style='border-radius:50%; position:absolute; top:-3px; left:-3px; width:15px; height:15px; background: ${this.selectedRegion.color};'></div>`,
                    iconSize: [32, 32],
                    iconAnchor: [16, 32],
                })
                this.selectedRegion.leafletLayer.setIcon(icon)
                this.copyEnableToolTip.setLatLng(this.selectedRegion.leafletLayer.getLatLng()).openOn(this.map)

            } else {

                this.selectedRegion.leafletLayer.setStyle(COPYINGREGIONSTYLE)
                this.copyEnableToolTip.setLatLng(this.selectedRegion.leafletLayer.getBounds().getSouthWest()).openOn(this.map)
            }
            this.update({ ...this, selectedRegion: { ...this.selectedRegion, copying: true } })
            this.selectedRegion.copying = true
        } else if (this.selectedRegion) {
            this.setCursor("auto")
            this.copyEnableToolTip.remove()
            if (this.selectedRegion.type == MARKER) {
                const icon = L.divIcon({
                    html: `<div class='mapMarker' style='border-radius:50%; position:absolute; top:-3px; left:-3px; width:15px; height:15px; background: ${this.selectedRegion.color};'></div>`,
                    iconSize: [32, 32],
                    iconAnchor: [16, 32],
                })
                this.selectedRegion.leafletLayer.setIcon(icon)
                this.defaultTooltip.setLatLng(this.selectedRegion.leafletLayer.getLatLng()).openOn(this.map)
            } else {

                this.selectedRegion.leafletLayer.setStyle({
                    color: this.selectedRegion.color,
                    fillColor: this.selectedRegion.color
                })
                this.defaultTooltip.setLatLng(this.selectedRegion.leafletLayer.getBounds().getSouthWest()).openOn(this.map)
            }
            this.update({ ...this, selectedRegion: { ...this.selectedRegion, copying: false } })
            this.selectedRegion.copying = false
        }
    }

    deleteSelectedRegion = async (overlayOnly) => {
        if (this.disableUpdate) this.update(this, FIRENOTALLOWEDERROR);
        else {
            this.hideAllToolTips()
            if (!this.dontShowRegionName) {
                //get all regions with class name same as deleted region
                let classRegions = this.regions.filter(r => r.className == this.selectedRegion.className)

                //seperate other regions
                const otherClassRegions = this.regions.filter(r => r.className !== this.selectedRegion.className)

                //find index of the deleted region ,which will help us to maintain count of new regions
                const index = classRegions.findIndex(r => r.id == this.selectedRegion.id)

                //now finally remove the region from the class,as it is deleted
                classRegions = classRegions.filter(r => r.id != this.selectedRegion.id)

                //now remove the region from actual all regions
                const removeRegionIndex = this.regions.findIndex(a => this.selectedRegion.id === a.id)
                this.annotationLayers.removeLayer(this.selectedRegion.leafletLayer)
                if (removeRegionIndex >= 0) this.regions.splice(removeRegionIndex, 1)

                let count = index + 1;
                for (let i = index; i < classRegions.length; i++) {
                    let region = classRegions[i];
                    region.name = `${region.className}_${count++}`;
                    var popup = region.leafletLayer.getPopup();
                    popup.setContent(`${getLimitedName(getRegionNameBeforeUnderscore(region.name), 5)}_${getRegionNameAfterUnderscore(region.name)}`);
                    classRegions[i] = region;
                }
                this.regions = [...otherClassRegions, ...classRegions]
                this.updateRegions = [...classRegions]
                this.drawnRegions = [...this.regions]
            }

            if (this.task.tasktype == 2 || this.task.tasktype == 4) {
                await this.update({ ...this, }, overlayOnly ? false : DELETEDETECTION)
                if (!this.dontShowRegionName && this.updateRegions.length > 0) {
                    await this.update({ ...this, updateRegions: this.updateRegions, }, overlayOnly ? false : EDITDETECTIONS)
                }
                this.update({ ...this, selectedRegion: undefined })
            } else {
                await this.update({ ...this, selectedRegion: undefined, deleteRegion: { ...this.selectedRegion, leafletLayer: undefined } }, overlayOnly ? false : UPDATEREGION)
            }
            // this.addToHistory("DELETE_REGION", { region: this.selectedRegion })
            this.selectedRegion = undefined
        }
    }

    deleteSelectedArea = () => {
        if (this.disableUpdate) this.update(this, FIRENOTALLOWEDERROR);
        else {
            this.hideAllToolTips()
            let removeAreaIndex;

            if (this.selectedArea.subType === DETECTIONTOOL) {

                removeAreaIndex = this.detectionArr.findIndex(a => this.selectedArea.id === a.id)
                //    this.detectionArr=this.detectionArr.filter(area=>area.id!==this.selectedArea.id)
                if (removeAreaIndex >= 0) this.detectionArr.splice(removeAreaIndex, 1)
            }
            else if (this.selectedArea.subType === TRAININGTOOL) {

                removeAreaIndex = this.trainingArr.findIndex(a => this.selectedArea.id === a.id)
                //    this.trainingArr=this.trainingArr.filter(area=>area.id!==this.selectedArea.id)
                if (removeAreaIndex >= 0) this.trainingArr.splice(removeAreaIndex, 1)
            } else if (this.selectedArea.subType === TESTINGTOOL) {
                removeAreaIndex = this.testingArr.findIndex(a => this.selectedArea.id === a.id)
                // this.testingArr=this.testingArr.filter(area=>area.id!==this.selectedArea.id)
                if (removeAreaIndex >= 0) this.testingArr.splice(removeAreaIndex, 1)
            } else if (this.selectedArea.subType === ACCURACYTOOL) {
                removeAreaIndex = this.accuracyArr.findIndex(a => this.selectedArea.id === a.id)
                //    this.accuracyArr=this.accuracyArr.filter(area=>area.id!==this.selectedArea.id)
                if (removeAreaIndex >= 0) this.accuracyArr.splice(removeAreaIndex, 1)
            }
            this.areaLayers.removeLayer(this.selectedArea.leafletLayer)

            this.update({ ...this, selectedArea: undefined, deleteArea: { ...this.selectedArea, leafletLayer: undefined } }, UPDATEREGION)
            this.selectedArea = undefined
        }
    }

    highlightClasses = (className) => {
        this.regions.map(region => {
            if (region.className === className) {
                region.leafletLayer.setStyle(REGIONHIGHLIGHTSTYLE)
            } else {
                if (region.type == MARKER) {
                    const icon = L.divIcon({
                        html: `<div class='mapMarker' style='border-radius:50%; position:absolute; top:-3px; left:-3px; width:15px; height:15px; background: ${region.color};'></div>`,
                        iconSize: [32, 32],
                        iconAnchor: [16, 32],
                    })
                    region.leafletLayer.setIcon(icon)
                } else {

                    region.leafletLayer.setStyle({
                        color: region.color,
                        fillColor: region.color
                    })
                }

            }
        })

        if (className) {
            this.selectRegion()
            this.selectArea()

        }


    }
    highlightArea = (areaId) => {
        // if(areaId){
        //     this.highlightClasses()
        //     this.selectRegion()
        // }else{
        //     this.selectArea()
        // }
        if (areaId) {
            this.selectArea();
        }

        const arr = [...this.detectionArr, ...this.trainingArr, ...this.testingArr, ...this.accuracyArr];
        arr.map(area => {
            if (area.id === areaId) {
                area.leafletLayer.setStyle(REGIONHIGHLIGHTSTYLE)
            } else {
                area.leafletLayer.setStyle({
                    color: area.color,
                    fillColor: area.color
                })
            }
        })

    }


    getLatLngsOfCircle = (latLng, radius, points) => {

        var rlat = ((radius / earthsradius / (180 / Math.PI)) * 2.0418);
        var rlng = rlat / Math.cos(latLng.lat * d2r);

        var extp = new Array();
        for (var i = 0; i < points; i++) {
            var theta = Math.PI * (i / (points / 2));
            let ex = latLng.lng + (rlng * Math.cos(theta));
            let ey = latLng.lat + (rlat * Math.sin(theta));
            extp.push({ lat: ey, lng: ex });
        }

        return (extp);

    }

    addTemperatureLabels = async (layer, coordinates, name, type, temperature, show) => {
        const p = new L.tooltip({ direction: "top", offset: [0, -30], className: " map-popup", pane: 'tooltips' })
            .setContent(
                type == MARKER ?
                    `<div>
                     <div>${name}</div>
                     ${temperature ? `
                        <div>Temperature : ${temperature.temperature.toFixed(2)} °C</div>
                     </div>
                        `: ''}
                     ` :
                    `
                     <div>
                     <div>${name}</div>
                     ${temperature ? `
                        <div>Min Temperature : ${temperature.min.toFixed(2)} °C</div>
                        <div>Max Temperature : ${temperature.max.toFixed(2)} °C</div>
                        <div>Avg Temperature :  ${temperature.mean.toFixed(2)} °C</div>
                        `: ''}
                     </div>
                    `
            )
            .setLatLng(coordinates)
        layer.bindTooltip(p)
        // if (show) layer.openTooltip()
        // else layer.closeTooltip()
    }
    drawRegions = async (drawingRegions, overlayOnly, overwriteExisting) => {
        const drawnRegionsPromises = drawingRegions.map(async (region, index) => {

            if (region.type === POLYGON) {
                let polygon = new L.Polygon(region.coordinates, {
                    name: region.id,
                    color: region.color,
                    fillColor: region.color,
                    pane: "annotation",
                    fillOpacity: 0.1,
                    weight: 3,
                }).addTo(this.annotationLayers).on('click', () => {
                    this.regionClicked(region.id, polygon)
                    polygon.bringToBack()
                    polygon.openPopup()
                })
                    this.addTemperatureLabels(polygon, region.coordinates, region.name, region.type, region.temperature, true)

                return ({ ...region, point: calculateCenterOfRegion(region), data: { datatype: region?.data?.datatype, id: region.id, note: region?.data?.note, percentage: region?.data?.percentage, severity: region?.data?.severity, ...region.data }, leafletLayer: polygon })
            }
            if (region.type === POLYLINE) {
                let polyline = new L.Polyline(region.coordinates, {
                    name: region.id,
                    color: region.color,
                    fillColor: region.color,
                    pane: "annotation",
                    fillOpacity: 0.1,
                    weight: 3,
                }).addTo(this.annotationLayers).on('click', () => {
                    this.regionClicked(region.id, polyline)
                    polyline.bringToBack()
                    polyline.openPopup()
                })

                    this.addTemperatureLabels(polyline, region.coordinates, region.name, region.type, region.temperature, true)

                return ({ ...region, point: calculateCenterOfRegion(region), data: { datatype: region?.data?.datatype, id: region.id, note: region?.data?.note, percentage: region?.data?.percentage, severity: region?.data?.severity, ...region.data }, leafletLayer: polyline })
            }
            if (region.type === MARKER) {
                var markerOptions = {
                    name: region.id,
                    title: "MyLocation",
                    pane: "annotation",
                    clickable: true,
                    draggable: false,
                }
                let marker = new L.Marker([region.coordinates[0].lat, region.coordinates[0].lng], markerOptions)
                const newIcon = L.divIcon({
                    html: `<div class='mapMarker' style='border-radius:50%; position:absolute; top:-3px; left:-3px; width:15px; height:15px; background: ${region.color};'></div>`,
                    iconSize: [32, 32],
                    iconAnchor: [16, 32],
                })
                // @ts-ignore
                marker.setIcon(newIcon);

                marker.addTo(this.annotationLayers).on('click', () => {
                    this.regionClicked(region.id, marker)
                    // marker.bringToBack()
                    marker.openPopup([region.coordinates[0].lat, region.coordinates[0].lng])
                })

                    this.addTemperatureLabels(marker, region.coordinates, region.name, region.type, region.temperature, true)

                return ({ ...region, point: [region.coordinates[0].lat, region.coordinates[0].lng], data: { datatype: region?.data?.datatype, id: region.id, note: region?.data?.note, percentage: region?.data?.percentage, severity: region?.data?.severity, ...region.data }, leafletLayer: marker })
            }
            if (region.type === BOX) {
                let box = new L.Rectangle(region.coordinates, {
                    name: region.id,
                    pane: "annotation",
                    color: region.color,
                    fillColor: region.color,
                    fillOpacity: 0.1,
                    weight: 3,
                }).addTo(this.annotationLayers).on('click', () => {
                    this.regionClicked(region.id, box)
                    box.bringToBack()
                    box.openPopup()
                })
                    this.addTemperatureLabels(box, region.coordinates, region.name, region.type, region.temperature, true)
                

                return ({ ...region, point: calculateCenterOfRegion(region), data: { datatype: region?.data?.datatype, id: region.id, note: region?.data?.note, percentage: region?.data?.percentage, severity: region?.data?.severity, ...region.data }, leafletLayer: box })
            }
            if (region.type === CIRCLE) {
                let circle = new L.Circle(region.coordinates.latLng, region.coordinates.radius, {
                    name: region.id,
                    color: region.color,
                    pane: "annotation",
                    fillColor: region.color,
                    fillOpacity: 0.1,
                    weight: 3,
                }).addTo(this.annotationLayers).on('click', () => {
                    this.regionClicked(region.id, circle)
                    circle.bringToBack()
                    circle.openPopup()
                })
                    this.addTemperatureLabels(circle, region.coordinates, region.name, region.type, region.temperature, true)

                return ({ ...region, point: calculateCenterOfRegion(region), data: { datatype: region?.data?.datatype, id: region.id, note: region?.data?.note, percentage: region?.data?.percentage, severity: region?.data?.severity, ...region.data }, leafletLayer: circle })
            }
        })
        const drawnRegions = [...(await Promise.all(drawnRegionsPromises)).filter(r => r)]
        let regs = []
        if (overwriteExisting) {
            regs = [...drawnRegions]
        } else {
            regs = [...this.regions, ...drawnRegions]
        }
        this.regions = [...regs]
        if (this.task.tasktype == 2 || this.task.tasktype === 4) {
            this.update({ ...this, regions: regs, drawnRegions }, overlayOnly ? false : UPDATEREGION)
        } else {
            this.update({ ...this, regions: regs, drawnRegions: drawingRegions, }, overlayOnly ? false : UPDATEREGION)
        }
        return regs
    }

    setSelectedRegionEditable = (trueOrFalse) => {
        if (this.disableUpdate) this.update(this, FIRENOTALLOWEDERROR);
        else if (trueOrFalse) {
            this.selectedRegion.leafletLayer.enableEdit()
            this.defaultTooltip.remove()
            if (this.selectedRegion.type == MARKER) {
                this.editEnableToolTip.setLatLng(this.selectedRegion.leafletLayer.getLatLng()).openOn(this.map)
            } else {
                this.editEnableToolTip.setLatLng(this.selectedRegion.leafletLayer.getBounds().getSouthWest()).openOn(this.map)
            }
            this.update({ ...this, selectedRegion: { ...this.selectedRegion, editable: true } })
            this.selectedRegion.editable = true
        }
        else {
            this.selectedRegion.leafletLayer.disableEdit()
            this.editEnableToolTip.remove()
            if (this.selectedRegion.type == MARKER) {
                this.defaultTooltip.setLatLng(this.selectedRegion.leafletLayer.getLatLng()).openOn(this.map)
            } else {

                this.defaultTooltip.setLatLng(this.selectedRegion.leafletLayer.getBounds().getSouthWest()).openOn(this.map)
            }
            this.update({ ...this, selectedRegion: { ...this.selectedRegion, editable: false } })
            this.selectedRegion.editable = false
        }
    }
    setSelectedAreaEditable = (trueOrFalse) => {
        if (this.disableUpdate) this.update(this, FIRENOTALLOWEDERROR);
        else if (trueOrFalse) {
            this.changeZIndex && this.changeZIndex(2000);
            this.selectedArea.leafletLayer.enableEdit()
            this.defaultTooltipArea.remove()
            this.editEnableToolTipArea.setLatLng(this.selectedArea.leafletLayer.getBounds().getSouthWest()).openOn(this.map)
            this.update({ ...this, selectedArea: { ...this.selectedArea, editable: true } })
            this.selectedArea.editable = true
        }
        else {
            this.changeZIndex && this.changeZIndex(1001);
            this.selectedArea.leafletLayer.disableEdit()
            this.editEnableToolTipArea.remove()
            this.defaultTooltipArea.setLatLng(this.selectedArea.leafletLayer.getBounds().getSouthWest()).openOn(this.map)
            this.update({ ...this, selectedArea: { ...this.selectedArea, editable: false } })
            this.selectedArea.editable = false
        }
    }

    onEnableEdit = (layer) => {
        if (this.activeTool !== POINTER) {
            this.activeNewLayer = layer
        }
    }

    enableTool = (tool) => {
        this.drawMarker = false;
        this.selectArea()
        this.selectRegion()
        this.highlightClasses()
        this.enableDrawingForTool(tool)
    }

    enableDrawingForTool = (tool) => {
        if (this.activeDrawingRegion) this.activeDrawingRegion.remove()
        switch (tool) {

            case DETECTIONTOOL:
                this.activeBox = undefined
                this.activeTool = DETECTIONTOOL
                this.activeDrawingRegion = this.map.editTools.startPolygon(undefined, {
                    pane: "Areas",
                    color: DETECTIONCOLOR
                })
                break;
            case TRAININGTOOL:
                this.activeBox = undefined
                this.activeTool = TRAININGTOOL
                this.activeDrawingRegion = this.map.editTools.startRectangle(undefined, {
                    pane: "Areas",
                    color: TRAININGCOLOR
                })
                break;
            case TESTINGTOOL:
                this.activeBox = undefined
                this.activeTool = TESTINGTOOL
                this.activeDrawingRegion = this.map.editTools.startRectangle(undefined, {
                    pane: "Areas",
                    color: TESTINGCOLOR
                })
                break;
            case ACCURACYTOOL:
                this.activeBox = undefined
                this.activeTool = ACCURACYTOOL
                this.activeDrawingRegion = this.map.editTools.startRectangle(undefined, {
                    pane: "Areas",
                    color: ACCURACYCOLOR
                })
                break;
            case POLYGON:
                this.activeBox = undefined
                this.activeTool = POLYGON
                this.activeDrawingRegion = this.map.editTools.startPolygon(undefined, {
                    pane: "annotation",
                    color: this.activeDrawingClass.color,
                })
                break;
            case POLYLINE:
                this.activeBox = undefined
                this.activeTool = POLYLINE
                this.activeDrawingRegion = this.map.editTools.startPolyline(undefined, {
                    pane: "annotation",
                    color: this.activeDrawingClass.color,
                })
                break;
            case MARKER:
                this.activeBox = undefined
                this.activeTool = MARKER
                this.activeDrawingRegion = this.map.editTools.startMarker(undefined, {
                    pane: "annotation",
                    color: this.activeDrawingClass.color,
                })
                break;
            case BOX:
                this.activeBox = undefined
                this.activeTool = BOX
                this.activeDrawingRegion = this.map.editTools.startRectangle(undefined, {
                    pane: "annotation",
                    color: this.activeDrawingClass.color,
                })
                break;
            case CIRCLE:
                this.activeBox = undefined
                this.activeTool = CIRCLE
                this.activeDrawingRegion = this.map.editTools.startCircle(undefined, {
                    radius: 0,
                    pane: "annotation",
                    color: this.activeDrawingClass.color,
                })
                break;

            case POINTER:
                this.activeTool = POINTER
                this.disableEdit()
                break;
            default: console.log("toggle type not valid");
        }
    }

    setCursor = (value) => {
        let element = document.getElementById("map")
        this.mapPrevCursor = element.style.cursor
        element.style.cursor = value
        // let elements = document.getElementsByClassName("leaflet-interactive")
        // for (let i = 0; i < elements.length; i++) {
        //     elements[i].style.cursor = value
        // }
    }

    disableEdit = async () => {
        if (this.activeNewLayer) {
            this.activeNewLayer.disableEdit()
        }
    }

    //listeners start here

    onUpdateLayer = (updatedLayer) => {
        this.map.editTools.on('editable:dragend', e => updatedLayer(e.layer))
        this.map.editTools.on('editable:vertex:dragend', e => updatedLayer(e.layer))
        this.map.editTools.on('editable:vertex:deleted', e => updatedLayer(e.layer))
        this.map.editTools.on('editable:vertex:new', e => updatedLayer(e.layer))
        this.map.editTools.on('editable:vertex:clicked', e => updatedLayer(e.layer))

    }

    onVertexDragStart = (dragStarted) => {
        this.map.editTools.on('editable:vertex:dragstart', e => dragStarted())
        this.map.editTools.on('editable:dragstart', e => dragStarted())

    }

    drawingEndEvent = (drawingEnd) => {
        this.map.editTools.on('editable:drawing:end', function (e) {
            drawingEnd(e)
        })
    }

    editEnableEvent = (setActiveEditLayer) => {
        this.map.editTools.on('editable:enable', function (e) {
            if (this.currentPolygon) this.currentPolygon.disableEdit();
            this.currentPolygon = e.layer;
            setActiveEditLayer(e.layer)
            this.fire('editable:enabled');
        });
    }

    editDisableEvent = (saveLayer, cancel) => {
        this.map.editTools.on('editable:disable', function (e) {

            let layer = e.layer
            if (layer instanceof L.Polygon && !(layer instanceof L.Rectangle)) {
                if (layer.getLatLngs()[0].length > 2) {
                    saveLayer(layer.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }), layer, POLYGON)
                } else {
                    cancel()
                }
            }
            if (layer instanceof L.Polyline && !(layer instanceof L.Rectangle)) {
                if ((layer.getLatLngs()).length == 2) {
                    saveLayer(layer.getLatLngs().map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }), layer, POLYLINE)
                } else {
                    cancel()
                }
            }
            if (layer instanceof L.Rectangle) {
                if (layer.getLatLngs()[0].length === 4) {
                    saveLayer(layer.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }), layer, BOX)
                } else {
                    cancel()
                }
            }
            if (layer instanceof L.Marker) {

            }
            if (layer instanceof L.Circle) {
                if (layer._zoomAnimated) {
                    saveLayer({ latLng: { lat: layer.getLatLng().lat, lng: layer.getLatLng().lng }, radius: layer.getRadius() }, layer, CIRCLE)
                } else {
                    cancel()
                }
            }
            delete this.currentPolygon;
        })
    }

    drawingCommitEvent = (saveLayer, cancel) => {
        this.map.editTools.on('editable:drawing:commit', function (e) {

            let layer = e.layer
            if (layer instanceof L.Marker) {
                const latlng = layer.getLatLng()
                saveLayer([{ lat: latlng.lat, lng: latlng.lng }], layer, MARKER)
            }
        })
    }


}