import React from 'react'
import { Popup, getLimitedName } from '../../../ReusableComponents/reusableFunctions'
import Tippy from '@tippyjs/react'
import ButtonWithIcon from '../../../ReusableComponents/ButtonWithIcon'
import ImportExportRegions from './ImportExportRegions'
import { PICSTORK_FEATURES } from '../../../Teams'
import AppConstants from '../../../AppConstants'

const MapTopSection = (props) => {
    return (

        <div style={{ display: "flex", justifyContent: "space-between", height: '35px', alignItems: 'center' }}>

            <div
                className="services-content-title"
                style={{ color: "#3c3c3c" }}
            >
                <span style={{ color: "#2989cf" }}>{props.selectedImageForAnnotation ? getLimitedName(props.selectedImageForAnnotation.file_name, 30) : "Orthomosaic"}</span>
                <span
                    className="content-text"
                    style={{ color: "#3c3c3c", fontWeight: 500, marginLeft: "20px" }}
                >
                    Annotations : <span style={{ color: "#2989cf", fontSize: "12px", marginRight: "20px" }}>{props.annotations}</span>
                </span>
            </div>

            <div style={{ display: "flex", alignItems: 'center' }}>
                {(props.task.tasktype === 2 || props.task.tasktype === 4) && props.task.linked_map && <>
                    {props.enableCalculateMesBtn &&
                        <Tippy
                            className="tippy-menu tippy-warning"
                            content={<span>Calculate measurements of all annotations at once</span>}>
                            <div>
                                <ButtonWithIcon
                                    text={"Calculate Measurements"}
                                    onClick={() => {
                                        props.annotationTool.current.calculateAllMeasurements()
                                    }}
                                />
                            </div>
                        </Tippy>
                    }
                    <ButtonWithIcon
                        text={"View report"}
                        isDisabled={props.annotations === 0 || props.validTiff}
                        linkData={
                            {
                                pathname: props?.task.task_purpose === AppConstants.TASKPURPOSE.COUNTING ? "/picstork/Pdf_Download" : "/picstork/defect_report",
                                state: {
                                    user: props.user,
                                    type: props.selectedReportType,
                                    task: props.task,
                                }
                            }
                        }
                    />
                    {props?.task.task_purpose === AppConstants.TASKPURPOSE.DEFECT && <ButtonWithIcon
                        text={"View thermal report"}
                        isDisabled={props.annotations === 0 || props.validTiff}
                        linkData={
                            {
                                pathname:"/picstork/thermal_report_ortho",
                                state: {
                                    user: props.user,
                                    type: props.selectedReportType,
                                    task: props.task,
                                }
                            }
                        }
                    />}
                </>
                }
                {props.annotationToolLoaded ?
                    <ImportExportRegions
                        permissions={props.permissions}
                        regions={props.annotations}
                        importRegions={props.annotationTool.current.importAnnotations}
                        exportRegions={props.annotationTool.current.exportAnnotations}
                    /> : <></>}
                <ButtonWithIcon
                    text="Clear" width="94px"
                    isDisabled={props.annotations < 1 || !props.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].EDIT}
                    onClick={(event) => {
                        if (props.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].EDIT && props.annotations && props.isTaskEditable(props.task.status, props.task.sharedBy)) {
                            Popup.alert("WARNING", 'Are you sure you want to clear all annotations ?', () => {
                                props.annotationTool.current.clearAllAnnotations()
                            })
                        } else props.annotationTool.current.setUpdateNotAllowed()
                    }}
                />
                <ButtonWithIcon text="Done" width="94px" onClick={() => props.setAnnotationDone(1)} />
            </div>
        </div>
    )
}

export default MapTopSection