import React, { Component } from 'react';
import * as API from '../api.js';
import service from "../sidebaricon/Services.png";
import Loader from '../icons/loader.svg';
import SideBar from '../SideBar';
import btnAdd from '../AeroCapture/assets/icons/btnAdd.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import Menu from '../AeroCapture/assets/icons/menu.svg';
import { Modal } from "react-bootstrap";
import waiting from '../res/waiting.png';
import Popup from "reactjs-popup";
import closeButton from '../close-button.png';
import { AnimatedModal } from './Reusable/AnimatedModal';
import success from '../assets/check_circle_24px.png';
import fail from '../assets/error.png';
import install from '../icons/AeroGCSInactive.png'
import TaskIcon from '../icons/servers.png'
import SupportIcon from '../icons/support.png'
import logList from '../icons/logList.png'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={Menu} alt="I am alt" />
    </a>
));

class subscriptiontems extends Component {

    state = {
        user: {},
        type: "",
        userperpage: 10,
        currentpage: 1,
        firstpage: 1,
        showBackIcon: true,
        loading: true,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        subscriptionDeletePopup: false,
        units: '',
        item_name: '',
        items: [],
        subscriptionupdatePopup: false,
    }

    onChangeListener = async (e) => {
        let t = e.target;
        if (t.value.includes(' ')) {
            // alert("spaces not allowed")
        }
        else {
            this.setState((state) => ({
                ...state,
                [t.name]: t.value
            }))
        }
    }

    saveSubscriptionItem = () => {
        this.setState((state) => ({
            deletingTaskText: 'Creating Item Please Wait...',
            createIcon: waiting
        }))
        API.saveSubscriptionItem(this.state.item_name, this.state.units).then((result) => {
            if (result.status == 200) {
                this.setState((state) => ({
                    ...state,
                    deletingTaskText: 'Item Saved Successfully',
                    createIcon: success
                }), () => {
                    this.getSubscriptionItem();
                })
            } else {
                this.setState((state) => ({
                    ...state,
                    deletingTaskText: 'Something Went Wrong',
                    createIcon: fail
                }))

            }
        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                ...state,
                deletingTaskText: e,
                createIcon: fail
            }))
        })
    }

    updateSubscriptionItem = () => {
        this.setState((state) => ({
            deletingTaskText: 'Updating Item Please Wait...',
            createIcon: waiting
        }))
        API.updateSubscriptionItem(this.state.item_name,this.state.units).then((data) => {
            if (data.status == 200) {
                this.setState((state) => ({
                    ...state,
                    deletingTaskText: 'Item Updated Successfully',
                    createIcon: success
                }), () => {
                    this.getSubscriptionItem();
                })
            } else {
                this.setState((state) => ({
                    ...state,
                    deletingTaskText: 'Something Went Wrong',
                    createIcon: fail
                }))

            }
        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                ...state,
                deletingTaskText: e,
                createIcon: fail
            }))
        })

    }

    deleteSubscriptionItem = () => {
        this.setState((state) => ({
            deletingTaskText: 'Deleting Item Please Wait...',
            createIcon: waiting
        }))
        API.deleteSubscriptionItem(this.state.deleteItem.item_name).then((data) => {
            if (data.message == 'Subscription item deleted.') {
                this.setState((state) => ({
                    ...state,
                    deletingTaskText: 'Item Deleted Successfully',
                    createIcon: success
                }), () => {
                    this.getSubscriptionItem();
                })
            } else {
                this.setState((state) => ({
                    ...state,
                    deletingTaskText: 'Something Went Wrong',
                    createIcon: fail
                }))

            }
        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                ...state,
                deletingTaskText: e,
                createIcon: fail
            }))

        })
    }



    getSubscriptionItem = () => {
        API.getSubscriptionItem().then((data) => {
            this.setState((state) => ({
                ...state,
                items: data.items,
                loading: false
            }))
        })
    }

    componentWillMount() {
        document.title = "AeroMegh - Subscription";
        if (this.props.location.state) {
            let { user, type, sidebarMode } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type, sidebarMode
            }))
            this.getSubscriptionItem()
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }))
                this.getSubscriptionItem()
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }



    render() {
        let { user, type } = this.state
        let sidebarConfigurations = {

            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
                {
                    icon: install,
                    text: "Installations",
                    path: "/admin/gcs-installation",
                },
                {
                    icon: TaskIcon,
                    text: "Tasks",
                    path: "/admin/tasks",
                },
                {
                    icon: logList,
                    text: "Task Logs",
                    path: "/admin/taskLogs",
                },
                {
                    icon: SupportIcon,
                    text: "Support",
                    path: "/admin/supportEmails",
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode,

        }


        return (
            user.username ? <div className="wrapper">

                <SideBar config={sidebarConfigurations} showMainDashboardIcons={this.state.showBackIcon} parentSidebarSwitch={this.parentSidebarSwitch} />
                <div className="right-content"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <div className="top-bar"><div className="top-bar-text">Subscription</div></div>
                    {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> : <div className="main-content" style={{ background: 'none' }}>
                        <Popup
                            trigger={<button id="delete-task-popup-button" style={{
                                display: "none",
                                // width:"30% !important"
                            }}></button>}
                            modal
                        >
                            <img
                                id="close-popup"
                                src={closeButton} style={{
                                    width: "auto",
                                    height: "26px",
                                    position: "absolute",
                                    top: "-13px",
                                    right: "-13px",
                                    cursor: "pointer"

                                }} onClick={() => {

                                    document.getElementById("delete-task-popup-button").click();
                                    this.setState((state) => ({
                                        ...state,
                                        createIcon: waiting,
                                        createCollectionMsg: 'Please Wait...',

                                    }))


                                }}></img>
                            <div >
                                <div style={{ width: "100%", display: "flex" }}>
                                    <img src={this.state.createIcon} style={{
                                        width: "auto",
                                        height: "64px",
                                        margin: "30px auto",
                                    }} />
                                </div>

                                <>
                                    <div style={{ width: "100%", textAlign: "center", marginBottom: '50px' }}>{
                                        this.state.deletingTaskText
                                    }</div>

                                </>

                            </div>
                        </Popup>
                        <Modal
                            // style={{ marginTop: '150px' }}
                            show={this.state.subscriptionDeletePopup}
                            onHide={() => {
                                this.setState((state) => ({
                                    ...state,
                                    subscriptionDeletePopup: false,

                                }));
                            }}
                            className="modal-dialog-centered"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Body >
                                <p>Are you sure you want to delete?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <div>
                                    <button
                                        className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                                        onClick={() => {
                                            this.setState((state) => ({
                                                ...state,
                                                subscriptionDeletePopup: false,
                                            }));
                                        }}
                                    >
                                        No
                                    </button>
                                </div>
                                <div>
                                    <button
                                        className="add-user-form-submit-button popup-btn-right btn-ok"
                                        onClick={() => {
                                            document.getElementById("delete-task-popup-button").click();
                                            this.setState((state) => ({
                                                ...state,
                                                subscriptionDeletePopup: false,
                                                cteateIcon: waiting,
                                                deletingTaskText: 'Deleting Project Please Wait...',
                                            }), () => {
                                                this.deleteSubscriptionItem()
                                            })
                                        }}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </Modal.Footer>
                        </Modal>



                        <AnimatedModal
                            isOpen={this.state.subscriptionAddPopup}
                            onRequestClose={() => {
                                this.setState((state) => ({
                                    ...state,
                                    inviteModal: false,
                                }))
                            }}
                            height="360px"
                            width="400px"
                        >

                            <div style={{ textAlign: "center", margin: '20px 0px', height: '40px', fontSize: '22px', fontWeight: '500' }}>Add Item</div>
                            <div style={{ height: '5px', width: '100%' }}><hr /></div>
                            <div style={{ margin: '10px 50px' }}>
                                <div className='sub-add-user-form-label'>Item Name</div>
                                <div className="add-user-form-text-wrapper">
                                    <input
                                        className="sub-add-user-form-text"
                                        type="text"
                                        name="item_name"
                                        placeholder="Enter Item Name"
                                        onChange={this.onChangeListener}
                                        value={this.state.item_name}
                                    />
                                </div>
                                <div className="add-user-form-label" style={{ marginTop: '15px' }}>
                                    Item Units
                                </div>
                                <div className="add-user-form-text-wrapper">
                                    <input
                                        className="sub-add-user-form-text"
                                        type="text"
                                        name="units"
                                        placeholder="Enter Units"
                                        onChange={this.onChangeListener}
                                        value={this.state.units}
                                    />
                                </div>
                            </div>
                            <div style={{ margin: '50px 50px 10px 50px', display: 'flex', justifyContent: 'space-between' }}>
                                <button
                                    className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            subscriptionAddPopup: false,
                                        }));
                                    }}
                                >
                                    Cancel
                                </button>
                                {this.state.item_name && this.state.units ? <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    onClick={() => {
                                        document.getElementById("delete-task-popup-button").click();
                                        this.setState((state) => ({
                                            ...state,
                                            subscriptionAddPopup: false,
                                            cteateIcon: waiting,
                                            deletingTaskText: 'Saving Item Please Wait...',
                                        }), () => {
                                            this.saveSubscriptionItem();
                                        })
                                    }}
                                >
                                    Save
                                </button> : <button style={{ opacity: '0.5' }}
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                >
                                    Save
                                </button>}
                            </div>
                        </AnimatedModal>

                        <AnimatedModal
                            isOpen={this.state.subscriptionupdatePopup}
                            onRequestClose={() => {
                                this.setState((state) => ({
                                    ...state,
                                    inviteModal: false,
                                }))
                            }}
                            height="360px"
                            width="400px"
                        >

                            <div style={{ textAlign: "center", margin: '20px 0px', height: '40px', fontSize: '22px', fontWeight: '500' }}>Updating Item</div>
                            <div style={{ height: '5px', width: '100%' }}><hr /></div>
                            <div style={{ margin: '10px 50px' }}>
                                <div className='sub-add-user-form-label'>Item Name</div>
                                <div className="add-user-form-text-wrapper" style={{opacity:'0.5'}}>
                                    <input
                                        className="sub-add-user-form-text"
                                        type="text"
                                        name="item_name"
                                        placeholder="Enter Item Name"
                                        onChange={this.onChangeListener}
                                        disabled= {true}
                                        value={this.state.item_name}
                                    />
                                </div>
                                <div className="add-user-form-label" style={{ marginTop: '15px' }}>
                                    Item Units
                                </div>
                                <div className="add-user-form-text-wrapper">
                                    <input
                                        className="sub-add-user-form-text"
                                        type="text"
                                        name="units"
                                        placeholder="Enter Units"
                                        onChange={this.onChangeListener}
                                        value={this.state.units}
                                    />
                                </div>
                            </div>
                            <div style={{ margin: '50px 50px 10px 50px', display: 'flex', justifyContent: 'space-between' }}>
                                <button
                                    className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            subscriptionupdatePopup: false,
                                        }));
                                    }}
                                >
                                    Cancel
                                </button>
                                {this.state.item_name && this.state.units ? <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    onClick={() => {
                                        document.getElementById("delete-task-popup-button").click();
                                        this.setState((state) => ({
                                            ...state,
                                            subscriptionupdatePopup: false,
                                            cteateIcon: waiting,
                                            deletingTaskText: 'Saving Item Please Wait...',
                                        }), () => {
                                            this.updateSubscriptionItem();
                                        })
                                    }}
                                >
                                    Update
                                </button> : <button style={{ opacity: '0.5' }}
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                >
                                    Update
                                </button>}
                            </div>
                        </AnimatedModal>
                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                            <div className="services-content-title">{"Subscription Items"}</div>
                            <div style={{ display: 'flex' }}>
                                <div className="add-user-form-text-wrapper" style={{ marginLeft: "25px", marginRight: '5px' }}>
                                    <img src={btnAdd} style={{ height: '30px', width: '34px', cursor: 'pointer' }} onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            item_name:'',
                                            units: '',
                                            subscriptionAddPopup: true,
                                        }));
                                    }} />
                                </div>


                            </div>
                        </div>
                        <div>
                            <table className="customers-table">
                                <col style={{ width: '35%' }} />
                                <col style={{ width: '30%' }} />
                                <col style={{ width: '20%' }} />
                                <col style={{ width: '10%' }} />
                                <tr className="customers-table-header-row">
                                    <th style={{ textAlign: 'center' }}>id</th>
                                    <th>Title</th>
                                    <th style={{ textAlign: 'center' }} >Unit</th>
                                    <th></th>
                                </tr>
                                {this.state.items.length === 0 ?
                                    <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                        <td colspan="4">
                                            {/* <img style={{height:'30%', width:'30%'}} src={Noproject} /> */}
                                            <p style={{ fontSize: '15px' }}>No Items available</p>
                                        </td>
                                    </tr> :
                                    this.state.items.map((item) =>
                                        <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                            <td style={{ textAlign: 'center' }}>{item.id}</td>
                                            <td>{item.item_name}</td>
                                            <td style={{ textAlign: 'center' }}>{item.unit}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <Dropdown style={{ margin: "0 20px" }}>
                                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" >
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu size="sm" title=""
                                                        className="custom-dropdown-settings"
                                                    >
                                                        <Dropdown.Item eventKey="delete"
                                                            className="dropdown-set-padding"
                                                        >
                                                            <div style={{ display: "flex", cursor: 'pointer' }}
                                                                onClick={(e) => {
                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        item_name: item.item_name,
                                                                        units: item.unit,
                                                                        subscriptionupdatePopup: true,
                                                                    }))
                                                                }}
                                                            >
                                                                <div style={{ marginLeft: '10px' }}>Edit</div>
                                                            </div>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item eventKey="delete"
                                                            className="dropdown-set-padding"
                                                        >
                                                            <div style={{ display: "flex", cursor: 'pointer' }}
                                                                onClick={(e) => {
                                                                  
                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        deleteItem: item,
                                                                        subscriptionDeletePopup: true
                                                                    }))
                                                                }}
                                                            >
                                                                <div style={{ marginLeft: '10px' }}>Delete</div>
                                                            </div>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>


                                    )}
                            </table>

                        </div>
                    </div>}
                </div>
            </div> : <div />
        );
    }
}

export default subscriptiontems;

