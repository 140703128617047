import React, { Component } from "react";
import * as API from "../../api.js";
import Image2 from "../../assets/Images/image2.jpg";
import Loader from "../../icons/loader.svg";
import Tiff from 'tiff.js'
import { Popup, } from '../../ReusableComponents/reusableFunctions'
import { AUTOSEGMENTATIONTOOL, BOX, CIRCLE, LINE, POINT, POINTER, POLYGON } from "./Annotation"
import AppConstants, { BLOB_URL, SOCKET_IO_MESSAGES } from "../../AppConstants";
import ClassListWithColor from "./ReusableComp/ClassListWithColor";
import { PICSTORK_FEATURES } from "../../Teams";
import { pushAnnotationToSocketRoom } from "../SocketIo/index.js";
import VideoPlayer from "./ReusableComp/VideoPlayer.js";
class VideoDetectionResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lineToolActive: false,
      pointToolActive: false,
      currentImgVal: 0,
      user: {},
      key: 0,
      type: "",
      progress: 0,
      showDownloadPopup: false,
      loader: false,
      services: [],
      objects: [],
      filter: [],
      showBackIcon: true,
      cancel: false,
      imageListindex: 0,
      panning: true,
      selected: 0,
      zoomcheck: 0,
      loading: false,
      indexLength: 10,
      image: Image2,
      outId: "",
      regions: [],
      setzoom: 0,
      sidebarMode: this.props
        ? this.props.sidebarMode
        : "shrunk",
      showPopup: false,
      message: "",
      scale: 0,
      showDiv: false,
      selectedObjects: [],
      addObjectPopup: false,
      newObject: "",
      regionIndex: 0,
      popupIcon: "",
      popupMessage: "",
      imageURL: '',
      thumbURLs: [],
      image_data: [],
      itemName: "",
      showProcessPopup: false,
      processPopupIcon: "",
      processAction: "",
      processMessage: "",
      imageLoad: true,
      showFilterPopup: false,
      severityLevels: [{ value: '1' }, { value: '2' }, { value: '3' }, { value: '4' }, { value: '5' }, { value: "All" }],
      severity: 'All',
      note: '',
      objCount: [],
      selectAll: true,
      obj: [],
      regionToCopy: null,
      classes: this.props?.data?.task?.classes ? this.props?.data?.task?.classes : [],
      isThermalImage: false,
      boxTemperature: "",
      thermalMeta: "",
      isThermalDataLoaded: false,
      isWindMillData: false,
      selectedWindMillImage: undefined,
      bladeSide: undefined,
      windMillRegions: undefined,
      selecteWindMillRegion: undefined,
      clickFromList: false,
      clearAnnotationPopup: false,
      currTempCalculated: true
    };
  }

  // to load tiff image 
  loadVideo = () => {
    let { task } = this.state
    let iurl = `${BLOB_URL}/${this.props.permissions?.container}/DetectionResults/${task.task_id}/output_${this.state.video.file_name}?${this.props.permissions?.st}`

    // let iurl = `${BLOB_URL}/${this.props.permissions?.container}/FlightImages/collection-${task.image_collection_id}/${this.state.video.file_name}?${this.props.permissions?.st}`
    this.setState({ imageURL: iurl })
  }

  getTask = async () => {
    return new Promise((resolve, reject) => {
      API.getAeroCaptureTask(this.state.task.task_id).then((data) => {
        this.setState((state) => ({
          ...state,
          task: data,
          video: data.video_task_details.images[0]
        }), () => {
          this.initObjects()
          this.loadVideo()
          resolve(0)
        })
      }, (e) => {
        console.log(e);
        resolve(0)
      })
    })
  }

  initObjects = async () => {
    let ob = this.state.task.type;
    var temp = { selected: false, type: "", id: 0 };
    var tmp = [];
    ob.forEach((type, key) => {
      let index = ob.findIndex((data) => { return data == type })
      temp.selected = index > -1 ? true : false;
      temp.type = type;
      temp.id = key;
      tmp.push(temp);
      temp = {};
    });
    tmp.push({})
    this.setState((state) => ({
      ...state,
      obj: tmp,
      filter: tmp
    }));
  }

  componentWillMount() {
    document.title = "PicStork -  Report Image";

    if (this.props) {
      let { user, type, task, report, key, selectedImage, plan, project, selectedSeverity, showAllImages } = this.props?.data;
      let detection = report?.[key];
      this.setState(
        (state) => ({
          ...state,
          user,
          type,
          task,
          plan,
          project,
          detection: detection,
          imageList: report,
          imageLoad: true,
          key,
          selectedImage,
          selected: key,
          zoomcheck: key,
          selectedSeverity: selectedSeverity ? selectedSeverity : 'All',
          showAllImages,
          selectAll: showAllImages,
          // panning: true
        }),
        async () => {
          // create function for socket connection to backend
          this.getTask()
          API.getUserSubbedServices().then((response) => {
            let services = response[0];
            if (services.length == 1 && services[0].title == "AeroStream") {
              this.setState((state) => ({
                ...state,
                showBackIcon: false,
              }));
            } else {
              this.setState((state) => ({
                ...state,
                showBackIcon: true,
              }));
            }
          });
        }
      );
    } else {
      API.getUserType().then(
        ([blah, type, user]) => {
          this.setState(
            (state) => ({
              ...state,
              user,
              type,
            }),
            () => {
              this.fetchThermalMeta();
              this.getRegions();

            }
          );
        },
        (e) => {
          window.location.replace("/login");
        }
      );
    }
  }

  render() {

    const { user, video } = this.state;
    return user.username ? (
      <div className="wrapper" style={{ width: '100%' }}>
        <div style={{ width: '100%' }}>
          {
            this.state.loading ? (
              <div style={{ height: "100%", width: "100%", display: "flex", textAlign: "center" }}>
                <img src={Loader} style={{ height: "30vh", width: "100%", margin: "auto" }} />
              </div>
            ) : (
              <div style={{ background: "none", width: '100%', height: '100%' }}>
                <>
                  <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                    <div>
                      <span className={"onhoverext hoverPointer"}
                        onClick={() => this.props.changeView(1)}
                        style={{
                          color: "#2989cf",
                          fontSize: "12px",
                        }}>Task</span><span style={{
                          fontSize: "12px",
                        }}>&nbsp; /&nbsp; </span><span className={"onhoverext hoverPointer"}
                          onClick={() => this.props.changeView(2)}
                          style={{
                            color: "#2989cf",
                            fontSize: "12px",
                          }}>Report</span><span style={{
                            fontSize: "12px",
                          }}>&nbsp; /&nbsp; </span>Image
                    </div>

                  </div>

                  <div id='container-full-screen' style={{ display: "flex", justifyContent: 'space-between', marginTop: "8px", width: "100%", height: "94%", background: 'white', padding: '10px 0px 10px 10px' }}>
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <VideoPlayer
                        url={this.state.imageURL}
                      />
                    </div>
                    {/* class with colour */}
                    <div style={{ display: "flex", justifyContent: 'space-between', cursor: 'pointer', right: '0%', pointerEvents: "none", zIndex: 10 }} className="thermal-width">
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          zIndex: 10,
                          pointerEvents: "all",
                          height: '100%',
                          width: "100%",
                          bottom: '0px',
                          color: '#3c3c3c'
                        }}>

                        {/* classes list */}
                        {/* <div style={{ color: "white", cursor: "default", paddingBottom: "60px" }}> */}
                        <ClassListWithColor
                          hideAddClass={true}
                          task={this.state.task}
                          dontShowRegionName={true}
                          isThermalDataLoaded={this.state.isThermalDataLoaded}
                          onClassUpdate={this.onClassUpdate}
                          onEditClassColor={this.onEditClassColor}
                          // onEditClass={this.state.task.tasktype == 2 ? undefined : this.editClassName}
                          // onDeleteClass={this.state.task.tasktype == 2 ? undefined : this.deleteClass}
                          updateData={(key, value) => {
                            if (this.state.selectedRegion) {
                              let region = this.state.selectedRegion
                              region.data[key] = value
                              this.setState({
                                selectedRegion: region
                              })
                            }
                            if (key === 'userName') {
                              this.setState({
                                mentionedUser: value
                              })
                            } else if (this.state.selectedRegion) {
                              let region = this.state.selectedRegion
                              region.data[key] = value
                              this.setState({
                                selectedRegion: region
                              })
                            }

                          }}
                          onRegionDataUpdate={this.onRegionDataUpdate}
                          classListWithColor={this.state.classes}
                          addNewObject={this.addNewObject}
                          onClickClass={(cls) => {
                          
                          }}
                          taskType={this.state.task.tasktype}
                          imageHeight={Number(this.state.imageHeight)}
                          imageWidth={Number(this.state.imageWidth)}
                          regions={this.state.regions}
                          activeClass={this.state.activeClass}
                          selectedRegion={this.state.selectedRegion}
                          taskIsInCreating={this.taskIsInCreating}
                          permissions={this.props.permissions}
                          isThermalImage={this.state.isThermalImage}                                //for thermal image
                          thermalMeta={this.state.thermalMeta}
                          highlightAnnotation={this.highlightAnnotation}
                          toggleClickedFromList={this.toggleClickedFromList}
                          clickFromList={this.state.clickFromList}
                          task_purpose={this.state.task.task_purpose}
                          toggleTemperature={this.toggleTemperature}
                          enableColorPicker
                        //for thermal meta 
                        />
                        {/* </div> */}
                        {/* classesList */}
                      </div>
                    </div>
                  </div>
                  {/* ---------------------------------- */}
                </>
              </div>
            )
          }
        </div >
      </div >
    ) : (
      <div />
    );
  }
}

export default VideoDetectionResult;
