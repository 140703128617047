import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import * as turf from '@turf/turf'
import L from 'leaflet'
import ReactTooltip from "react-tooltip";
import polygonActive from '../../../icons/polygonActive.png'
import polygonInactive from '../../../icons/polygonInactive.png'
import boxInactive from '../../../icons/boxAnnotation.png';
import boxActive from '../../../icons/boxAnnotationActive.png';
import { TRAININGTOOL, TESTINGTOOL, ACCURACYTOOL, POLYGON, POINTER, BOX, CIRCLE, UPDATEREGION, UPDATECLASS, FIRENOTALLOWEDERROR, OUTSIDEORTHOREGIONERROR, AREAOVERLAP, UPDATEREGIONSANDCLASSES, FILETYPES, DEFAULTCLASSNAME, LINEINTERSECTITSELF, CLEARDETECTIONS, EDITDETECTION, DELETEDETECTION, EDITDETECTIONS, POLYLINE, MARKER } from "./Constants"
import Annotation from "./Annotation";
import ClassListWithColor from "../ReusableComp/ClassListWithColor";
import * as API from "../../../api.js";
import AppConstants, { BLOB_URL, SOCKET_IO_MESSAGES, TASKPURPOSE } from "../../../AppConstants";
import { ActivityPopup } from "../../../ReusableComponents/ActivityPopup";
import { Popup, calculateCenterOfRegion, generateRandomAlphanumeric, getLimitedName, getSingleGeoJSON, importShapeFile, isTaskEditable } from "../../../ReusableComponents/reusableFunctions";
import pointerActive from '../../../icons/cursorActive.png';
import pointerInactive from "../../../icons/cursorWhite.png"
import CircleActive from '../../../icons/CircleActive.png';
import CircleInactive from "../../../icons/CircleWhite.png"
import trainingToolActive from "../../../icons/trainingActive.png"
import trainingToolInactive from "../../../icons/training.png"
import testingToolActive from "../../../icons/testingActive.png"
import testingToolInactive from "../../../icons/testing.png"
import accuracyToolActive from "../../../icons/accuracyActive.png"
import accuracyToolInactive from "../../../icons/accuracy.png"
import locateWhiteIcon from "../../../assets/Icons/locateMeWhite.png"
import lineActive from "../../../assets/Icons/lineToolActive.png"
import lineInactive from "../../../assets/Icons/lineToolInactive.png"
import markerActive from "../../../assets/Icons/pointToolActive.png"
import markerInactive from "../../../assets/Icons/pointToolInactive.png"
import { v4 as uuid } from "uuid";
import { PICSTORK_FEATURES } from "../../../Teams";
import { connectToSocketIOGroup, pushAnnotationToSocketRoom } from "../../SocketIo/index.js";
import { BOTH, ELEVATION, VOLUME } from "../ReusableComp/TaskProcessView.js";
import { getArea } from "../../../ReusableComponents/map/leafletMeasure.js";

var utmObj = require('utm-latlng');
var utm = new utmObj();

const SingleToolView = (props) => {
    return <div className={props.disabled ? "AeroFarm" : "hoverPointer AeroFarm"}
        onClick={props.onClick}
        style={{ padding: "8px 1px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
        <img src={props.icon} data-tip data-for={props.tittle} width={props.width ? props.width : '22px'} style={{ opacity: props.disabled ? 0.5 : 1 }} />
        <ReactTooltip offset={{ left: 10 }} id={props.tittle} place="left" type="light" effect="solid"><span>{props.tittle}</span></ReactTooltip>
    </div>
}
export const AnnotationTool = forwardRef((props, ref) => {

    const fileInputRef = useRef(null);

    const [user, setUser] = useState(null)
    const [tempSelectedRegion, setTempSelectedRegion] = useState()
    const [trainingArrWithChildren, setTrainingArrWithChildren] = useState([])
    const [testingArrWithChildren, setTestingArrWithChildren] = useState([])
    const [accuracyArrWithChildren, setAccuracyArrWithChildren] = useState([])
    const [activeAnnotationTool, setActiveAnnotationTool] = useState(POINTER)
    const [leafletDraw, setLeafletDraw] = useState(undefined)
    const [highlightClassInList, setHighlightClassInList] = useState(undefined)
    const [activityPopup, setActivityPopup] = useState(undefined)
    const [importType, setImportedType] = useState(FILETYPES.JSON)
    const [detectionResult, setDetectionResult] = useState(false)
    const [selectedAreaId, setSelectedAreaId] = useState()
    const [mentionUser, setMentionUser] = useState(false)
    const [dontShowRegionName, setDontShowRegionName] = useState(false)
    const onClickTool = (tool) => {
        setActiveAnnotationTool(activeAnnotationTool === tool ? POINTER : tool)
        leafletDraw.enableTool(activeAnnotationTool === tool ? POINTER : tool)
        if (tool !== POINTER) setHighlightClassInList(leafletDraw.activeDrawingClass.name)
        else setHighlightClassInList(undefined)
    }
    const isTaskHasEditAccess = () => {
        return (props.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].EDIT && isTaskEditable(props.task.tasktype == 2 || props.task.tasktype == 4 ? AppConstants.TASK.STATUS.CREATING : props.task.status, props.task.sharedBy))
    }
    const onUpdateLeaflet = async (updatedLeafletClass, action) => {
        //updating backend data
        try {
            if (action === UPDATEREGION) {
                if (props.task.tasktype == 2 || props.task.tasktype == 4) {
                    await saveDetections(updatedLeafletClass.drawnRegions, updatedLeafletClass.getLatLngsOfCircle)
                    props.setAnnotationsCount(updatedLeafletClass.regions.length)
                } else {
                    if (!updatedLeafletClass.overlayOnly) {
                        await saveAnnotations(updatedLeafletClass.regions, updatedLeafletClass.getLatLngsOfCircle, undefined, updatedLeafletClass)
                        props.setAnnotationsCount(updatedLeafletClass.regions.length)
                        let areas;
                        let regions;
                        if (updatedLeafletClass.drawnAreas) {
                            areas = updatedLeafletClass.drawnAreas.map(a => ({ ...a, leafletLayer: undefined }))
                        }
                        if (updatedLeafletClass.drawnRegions) {
                            regions = updatedLeafletClass.drawnRegions.map(a => ({ ...a, leafletLayer: undefined }))
                        }
                        pushAnnotationToSocketRoom({
                            areas: areas || [],
                            regions: regions || [],
                            selectedRegion: { ...updatedLeafletClass.selectedRegion, leafletLayer: undefined },
                            selectedArea: { ...updatedLeafletClass.selectedArea, leafletLayer: undefined },
                            clearAllAnnotations: updatedLeafletClass.clearAllAnnotations,
                            deleteArea: updatedLeafletClass.deleteArea,
                            deleteRegion: updatedLeafletClass.deleteRegion,
                        },
                            SOCKET_IO_MESSAGES.PICSTORK.TRAINING_ORTHO_AREA_OR_REGION_CHANGED, props.task.task_id)

                        pushAnnotationToSocketRoom({
                            annotationCount: updatedLeafletClass.regions.length,
                        },
                            SOCKET_IO_MESSAGES.PICSTORK.ANNOTATION_COUNT_CHANGED, props.task.task_id)
                    }
                }
                setLeafletDraw(updatedLeafletClass)
            }
            if (action == EDITDETECTION) {
                if (updatedLeafletClass.regions.filter(r => checkIfCalculateAvailable(r)).length > 0) {
                    props.enableCalculateMesBtnFunc(true)
                } else {
                    props.enableCalculateMesBtnFunc(false)
                }
                editDetection(updatedLeafletClass.selectedRegion, updatedLeafletClass.getLatLngsOfCircle, updatedLeafletClass.oldClassName, updatedLeafletClass)
            }
            if (action == EDITDETECTIONS) {
                editDetections(updatedLeafletClass.updateRegions, updatedLeafletClass.oldClassName)
            }
            if (action == DELETEDETECTION) {
                await deleteDetection(updatedLeafletClass.selectedRegion)
                props.setAnnotationsCount(updatedLeafletClass.regions.length)
            }
            if (action == CLEARDETECTIONS) {
                if (props.task.tasktype == 2 || props.task.tasktype == 4) {
                    clearAllDetections()
                    props.setAnnotationsCount(updatedLeafletClass.regions.length)
                }
            }
            if (action === UPDATECLASS) {
                updateClasses(updatedLeafletClass.classes)
                pushAnnotationToSocketRoom({ newClasses: updatedLeafletClass.newClasses }, SOCKET_IO_MESSAGES.PICSTORK.ORTHO_CLASS_UPDATED, props.task.task_id)
            }
            if (action === UPDATEREGIONSANDCLASSES) {
                if (props.task.tasktype == 2 || props.task.tasktype == 4) {
                    pushAnnotationToSocketRoom({ classes: updatedLeafletClass.classes, editedClass: updatedLeafletClass.editedClass }, SOCKET_IO_MESSAGES.PICSTORK.ORTHO_CLASS_UPDATED, props.task.task_id)
                    updateClasses(updatedLeafletClass.classes)//updating classes only for detection in this scope
                } else {
                    if (updatedLeafletClass.classes.length === 0) setActiveAnnotationTool(POINTER)
                    if (activeAnnotationTool !== POINTER) setHighlightClassInList(updatedLeafletClass.classes[0] ? updatedLeafletClass.classes[0].name : undefined)
                    await saveAnnotations(updatedLeafletClass.regions, updatedLeafletClass.getLatLngsOfCircle, updatedLeafletClass.classes, updatedLeafletClass) //classes passed here for update
                    props.setAnnotationsCount(updatedLeafletClass.regions.length)
                    pushAnnotationToSocketRoom({ classes: updatedLeafletClass.classes, editedClass: updatedLeafletClass.editedClass, deletedClass: updatedLeafletClass.deletedClass, classNameEdited: updatedLeafletClass.classNameEdited }, SOCKET_IO_MESSAGES.PICSTORK.ORTHO_CLASS_UPDATED, props.task.task_id)
                }

            }
            //updating backend data end

            //updating leaflet state 
            if (action === FIRENOTALLOWEDERROR) {
                setUpdateNotAllowed()
            } else if (action === OUTSIDEORTHOREGIONERROR) {
                setOutsideOrthoRegionE()
            } else if (action === AREAOVERLAP) {
                setOverlapArea()
            }
            else if (action === LINEINTERSECTITSELF) {
                setOrthoLineIntersect()
            }
            else {
                setLeafletDraw(updatedLeafletClass)
            }

        }
        catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        props.setAnnotationsCount(0)
        API.getUserType().then(([blah, type, u]) => {
            setUser(u)
        })
        let lD
        const classes = props.task.classes && props.task.classes.length > 0 ? props.task.classes : []

        if (props.map && props.bounds) {
            lD = new Annotation(setSelectedAreaId, props.changeZIndex, { map: props.map, imageBounds: props.bounds, mapPanes: props.mapPanes, }, activeAnnotationTool, classes, onUpdateLeaflet)
            taskAfterLeaflet(lD, classes)
        }

        return () => lD.destroy()
    }, [props.map])
    const taskAfterLeaflet = (lD, classes) => {
        lD.setUpdating(isTaskHasEditAccess())
        if (highlightClassInList) {
            const cls = classes.find(cl => cl.name == highlightClassInList)
            lD.setActiveDrawingClass(cls)
        }
        lD.task = props.task
        setLeafletDraw(lD)
        if (props.task && props.task.tasktype == 1)
            getAnnotationData(lD, classes)
        else if (props.task && (props.task.tasktype == 2 || props.task.tasktype == 4))
            getDetectionData(lD, classes)

        initTeamsLiveChangesSetup(lD)
    }
    useEffect(() => {
        if (leafletDraw?.regions) {
            if (leafletDraw.regions.filter(r => checkIfCalculateAvailable(r)).length > 0 && props.enableCalculateMesBtnFunc) {
                props.enableCalculateMesBtnFunc(true)
            } else if (props.enableCalculateMesBtnFunc) props.enableCalculateMesBtnFunc(false)
        }
    }, [leafletDraw?.regions?.length])
    useEffect(() => {

        return () => {

            pushAnnotationToSocketRoom({}, SOCKET_IO_MESSAGES.LEAVE_ROOM, props.task.task_id)
        }
    }, [])
    useEffect(() => {
        const classes = props.task.classes && props.task.classes.length > 0 ? props.task.classes : []
        if (leafletDraw) {
            leafletDraw.removePrevAnnotations()
        }
    }, [props.imageInfo.file_name])
    const calculateAreaOfRegions = (regions) => {
        let areas = []
        for (let i = 0; i < regions.length; i++) {
            let region = regions[i]
            const area = getArea(region.coordinates, "m", false,)
            areas.push({ id: region.id, area })
        }
        return areas;
    }
    const getPicstorkMeasureFunc = async (coordinates) => {
        if (coordinates.length > 1) {
            Popup.alert("WAIT", "Calculating measurements, please wait...")
        }
        return new Promise(async (resolve, reject) => {
            try {

                let image_paths = []
                if (props.task.task_parameters?.dsm_url) {
                    const dsm_url = `${BLOB_URL}/${user.blob_container}/FlightImages/collection-${props.task.image_collection_id}/${props.task.task_parameters.dsm_url}?${user.sas_token}`
                    image_paths.push(dsm_url)
                }
                if (props.task.task_parameters?.dtm_url) {
                    const dtm_url = `${BLOB_URL}/${user.blob_container}/FlightImages/collection-${props.task.image_collection_id}/${props.task.task_parameters.dtm_url}?${user.sas_token}`
                    image_paths.push(dtm_url)
                }


                let payload = {
                    image_paths,
                    polygon_coordinates: coordinates,
                    area: true
                }
                if ((props.task?.task_parameters?.measurement_type == VOLUME || props.task?.task_parameters?.measurement_type == BOTH || props.task.tasktype == 4) && props?.task.task_parameters?.dsm_url) {
                    payload.volume = true;
                }
                if ((props.task?.task_parameters?.measurement_type == ELEVATION || props.task?.task_parameters?.measurement_type == BOTH || props.task.tasktype == 4) && props?.task.task_parameters?.dsm_url) {
                    payload.elevation = true;
                }
                if (payload.area && !payload.volume && !payload.elevation) {
                    const result = calculateAreaOfRegions(leafletDraw.regions.filter(r => checkIfCalculateAvailable(r)))
                    return resolve(result)
                } else {
                    const result = await API.getPicstorkMeasure(payload)
                    return resolve(result)
                }

            } catch (err) {
                reject(err)
            }
        })
    }
    const checkIfCalculateAvailable = (region) => {
        if (!props.task?.linked_map) return false
        if (props.task?.task_parameters?.measurement_type == VOLUME || (props.task?.task_parameters?.dsm_url && props.task.tasktype == 4)) {
            if (region.volume == undefined || region.volume == null) return true;
        }
        else if (props.task?.task_parameters?.measurement_type == ELEVATION || (props.task?.task_parameters?.dsm_url && props.task.tasktype == 4)) {
            if (region.elevation == undefined || region.elevation == null) return true;
        }
        else if (props.task?.task_parameters?.measurement_type == BOTH || (props.task?.task_parameters?.dsm_url && props.task.tasktype == 4)) {
            if (region.elevation == undefined || region.elevation == null || region.volume == undefined || region.volume == null) return true;
        }
        else if (region.area == null || region.area == undefined) return true;
        return false
    }
    const calculateMeasurements = async (id) => {

        try {

            let newRegion = leafletDraw.regions.find(r => r.id == id && checkIfCalculateAvailable(r))
            if (newRegion) {
                const result = await getPicstorkMeasureFunc([{ id: newRegion.id, coordinates: newRegion.coordinates.map(c => ([c.lat, c.lng])) }])
                const annoResult = result.find(r => r.id == id)
                const volume = annoResult?.volume
                const area = annoResult?.area
                const elevation = annoResult?.elevation
                if (volume !== undefined) {
                    newRegion.volume = volume;
                }
                if (area !== undefined) {
                    newRegion.area = area;
                }
                if (elevation !== undefined) {
                    newRegion.elevation = elevation;
                }
                const newRegions = leafletDraw.regions.map(r => {
                    if (r.id === id) return newRegion;
                    else return r;
                })
                leafletDraw.regions = newRegions
                leafletDraw.selectedRegion = { ...leafletDraw.selectedRegion, ...newRegion }
                let lD = { ...leafletDraw }
                if (leafletDraw.regions.filter(r => checkIfCalculateAvailable(r)).length == 0) {
                    props.enableCalculateMesBtnFunc(false)
                } else {
                    props.enableCalculateMesBtnFunc(true)
                }
                setLeafletDraw(lD)
                await editDetections([newRegion], lD.getLatLngsOfCircle,)
                Popup.close()
            }
        }
        catch (err) {
            console.log(err);
        }
    }
    const calculateTemperature = async (id) => {
        try {

            let region = leafletDraw.regions.find(r => r.id == id)
            if (region) {
                if (region.type == POLYLINE) {
                    region.temperature = await API.getMapPolylineTemperature(props.imageInfo.collection_id, props.imageInfo.file_name, props.permissions.st, props.permissions.container, region.coordinates)
                } else if (region.type == MARKER) {
                    region.temperature = await API.getMapPointTemperature(props.imageInfo.collection_id, props.imageInfo.file_name, props.permissions.st, props.permissions.container, region.coordinates)
                } else if (region.type == BOX) {
                    region.temperature = await API.getMapRectTemperature(props.imageInfo.collection_id, props.imageInfo.file_name, props.permissions.st, props.permissions.container, region.coordinates)
                }
                else if (region.type == POLYGON || region.type == CIRCLE) {
                    region.temperature = await API.getMapPolygonTemperature(props.imageInfo.collection_id, props.imageInfo.file_name, props.permissions.st, props.permissions.container, region.coordinates)
                }
                region.editable = false;
                const newRegions = leafletDraw.regions.map(r => {
                    if (r.id === id) return region;
                    else return r;
                })
                leafletDraw.regions = newRegions
                leafletDraw.selectedRegion = { ...leafletDraw.selectedRegion, ...region }
                leafletDraw.addTemperatureLabels(leafletDraw.selectedRegion.leafletLayer,region.coordinates, region.name, region.type, region.temperature, true)
                let lD = { ...leafletDraw }
                setLeafletDraw(lD)
                await editDetections([region], lD.getLatLngsOfCircle,)
                Popup.close()
            }
        }
        catch (e) {
            throw e
        }
    }
    const focusOnRegion = (id) => {
        onClickTool(POINTER)
        const region = leafletDraw.regions.find(r => r.id == id)
        if (region) {
            leafletDraw.regionClicked(region.id, region.leafletLayer)
            var padding = [props.map.getSize().x * 0.4, props.map.getSize().y * 0.4];
            if (region.type == MARKER) {
                const markerPosition = region.leafletLayer.getLatLng(); // Get the position of the marker
                props.map.fitBounds(L.latLngBounds([markerPosition]), {
                    padding
                })
            } else {
                props.map.fitBounds(region.leafletLayer.getBounds(), {
                    padding
                })
            }
        }
    }
    const initTeamsLiveChangesSetup = async (lD) => {
        try {
            await connectToSocketIOGroup(props.task.task_id, async (annotation, type) => {
                // annotation added
                if (type === SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTIONS_ADDED) {
                    const regions = annotation.regions
                    props.setAnnotationsCount(regions.length)
                    lD.drawRegions(regions, true)
                }
                // annotation deleted
                else if (type === SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTIONS_DELETED) {
                    const id = annotation.id;
                    props.setAnnotationsCount(lD.regions.length - 1)
                    lD.selectedRegion = lD.regions.find(r => r.id === id);
                    lD.deleteSelectedRegion(true)

                    // edit annotation
                } else if (type === SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTION_EDITED) {
                    const region = annotation.region;
                    const newRegs = lD.regions.map(r => {
                        if (r.id == region.id) {
                            r = { ...r, ...region }
                            r.leafletLayer.setLatLngs(region.coordinates)

                            return r;

                        } else return r;
                    })
                    if (lD.selectedRegion) {
                        lD.selectedRegion.data = region.data;
                        // lD.selectedRegion.editable=false;
                    }
                    lD.regions = newRegs
                    setLeafletDraw(lD)

                }
                else if (type === SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTION_EDITED) {
                    const newRegs = annotation.regions;
                    if (lD.selectedRegion) {
                        const selectedRegion = newRegs.find(r => r.id == lD.selectedRegion.id);
                        if (selectedRegion) {

                            lD.selectedRegion.data = selectedRegion.data;
                        }
                        // lD.selectedRegion.editable=false;
                    }
                    lD.regions = newRegs
                    setLeafletDraw(lD)
                }
                else if (type === SOCKET_IO_MESSAGES.PICSTORK.ORTHO_CLASS_UPDATED) {

                    const classes = annotation.classes
                    if (classes) {
                        lD.classes = classes
                    }
                    if (annotation.editedClass) {

                        const { className, color } = annotation.editedClass
                        lD.onEditClassColor(className, color, true)
                    } else if (annotation.deletedClass) {
                        lD.onDeleteClass(annotation.deletedClass, true)
                    } else if (annotation.classNameEdited) {
                        const { oldClassName, newClassName } = annotation.classNameEdited;
                        lD.onEditClassNameInClassList(oldClassName, newClassName, true)
                    } else if (annotation.newClasses) {
                        const { newClasses } = annotation;
                        lD.addNewClasses(newClasses, true)
                        if (!lD.activeDrawingClass) {
                            lD.setActiveDrawingClass(newClasses[0])
                        }
                    }
                    setLeafletDraw(lD)

                } else if (type === SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTIONS_CLEARED) {
                    lD.annotationLayers.clearLayers()
                    lD.regions = []
                    props.setAnnotationsCount(0)
                    setLeafletDraw(lD)
                }
                else if (type === SOCKET_IO_MESSAGES.PICSTORK.TRAINING_ORTHO_AREA_OR_REGION_CHANGED) {
                    const { areas, regions, selectedRegion, selectedArea } = annotation;
                    if (areas) {
                        lD.drawAreas(areas, true)
                        setLeafletDraw(lD)
                    }
                    if (regions) {
                        lD.drawRegions(regions, true)
                        setLeafletDraw(lD)
                    }
                    if (selectedRegion?.id) {
                        lD.regions = lD.regions.map(r => {
                            if (r.id === selectedRegion.id) {
                                r.leafletLayer.setLatLngs(selectedRegion.coordinates)
                                return { ...r, ...selectedRegion, editable: false, }
                            }
                            else return r;
                        })
                        setLeafletDraw(lD)
                    }
                    if (selectedArea?.id) {
                        switch (selectedArea.subType) {
                            case TESTINGTOOL:
                                lD.testingArr = lD.testingArr.map(r => {
                                    if (r.id === selectedArea.id) {
                                        r.leafletLayer.setLatLngs(selectedArea.coordinates)
                                        return { ...r, ...selectedArea, editable: false, }
                                    }
                                    else return r;
                                })
                                break;
                            case TRAININGTOOL:
                                lD.trainingArr = lD.trainingArr.map(r => {
                                    if (r.id === selectedArea.id) {
                                        r.leafletLayer.setLatLngs(selectedArea.coordinates)
                                        return { ...r, ...selectedArea, editable: false, }
                                    }
                                    else return r;
                                })
                                break;
                            case ACCURACYTOOL:
                                lD.accuracyArr = lD.accuracyArr.map(r => {
                                    if (r.id === selectedArea.id) {
                                        r.leafletLayer.setLatLngs(selectedArea.coordinates)
                                        return { ...r, ...selectedArea, editable: false, }
                                    }
                                    else return r;
                                })
                                break;
                        }
                        setLeafletDraw(lD)

                    }
                    else if (annotation.deleteRegion) {
                        const removeRegionIndex = lD.regions.findIndex(a => annotation.deleteRegion.id === a.id)
                        let deletedRegion;
                        if (removeRegionIndex >= 0) {
                            deletedRegion = lD.regions[removeRegionIndex]
                            lD.annotationLayers.removeLayer(deletedRegion.leafletLayer)
                            lD.regions.splice(removeRegionIndex, 1)
                        }
                    }
                    else if (annotation.deleteArea) {
                        let removeAreaIndex;
                        let deletedRegion;
                        if (annotation.deleteArea.subType === TRAININGTOOL) {
                            removeAreaIndex = lD.trainingArr.findIndex(a => annotation.deleteArea.id === a.id)
                            //    lD.trainingArr=lD.trainingArr.filter(area=>area.id!==annotation.deleteArea.id)
                            if (removeAreaIndex >= 0) {
                                deletedRegion = lD.trainingArr[removeAreaIndex]
                                lD.trainingArr.splice(removeAreaIndex, 1)
                            }
                        } else if (annotation.deleteArea.subType === TESTINGTOOL) {
                            removeAreaIndex = lD.testingArr.findIndex(a => annotation.deleteArea.id === a.id)
                            // lD.testingArr=lD.testingArr.filter(area=>area.id!==annotation.deleteArea.id)
                            if (removeAreaIndex >= 0) {
                                deletedRegion = lD.testingArr[removeAreaIndex]
                                lD.testingArr.splice(removeAreaIndex, 1)
                            }
                        } else if (annotation.deleteArea.subType === ACCURACYTOOL) {
                            removeAreaIndex = lD.accuracyArr.findIndex(a => annotation.deleteArea.id === a.id)
                            //    lD.accuracyArr=lD.accuracyArr.filter(area=>area.id!==annotation.deleteArea.id)
                            if (removeAreaIndex >= 0) {
                                deletedRegion = lD.accuracyArr[removeAreaIndex]
                                lD.accuracyArr.splice(removeAreaIndex, 1)
                            }
                        }
                        if (deletedRegion) {
                            lD.areaLayers.removeLayer(deletedRegion.leafletLayer)
                        }
                    }
                    else if (annotation.clearAllAnnotations) {
                        lD.clearAllAnnotations()
                    }
                } else if (type === SOCKET_IO_MESSAGES.PICSTORK.ANNOTATION_COUNT_CHANGED) {
                    props.setAnnotationsCount(annotation.annotationCount)
                }
            })
        } catch (e) {
            console.error(e)
        }
    }
    useImperativeHandle(ref, () => ({
        isCurrImageValid() {
            return isCurrImageValid()
        },
        exportAnnotations(type) {
            if (leafletDraw) {
                if (props.showLoader) props.showLoader(true, "Exporting File ...", "WAIT");
                leafletDraw.exportAnnotations(type, (!leafletDraw) ? exportError : () => { if (props.showLoader) props.showLoader(false) })

            }
        },
        clearAllAnnotations() {
            if (leafletDraw) leafletDraw.clearAllAnnotations()
        },
        importAnnotations(type) {
            if (leafletDraw) importFiles(type)
        },
        setUpdateNotAllowed() {
            setUpdateNotAllowed()
        },
        calculateAllMeasurements() {
            calculateAllMeasurements()
        }
    }));

    const calculateAllMeasurements = async () => {
        const regionsWithoutMeasure = leafletDraw.regions.filter(r => checkIfCalculateAvailable(r)).map(r => ({ id: r.id, coordinates: r.coordinates.map(c => [c.lat, c.lng]) }));
        const result = await getPicstorkMeasureFunc(regionsWithoutMeasure)
        let newRegions = leafletDraw.regions;
        for (let i = 0; i < result.length; i++) {
            const region = result[i]
            const index = newRegions.findIndex(r => r.id == region.id);
            newRegions[index].area = region?.area;
            newRegions[index].volume = region?.volume;
            newRegions[index].elevation = region?.elevation;
        }
        leafletDraw.regions = [...newRegions]
        if (leafletDraw?.selectedRegion?.id) {
            const newSelectedRegion = newRegions.find(r => r.id == leafletDraw.selectedRegion.id)
            leafletDraw.selectedRegion = { ...newSelectedRegion }
        }
        let lD = { ...leafletDraw }
        props.enableCalculateMesBtnFunc(false)
        await editDetections(newRegions, leafletDraw.getLatLngsOfCircle)
        Popup.close()
        setLeafletDraw(lD)
    }
    const updateClasses = (classes) => {
        API.updateTaskDetails(props.task.task_id, { classes, state: (props.task.tasktype == 2 || props.task.tasktype == 4) ? 4 : 3 }).then((result) => {

            props.updateTask(result.task)

        })
        // }
    }

    const handleClearFile = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = null; // Reset the value to clear the selected file
        }
    };
    const editDetections = async (regions, getLatLngsOfCircle, oldClassName) => {
        try {
            let resRegions = []
            let regs = []
            for (let i = 0; i < regions.length; i++) {
                let region = regions[i]
                let poly = region.type !== CIRCLE ? region.coordinates.map(latLng => [latLng.lat, latLng.lng]) :
                    getLatLngsOfCircle(region.coordinates.latLng, region.coordinates.radius, 360).map(latLng => [latLng.lat, latLng.lng])
                let polyData = { id: region.id, className: region.className, type: region.type, poly: poly, point: region.point, elevation: region.elevation, volume: region.volume, temperature: region.temperature, area: region.area, data: { severity: region?.data?.severity, note: region?.data?.note, percentage: region?.data?.percentage }, }
                if (region?.name) {
                    polyData.name = region.name
                }
                resRegions.push(polyData)
                let reg = { ...region };
                regs.push(reg)
                reg.leafletLayer = undefined;
            }
            await API.updateDetectionsRegionsOfClass(props.task, { collection_id: props.imageInfo.collection_id, file_name: props.imageInfo.file_name, }, resRegions, oldClassName)
            pushAnnotationToSocketRoom({ regions: regs }, SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTIONS_EDITED, props.task.task_id)

        } catch (err) {

            Popup.alert("ERROR", err.message || err)
        }
    }
    const editDetection = async (region, getLatLngsOfCircle, oldClassName, updatedLeafletClass) => {
        try {
            let poly = region.type !== CIRCLE ? region.coordinates.map(latLng => [latLng.lat, latLng.lng]) :
                getLatLngsOfCircle(region.coordinates.latLng, region.coordinates.radius, 360).map(latLng => [latLng.lat, latLng.lng])
            let polyData = { id: region.id, className: region.className, type: region.type, poly: poly, point: region.point, elevation: region.elevation, volume: region.volume, area: region.area, temperature: region.temperature, data: { severity: region?.data?.severity, note: region?.data?.note, percentage: region?.data?.percentage }, }
            if (region?.name) {
                polyData.name = region.name
            }
            let reg = { ...region };
            reg.leafletLayer = undefined;
            const foundRegion = updatedLeafletClass.regions.find(r => r.id == region.id)
            if (foundRegion)
                foundRegion.temperature = undefined;
            await API.updateDetectionsRegion(props.task, { collection_id: props.imageInfo.collection_id, file_name: props.imageInfo.file_name, }, polyData, oldClassName)
            pushAnnotationToSocketRoom({ region: reg }, SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTION_EDITED, props.task.task_id)
            setLeafletDraw({ ...updatedLeafletClass })
        } catch (err) {
            Popup.alert("ERROR", err.message || err)
        }
    }
    const clearAllDetections = async () => {
        try {

            await API.clearAllDetections(props.task, { collection_id: props.imageInfo.collection_id, file_name: props.imageInfo.file_name })
            pushAnnotationToSocketRoom({}, SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTIONS_CLEARED, props.task.task_id)
        } catch (err) {
            Popup.alert("ERROR", err.message || err)
        }
    }
    const isCurrImageValid = () => {
        if (trainingArrWithChildren?.noOfAreas && (trainingArrWithChildren?.noOfChildren?.length || 0) > 3
            &&
            testingArrWithChildren?.noOfAreas && (testingArrWithChildren?.noOfChildren?.length || 0) > 3
            &&
            accuracyArrWithChildren?.noOfAreas && (accuracyArrWithChildren?.noOfChildren?.length || 0) > 3
            &&
            (
                ((trainingArrWithChildren?.noOfChildren?.length || 0) +
                    (testingArrWithChildren?.noOfChildren?.length || 0) +
                    (accuracyArrWithChildren?.noOfChildren?.length || 0)) >= 20
            )
        ) {
            return true
        }
        return false
    }
    const getAnnotationData = (leafletDraw, classes) => {
        API.getAnnotation(props.imageInfo.file_name, props.imageInfo.collection_id, props.annotationSetId).then((data) => {
            if (data.data) {
                props.setAnnotationsCount(data.data.annotationData.features.length)
                let regionsData = data.data.annotationData.features.map((obj) => {
                    if (!obj.properties.name) {
                        leafletDraw.dontShowRegionName = true;
                        setDontShowRegionName(true)
                    }
                    return ({
                        id: obj.properties.id,
                        className: obj.properties.className,
                        coordinates: obj.geometry.coordinates[0].map(latLng => {
                            return {
                                lat: latLng[0],
                                lng: latLng[1]
                            }
                        }),
                        name: obj.properties?.name,
                        type: obj.properties.type || obj.geometry.type,
                        color: classes.find((cls) => cls.name === obj.properties.className).color
                    })
                })
                leafletDraw.drawRegions(regionsData, true, true)




                if (data.data.annotationData.trainingAreas) setTrainingArrWithChildren({ noOfAreas: data.data.annotationData.trainingAreas, noOfChildren: data.data.annotationData.trainingAreaAnnotations });

                if (data.data.annotationData.testingAreas) setTestingArrWithChildren({ noOfAreas: data.data.annotationData.testingAreas, noOfChildren: data.data.annotationData.testingAreaAnnotations });

                if (data.data.annotationData.accuracyAreas) setAccuracyArrWithChildren({ noOfAreas: data.data.annotationData.accuracyAreas, noOfChildren: data.data.annotationData.accuracyAreaAnnotations });

                leafletDraw.drawAreas([...(data.data.annotationData.trainingAreas || []), ...(data.data.annotationData.testingAreas || []), ...(data.data.annotationData.accuracyAreas || [])], true)
            }
        })
    }

    const formatDetections = (task, report, classes, leafletDraw) => {
        return new Promise(async (resolve, reject) => {

            const formatCoordinates = (latLngs) => {
                return new Promise((resolve, reject) => {
                    const coordinates = latLngs.map(latLng => {
                        return {
                            lat: latLng[0],
                            lng: latLng[1]
                        }
                    })
                    resolve(coordinates)
                })
            }
            const detections = task.type.map((ob, i) => {

                if (report.result.detections[ob] && report.result.detections[ob].data) {
                    const objDetection = report.result.detections[ob].data.map(async dt => {
                        if (!dt.name) {
                            leafletDraw.dontShowRegionName = true;
                            setDontShowRegionName(true)
                        }
                        return ({
                            id: dt.id || uuid(),
                            className: ob,
                            coordinates: await formatCoordinates(dt.poly),
                            type: dt.type || POLYGON,
                            color: classes.find((cls) => cls.name === ob).color,
                            ...dt
                        })
                    })
                    return (objDetection)
                }
            }).filter(d => d).flat();
            resolve(await Promise.all(detections))
        })
    }
    const getDetectionData = (leafletDraw, classes) => {
        // get detection task result for orthomosaic
        let { task } = props
        API.getDetections(task.task_id, task.type).then(async (data) => {
            if (data.ret.length != 0) {
                let report = data.ret.find(r => r.image === props.imageInfo.file_name) // as there is only one element in the report i.e. orthomosaic.tif
                setDetectionResult(report)
                const detections = await formatDetections(task, report, classes, leafletDraw)
                props.setAnnotationsCount(detections.length)
                if (props.map && props.bounds) leafletDraw.drawRegions(detections, true);
            }
        })
    }

    const saveAnnotations = async (regions, getLatLngsOfCircle, updateClassListToo, updatedClass) => {
        return new Promise(async (resolve, reject) => {

            try {

                if (regions.length || updatedClass.trainingArr.length > 0 || updatedClass.testingArr.length > 0 || updatedClass.accuracyArr.length > 0) {
                    if (updateClassListToo) {
                        updateClasses(updateClassListToo)
                    }
                    let trainingArr = [];
                    let testingArr = [];
                    let accuracyArr = [];

                    if (updatedClass) {
                        for (let i = 0; i < updatedClass.trainingArr.length; i++) {
                            const poly1 = turf.polygon([[...updatedClass.trainingArr[i].coordinates.map(coords => [coords.lat, coords.lng]), [updatedClass.trainingArr[i].coordinates[0].lat, updatedClass.trainingArr[i].coordinates[0].lng]]]);
                            for (let j = 0; j < regions.length; j++) {
                                const poly2 = turf.polygon([[...regions[j].coordinates.map(coords => [coords.lat, coords.lng]), [regions[j].coordinates[0].lat, regions[j].coordinates[0].lng]]]);
                                if (turf.booleanContains(poly1, poly2)) {
                                    trainingArr.push({ ...regions[j], leafletLayer: undefined })
                                }
                            }
                        }


                        for (let i = 0; i < updatedClass.testingArr.length; i++) {
                            const poly1 = turf.polygon([[...updatedClass.testingArr[i].coordinates.map(coords => [coords.lat, coords.lng]), [updatedClass.testingArr[i].coordinates[0].lat, updatedClass.testingArr[i].coordinates[0].lng]]]);
                            for (let j = 0; j < regions.length; j++) {
                                const poly2 = turf.polygon([[...regions[j].coordinates.map(coords => [coords.lat, coords.lng]), [regions[j].coordinates[0].lat, regions[j].coordinates[0].lng]]]);
                                if (turf.booleanContains(poly1, poly2)) {
                                    testingArr.push({ ...regions[j], leafletLayer: undefined })
                                }
                            }
                        }
                        for (let i = 0; i < updatedClass.accuracyArr.length; i++) {
                            const poly1 = turf.polygon([[...updatedClass.accuracyArr[i].coordinates.map(coords => [coords.lat, coords.lng]), [updatedClass.accuracyArr[i].coordinates[0].lat, updatedClass.accuracyArr[i].coordinates[0].lng]]]);
                            for (let j = 0; j < regions.length; j++) {
                                const poly2 = turf.polygon([[...regions[j].coordinates.map(coords => [coords.lat, coords.lng]), [regions[j].coordinates[0].lat, regions[j].coordinates[0].lng]]]);
                                if (turf.booleanContains(poly1, poly2)) {
                                    accuracyArr.push({ ...regions[j], leafletLayer: undefined })
                                }
                            }
                        }

                        setTrainingArrWithChildren({ noOfAreas: updatedClass.trainingArr, noOfChildren: trainingArr });
                        setTestingArrWithChildren({ noOfAreas: updatedClass.testingArr, noOfChildren: testingArr });
                        setAccuracyArrWithChildren({ noOfAreas: updatedClass.accuracyArr, noOfChildren: accuracyArr });
                    }

                    if (!updatedClass.overlayOnly) {
                        const geoJSON = {
                            "type": "FeatureCollection",
                            "trainingAreaAnnotations": trainingArr,
                            "testingAreaAnnotations": testingArr,
                            "accuracyAreaAnnotations": accuracyArr,
                            "trainingAreas": updatedClass.trainingArr.map(arr => {
                                return { ...arr, leafletLayer: undefined, editable: false }
                            }),
                            "testingAreas": updatedClass.testingArr.map(arr => {
                                return { ...arr, leafletLayer: undefined, editable: false }
                            }),
                            "accuracyAreas": updatedClass.accuracyArr.map(arr => {
                                return { ...arr, leafletLayer: undefined, editable: false }
                            }),
                            "features": await Promise.all(regions.map(async (region, key) => {
                                return ({
                                    "type": "Feature",
                                    "properties": {
                                        "id": region.id,
                                        "className": region.className,
                                        "type": region.type,
                                        'name': region?.name || undefined,
                                    },
                                    "geometry": {
                                        "type": POLYGON,
                                        "coordinates": [
                                            region.type !== CIRCLE ?
                                                await Promise.all(region.coordinates.map(async latLng => [latLng.lat, latLng.lng])) :
                                                await Promise.all(getLatLngsOfCircle(region.coordinates.latLng, region.coordinates.radius, 360).map(async latLng => [latLng.lat, latLng.lng]))
                                        ]
                                    },

                                })
                            })),

                        }
                        API.saveAnnotation({
                            "fileName": props.imageInfo.file_name,
                            "annotation_set_id": props.annotationSetId,
                            "image_collection_id": props.imageInfo.collection_id,
                            image_data: { annotationData: geoJSON, imageName: props.imageInfo.file_name }
                        }).then(res => {
                            resolve()
                        }).catch((err) => reject(err))
                    }
                    resolve()
                }
                else {
                    if (!updatedClass.overlayOnly) {
                        API.clearAllAnnotations(props.imageInfo.collection_id, props.annotationSetId, props.imageInfo.file_name).then(
                            (data) => {
                                API.updateTaskDetails(props.task.task_id, { state: 3, classes: updateClassListToo }).then((result) => {
                                    props.updateTask(result.task)
                                }).then(res => {
                                    resolve()
                                }).catch(reject)
                            }
                        ).catch(err => {
                            console.log(err)
                            API.updateTaskDetails(props.task.task_id, { state: 3, classes: updateClassListToo }).then((result) => {
                                props.updateTask(result.task)
                            }).then(res => {
                                resolve()
                            }).catch(reject)
                        })
                    }
                    setTrainingArrWithChildren({ noOfAreas: updatedClass.trainingArr, noOfChildren: [] });
                    setTestingArrWithChildren({ noOfAreas: updatedClass.testingArr, noOfChildren: [] });
                    setAccuracyArrWithChildren({ noOfAreas: updatedClass.accuracyArr, noOfChildren: [] });
                    resolve()
                }
                resolve()
            }
            catch (err) {
                console.log("err", err)
                Popup.alert("ERROR", err)
                reject()
            }
        })
    }
    const deleteDetection = async (region) => {
        return new Promise(async (resolve, reject) => {

            try {
                await API.deleteDetectionsRegion(props.task, { collection_id: props.imageInfo.collection_id, file_name: props.imageInfo.file_name }, region.className, region.id,)
                resolve()
                pushAnnotationToSocketRoom({ id: region.id, }, SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTIONS_DELETED, props.task.task_id)
            } catch (err) {
                reject()
                Popup.alert("ERROR", err.message || err)
            }
        })
    }
    const saveDetections = async (regions, getLatLngsOfCircle) => {

        return new Promise(async (resolve, reject) => {
            try {
                let { task } = props
                let resultRegions = []
                let regs = []
                for (let i = 0; i < regions.length; i++) {
                    let region = regions[i];
                    let poly = region.type !== CIRCLE ? region.coordinates.map(latLng => [latLng.lat, latLng.lng]) :
                        getLatLngsOfCircle(region.coordinates.latLng, region.coordinates.radius, 360).map(latLng => [latLng.lat, latLng.lng])
                    let polyData = {
                        id: region.id,
                        className: region.className,
                        type: region.type,
                        poly: poly,
                        point: region.point,
                        name: region.name,
                        bounding_box: region.bounding_box,
                        data: {
                            severity: region?.data?.severity,
                            note: region?.data?.note,
                            percentage: region?.data?.percentage
                        },
                        // temperature:region.temperature,
                        area: region.area,
                        volume: region.volume,
                        elevation: region.elevation,
                        temperature: region.temperature,
                    }
                    resultRegions.push(polyData)
                    let reg = { ...polyData, color: regions[i].color, poly: undefined, point: undefined, data: undefined }
                    reg.coordinates = regions[i].coordinates
                    regs.push(reg)
                }
                await API.addDetectionsRegions(task, { collection_id: props.imageInfo.collection_id, file_name: props.imageInfo.file_name }, resultRegions,).then(
                    (res) => {
                        setDetectionResult(res.result)
                        pushAnnotationToSocketRoom({ regions: regs }, SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTIONS_ADDED, props.task.task_id)
                        resolve()
                    },
                    (e) => {
                        console.log(e);
                    }
                );
            } catch (err) {
                console.log(err)
                reject()
                Popup.alert("ERROR", err?.message || err)
            }
        })

    }

    const onAddNewClass = (className) => {
        if (className) {
            setHighlightClassInList(className)
            leafletDraw.addNewClass(className)
        }
    }

    const onClickClass = (cls) => {

        setHighlightClassInList(cls.name)
        leafletDraw.highlightArea()
        if (activeAnnotationTool === POINTER) {
            if (dontShowRegionName) {
                leafletDraw.highlightClasses(cls.name)
                leafletDraw.selectRegion()
            }
        };
        leafletDraw.setActiveDrawingClass(cls)
        if (leafletDraw.activeDrawingRegion) {
            if (leafletDraw.activeDrawingRegion instanceof L.Marker) {
                const newIcon = L.divIcon({
                    html: `<div class='mapMarker' style='border-radius:50%; position:absolute; top:-3px; left:-3px; width:15px; height:15px; background: ${cls.color};'></div>`,
                    iconSize: [32, 32],
                    iconAnchor: [16, 32],
                })
                // @ts-ignore
                leafletDraw.activeDrawingRegion.setIcon(newIcon);
            } else {

                leafletDraw.activeDrawingRegion.setStyle({ color: cls.color })
            }
        }

    }
    const onClickAreaName = (areaId, areaSubType) => {
        leafletDraw.highlightArea(areaId)
        leafletDraw.highlightClasses()
    }
    const setOutsideOrthoRegionE = () => {
        setActivityPopup({
            processPopupIcon: "ERROR",
            title: "Annotation outside of orthomosaic.",
            processMessage: "You cannot draw annotation outside of orthomosaic bounds."
        })
    }
    const setOrthoLineIntersect = () => {
        setActivityPopup({
            processPopupIcon: "ERROR",
            title: "Polygon lines intersect itself",
            processMessage: "Polygon lines should not be intersect itself."
        })
    }
    const setOverlapArea = () => {
        setActivityPopup({
            processPopupIcon: "ERROR",
            title: "Overlapping not allowed",
            processMessage: "You cannot draw area over one another."
        })
    }

    const importRejectedError = (importedCount, rejectedCount) => {
        setActivityPopup({
            processPopupIcon: "ERROR",
            title: "Import failed.",
            processMessage: importedCount ?
                `${rejectedCount} annotations out of ${importedCount + rejectedCount} import failed, may be they are outside of orthomosaic bounds / invalid format.` :
                "All annotations import failed, may be they are outside of orthomosaic bounds / invalid format."
        })
    }

    const exportError = (error) => {
        setActivityPopup({
            processPopupIcon: "ERROR",
            title: "Export failed.",
            processMessage: error || "Something went wrong while exporting annotations."
        })
    }
    const setUpdateNotAllowed = () => {
        setActivityPopup({
            processPopupIcon: "ERROR",
            title: "Modification restricted",
            processMessage: !props.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].EDIT ? "You are not allowed." : props.task.sharedBy && props.task.sharedBy.isShared ? `Cannot modify existing annotations as your task is shared by ${props.task.sharedBy.sharedBy.first_name}` : `Cannot modify existing annotations as your task is ${props.task.status == AppConstants.TASK.STATUS.COMPLETED ? 'completed' : 'in process'}.`
        })
    }
    const setClassesError = () => {
        setActivityPopup({
            processPopupIcon: "ERROR",
            title: "Add classes",
            processMessage: "Please add at least one class to annotate."
        })
    }
    const onEditClassNameInClassList = (oldClassName, newClassName) => {
        const existClass = leafletDraw.classes.find(cls => cls.name === newClassName)
        if (existClass) onClickClass(existClass)
        else leafletDraw.onEditClassNameInClassList(oldClassName, newClassName);
    }
    const onDeleteClass = (className, confirmDelete) => {
        if (confirmDelete)
            leafletDraw.onDeleteClass(className)
        else {
            setActivityPopup({
                processPopupIcon: "WARNING",
                processMessage: `All the annotations will be deleted, are you sure you want to delete this class ${getLimitedName(className)} ?`,
                className
            })
        }
    }

    const checkIsLatLngs = ({ type, coordinates }) => {
        if (type == POLYGON) {
            return coordinates[0]
                && coordinates[0][0]
                && (coordinates[0][0][0].toString().split(".")[0].length <= 3
                    || coordinates[0][0][1].toString().split(".")[0].length <= 3)
        } else if (type == POLYLINE) {
            return (coordinates[0][0][0].toString().split(".")[0].length <= 3
                || coordinates[0][0][1].toString().split(".")[0].length <= 3)
        } else if (type == MARKER) {
            return (coordinates[0][0][0].toString().split(".")[0].length <= 3
                || coordinates[0][0][1].toString().split(".")[0].length <= 3)
        }
    }
    const getFormattedRegionCoordinates = ({ coordinates }, utmData) => {
        return coordinates[0].map(latLng => {
            let { lat, lng } = utmData ? utm.convertUtmToLatLng(latLng[0], latLng[1], utmData.ZoneNumber, utmData.ZoneLetter) : { lat: latLng[1], lng: latLng[0] }
            return { lat, lng }
        })
    }
    const importGeoJson = async (event) => {
        let shpaeFileInvalid = false
        const data = event.target ? JSON.parse(event.target.result) : event
        let GeoJSON = Array.isArray(data) ? await getSingleGeoJSON(data) : data
        if (GeoJSON.features.find(feature => !(feature.geometry.type == POLYGON || feature.geometry.type == POLYLINE || feature.geometry.type == MARKER))) {
            shpaeFileInvalid = true
        }
        GeoJSON = { ...GeoJSON, features: GeoJSON.features.filter(feature => feature.geometry.type === POLYGON || feature.geometry.type === POLYLINE || feature.geometry.type == MARKER) }
        if (GeoJSON.features && GeoJSON.features[0]) {
            if (props.showLoader) props.showLoader(true, "Importing File ...", "WAIT");
            const newClasses = []
            const importedRegions = []
            const existingRegions = leafletDraw.regions
            const rejectedFeatures = []
            let map = new Map()
            const formatRegion = async (obj, utmData) => {
                return new Promise(async (resolve, reject) => {
                    const className = obj.properties && (obj.properties.className || obj.properties.classname || obj.properties.class) ? (obj.properties.className || obj.properties.classname || obj.properties.class) : DEFAULTCLASSNAME
                    const existingClass = leafletDraw.classes.find(cls => className === cls.name)
                    const existingNewClass = newClasses.find(cls => className === cls.name)
                    const color = existingClass ? existingClass.color : existingNewClass ? existingNewClass.color : leafletDraw.randomColor()
                    if (!existingClass && !existingNewClass) newClasses.push({ name: className, color })
                    const coordinates = getFormattedRegionCoordinates(obj.geometry, utmData)
                    if (obj.properties?.name) {

                    } else {

                        map.set(className, (map.get(className) || 0) + 1)
                    }
                    const existingLength = existingRegions.filter(r => r.className == className).length
                    if (await leafletDraw.isRegionInsideImageBounds(coordinates, false)) resolve({
                        id: uuid(),
                        className,
                        coordinates,
                        name: obj.properties?.name || `${className}_${existingLength + map.get(className)}`,
                        type: obj.properties.type || obj.geometry.type || POLYGON,

                        color,
                        data: {
                            note: obj.properties.desc || "",
                            ...obj.properties
                        },
                        elevation: null,
                        volume: null,
                        area: obj.properties.area || null,
                    })
                    else {
                        if (props.showLoader) props.showLoader(false);
                        reject(obj)
                    }
                })
            }

            //check if is latlngs by checking number length before decimal
            const isLatLngs = checkIsLatLngs(GeoJSON.features[0].geometry)

            const { ZoneLetter, ZoneNumber } = isLatLngs ? {} : utm.convertLatLngToUtm(props.bounds.drawingBounds[0][0], props.bounds.drawingBounds[0][1], 2);
            for (let key in GeoJSON.features) {
                const feature = GeoJSON.features[key]

                const isPolygonValid = feature.geometry &&
                    feature.geometry.coordinates &&
                    feature.geometry.coordinates[0] &&
                    feature.geometry.coordinates[0][0] &&
                    feature.geometry.coordinates[0].length >= 3

                const isPolylineValid = feature.geometry &&
                    feature.geometry.coordinates &&
                    feature.geometry.coordinates[0] &&
                    feature.geometry.coordinates[0][0] &&
                    feature.geometry.coordinates[0].length == 2

                if (feature.geometry.type == POLYGON ? isPolygonValid
                    : feature.geometry.type == POLYLINE ? isPolylineValid
                        : feature.geometry.type == MARKER ? true
                            : false) {
                    try {
                        importedRegions.push(await formatRegion(feature, isLatLngs ? undefined : { ZoneLetter, ZoneNumber },))
                    } catch {

                        rejectedFeatures.push(feature)
                    }
                } else { rejectedFeatures.push(feature) }
            }

            let leafletRegions = []

            for (let i = 0; i < leafletDraw.classes.length; i++) {
                const regs = leafletDraw.regions.filter(r => r.className == leafletDraw.classes[i].name)
                let count = importedRegions.filter(r => r.className == leafletDraw.classes[i].name).length + 1;
                for (let j = 0; j < regs.length; j++) {
                    let r = regs[j];
                    r.name = `${r.className}_${count++}`
                    leafletRegions.push(r)

                }
            }
            const newRegions = [...importedRegions, ...leafletRegions]

            if (importedRegions.length) leafletDraw.drawRegions(newRegions, false, true)

            if (newClasses.length) {
                leafletDraw.addNewClasses(newClasses.filter(cls => importedRegions.some(region => region.className === cls.name)))
                leafletDraw.setActiveDrawingClass(newClasses[0])
            }
            if (rejectedFeatures.length > 0) {
                importRejectedError(importedRegions.length, rejectedFeatures.length)
            }
            if (props.showLoader) props.showLoader(false);
            if (shpaeFileInvalid) {
                if (props.showLoader) props.showLoader(true, "Invalid Shapefile. Please ensure the file contains only polygons, boxes, or circles.", "ERROR");
            }
        }
        else {
            if (props.showLoader) props.showLoader(true, "Invalid File Format ...", "ERROR");
        }
    }

    const importFiles = (accept = importType) => {
        if (isTaskHasEditAccess()) {
            const fileImporter = document.getElementById("fileImporter")
            setImportedType(accept)
            fileImporter.setAttribute('accept', accept);
            fileImporter.click()
        } else {
            setUpdateNotAllowed()
        }
    }

    const onSelectFiles = async (e) => {
        if (props.showLoader) props.showLoader(true, "Importing File ...", "WAIT");
        if (e.target.files[0] && (e.target.files[0].type === importType || e.target.files[0].type === FILETYPES.WINDOWSZIP) && FILETYPES.ZIP === importType) {
            await importShapeFile(e.target.files[0]).then(geoJSON => {
                importGeoJson(geoJSON)
            }).catch((error) => {
                console.log("error", error);
                importRejectedError();
                if (props.showLoader) props.showLoader(true, "Invalid File ...", "ERROR");
            })
        }
        else if (e.target.files[0] && e.target.files[0].type === importType && FILETYPES.JSON === importType) {
            const reader = new FileReader();
            reader.onload = importGeoJson;
            reader.readAsText(e.target.files[0]);
            e.target.value = "";
        }
        else {
            if (props.showLoader) props.showLoader(true, "Invalid File Format ...", "ERROR");
        }
        handleClearFile()
    }

    const onRegionDataUpdate = () => {
        const { task } = props
        if (mentionUser) {
            let props = {
                pathname: '/picstork/annotationReportTask',
                state: {
                    task: { task_id: task.task_id },
                    collection: { id: task.image_collection_id },
                    project: { project_name: task.project_name, id: task.project_id },
                    plan: { plan_name: task.plan_name, id: task.plan_id, project_name: task.project_name },
                }
            }
            if (mentionUser) API.addNotification(mentionUser, leafletDraw.selectedRegion.data.note, props, 1)
        }
        leafletDraw.saveSevirity(leafletDraw.selectedRegion)
        leafletDraw.setSelectedRegionEditable()
        // , () => {
        //     this.setRegionEditable()
        //     this.saveFile()
        //   });
    }
    return <>
        <input
            id='fileImporter'
            style={{ display: 'none' }}
            type="file"
            ref={fileInputRef}
            onChange={onSelectFiles}
        />
        {activityPopup ? <ActivityPopup
            item={activityPopup.itemName}
            open={activityPopup}
            icon={activityPopup.processPopupIcon}
            action={activityPopup.processAction}
            title={activityPopup.title}
            msg={activityPopup.processMessage}
            close={() => setActivityPopup(undefined)}
            onClickOk={() => {
                onDeleteClass(activityPopup.className, true)
                setActivityPopup(undefined)
            }}


        /> : <></>}
        {leafletDraw ? <div style={{ width: '100%', height: '100%' }}>
            <div id="copySelectedAnnotation" className="" onClick={() => leafletDraw.enableSelectedLayerCopy(!(leafletDraw.selectedRegion && leafletDraw.selectedRegion.copying))} />
            <div id="editSelectedAnnotation" className="" onClick={() => leafletDraw.setSelectedRegionEditable(!(leafletDraw.selectedRegion && leafletDraw.selectedRegion.editable))} />
            <div id="deleteSelectedAnnotation" className="" onClick={() => leafletDraw.deleteSelectedRegion()} />
            <div id="editSelectedAnnotationArea" className="" onClick={() => leafletDraw.setSelectedAreaEditable(!(leafletDraw.selectedArea && leafletDraw.selectedArea.editable))} />
            <div id="deleteSelectedAnnotationArea" className="" onClick={() => leafletDraw.deleteSelectedArea()} />
            <div id="draw-tool" style={{
                fontSize: "9px",
                height: 'fit-content',
                // width: '35px',
                zIndex: '1',
                borderRadius: '7px',
                // background: 'rgba(0,0,0,0.8)',
                backgroundColor: "black",
                padding: '5px 10px',
                // margin: props.task.tasktype == 2 ? "40px 0px 0px -40px" : "5px 0px 0px -40px",
                margin: (props.task.tasktype == 2 || props.task.tasktype == 4) ? "40px 0px 0px -50px" : "5px 0px 0px -50px",
                position: 'absolute'
            }}>
                {/* Pointer */}
                <SingleToolView
                    onClick={() => {
                        onClickTool(POINTER)
                    }}
                    tittle={POINTER}
                    icon={activeAnnotationTool === POINTER ? pointerActive : pointerInactive}
                />
                {/* Box */}
                <SingleToolView
                    width={'18px'}
                    onClick={() => {
                        if (leafletDraw && leafletDraw.classes.length === 0) {
                            setClassesError()
                        } else if (isTaskHasEditAccess()) {
                            onClickTool(BOX)
                        } else {
                            setUpdateNotAllowed()
                        }

                    }}
                    disabled={leafletDraw && (leafletDraw.classes.length === 0 || !isTaskHasEditAccess())}
                    tittle={BOX}
                    icon={activeAnnotationTool === BOX ? boxActive : boxInactive}
                />

                {/* Polygon */}

                <SingleToolView
                    onClick={() => {
                        if (leafletDraw && leafletDraw.classes.length === 0) {
                            setClassesError()
                        } else if (isTaskHasEditAccess()) {
                            onClickTool(POLYGON)
                        } else {
                            setUpdateNotAllowed()
                        }

                    }}
                    // disabled={true}
                    disabled={leafletDraw && (leafletDraw.classes.length === 0 || !isTaskHasEditAccess())}
                    tittle={POLYGON}
                    icon={activeAnnotationTool === POLYGON ? polygonActive : polygonInactive}
                />

                <SingleToolView
                    onClick={() => {
                        if (leafletDraw && leafletDraw.classes.length === 0) {
                            setClassesError()
                        } else if (isTaskHasEditAccess()) {
                            onClickTool(CIRCLE)
                        } else {
                            setUpdateNotAllowed()
                        }

                    }}
                    // disabled={true}
                    disabled={leafletDraw && (leafletDraw.classes.length === 0 || !isTaskHasEditAccess())}
                    tittle={CIRCLE}
                    icon={activeAnnotationTool === CIRCLE ? CircleActive : CircleInactive}
                />

                {/* POLYLINE */}
                <SingleToolView
                    onClick={() => {
                        if (leafletDraw && leafletDraw.classes.length === 0) {
                            setClassesError()
                        } else if (isTaskHasEditAccess()) {
                            onClickTool(POLYLINE)
                        } else {
                            setUpdateNotAllowed()
                        }

                    }}
                    // disabled={true}
                    disabled={leafletDraw && (leafletDraw.classes.length === 0 || !isTaskHasEditAccess())}
                    tittle={POLYLINE}
                    icon={activeAnnotationTool === POLYLINE ? lineActive : lineInactive}
                />

                {/* MARKER */}
                <SingleToolView
                    onClick={() => {
                        if (leafletDraw && leafletDraw.classes.length === 0) {
                            setClassesError()
                        } else if (isTaskHasEditAccess()) {
                            onClickTool(MARKER)
                        } else {
                            setUpdateNotAllowed()
                        }

                    }}
                    // disabled={true}
                    disabled={leafletDraw && (leafletDraw.classes.length === 0 || !isTaskHasEditAccess())}
                    tittle={'Point'}
                    icon={activeAnnotationTool === MARKER ? markerActive : markerInactive}
                />

                {props.task && props.task.tasktype == 1 && <>
                    {/*training area */}
                    <SingleToolView
                        onClick={() => {
                            if (leafletDraw && leafletDraw.classes.length === 0) {
                                setClassesError()
                            } else if (isTaskHasEditAccess()) {
                                onClickTool(TRAININGTOOL)
                            } else {
                                setUpdateNotAllowed()
                            }

                        }}
                        // disabled={true}
                        disabled={leafletDraw && (leafletDraw.classes.length === 0 || !isTaskHasEditAccess())}
                        tittle={"Training Tool"}
                        icon={activeAnnotationTool === TRAININGTOOL ? trainingToolActive : trainingToolInactive}
                    />
                    {/* testing area */}
                    <SingleToolView
                        onClick={() => {
                            if (leafletDraw && leafletDraw.classes.length === 0) {
                                setClassesError()
                            } else if (isTaskHasEditAccess()) {
                                onClickTool(TESTINGTOOL)
                            } else {
                                setUpdateNotAllowed()
                            }

                        }}
                        // disabled={true}
                        disabled={leafletDraw && (leafletDraw.classes.length === 0 || !isTaskHasEditAccess())}
                        tittle={"Testing Tool"}
                        icon={activeAnnotationTool === TESTINGTOOL ? testingToolActive : testingToolInactive}
                    />
                    {/* acurracy area */}
                    <SingleToolView
                        onClick={() => {
                            if (leafletDraw && leafletDraw.classes.length === 0) {
                                setClassesError()
                            } else if (isTaskHasEditAccess()) {
                                onClickTool(ACCURACYTOOL)
                            } else {
                                setUpdateNotAllowed()
                            }

                        }}
                        // disabled={true}
                        disabled={leafletDraw && (leafletDraw.classes.length === 0 || !isTaskHasEditAccess())}
                        tittle={"Accuracy Tool"}
                        icon={activeAnnotationTool === ACCURACYTOOL ? accuracyToolActive : accuracyToolInactive}
                    />
                </>}


                {/* Recenter */}
                <SingleToolView
                    onClick={props.setMapCenter}
                    tittle={"Recenter"}
                    icon={locateWhiteIcon}
                />
            </div>
            <div style={{ width: "100%", height: '100%', zIndex: 3, background: AppConstants.CLASSLABELS.DARKCLASSBG, right: "0px", position: 'relative' }}>
                <ClassListWithColor
                    task={props.task}
                    calculateMeasurements={calculateMeasurements}
                    calculateTemperature={calculateTemperature}
                    dontShowRegionName={dontShowRegionName}
                    disabledUpdate={!isTaskHasEditAccess()}
                    onEditClassColor={leafletDraw.onEditClassColor}
                    onClassUpdate={leafletDraw.updateSelectedRegionClass}
                    onEditClass={(props.task.tasktype == 2 || props.task.tasktype == 4 || ((props.task.tasktype == 1 && props.isMultipleOrtho) ? true : false)) ? undefined : onEditClassNameInClassList}
                    onDeleteClass={(props.task.tasktype == 2 || props.task.tasktype == 4) ? undefined : onDeleteClass}
                    classListWithColor={leafletDraw.classes}
                    trainingArrWithChildren={trainingArrWithChildren}
                    testingArrWithChildren={testingArrWithChildren}
                    accuracyArrWithChildren={accuracyArrWithChildren}
                    addNewObject={onAddNewClass}
                    onClickClass={onClickClass}
                    setError={setUpdateNotAllowed}
                    selectedAreaId={selectedAreaId}
                    setSelectedAreaId={setSelectedAreaId}
                    areaVisible={props.task && props.task.tasktype == 1}
                    regions={leafletDraw.regions}
                    activeClass={highlightClassInList}
                    selectedRegion={leafletDraw.selectedRegion}
                    permissions={props.permissions}
                    onClickAreaName={onClickAreaName}
                    taskIsInCreating={() => isTaskHasEditAccess()}
                    taskType={props.task.tasktype}
                    focusOnRegion={focusOnRegion}
                    updateData={(key, value) => {
                        if (key === 'userName') {
                            setMentionUser(value)
                        } else if (leafletDraw.selectedRegion) {
                            let region = leafletDraw.selectedRegion
                            if (!region.data)
                                region = { ...leafletDraw.selectedRegion, data: { datatype: "", id: leafletDraw.selectedRegion.id, note: undefined, percentage: "100", severity: undefined } }
                            region.data[key] = value
                            setLeafletDraw(prev => ({ ...prev, selectedRegion: region }))
                        }
                    }}
                    onRegionDataUpdate={onRegionDataUpdate}
                    task_purpose={props.task.task_purpose}
                />
            </div>
        </div> : <></>}
    </>
})