import React from "react";
import { AnimatedModal } from "./AnimatedModal";
import closeBtn from '../assets/toolbarIcons/closeButton.png'
import * as API from '../api'
import shareInviteMailIcon from '../assets/shareInviteMailIcon.png'
import validator from 'validator'
import { ActivityPopup } from "./ActivityPopup";
import LoaderComponent from "../ReusableComponents/LoaderComponent";
import { checkURLExist } from "./reusableFunctions";
import { BLOB_URL } from "../AppConstants";
import { getActiveTeam } from "../Teams";



class ShareAssetsPopup extends React.Component {
    constructor() {
        super();
        this.state = {
            shareInviteModal: false,
            inviteModal: false,
            inviteMail: [],
            successEmails: [],
            existEmails: [],
            errorEmails: [],
            emailValue: '',
            accessType: false,
            validationDisplay: "none",
            validationColor: "red",
            validationMessage: "",
            alreadysharedwith: [],
            accessRemoved: [],
            loadingSharedList: true,
        }
    }
    componentDidMount() {
        getActiveTeam().then(activeTeam => {
            this.setState({
                activeTeam: activeTeam
            }, () => {
                this.getInviteUsersList()
            })
        })
    }
    getInviteUsersList = () => {
        API.getInviteUsersList(this.props.task.image_collection_id, this.props.task.task_id).then((res) => {
            this.setState((state) => ({
                ...state,
                loadingSharedList: false,
                alreadysharedwith: res.data || [],
            }))
        }, (e) => {
            console.log(e);
            console.log('Something went wrong while fetching invited users list.');
        })
    }

    deleteSharedData = () => {
        this.setState((state) => ({
            ...state,
            itemName: "",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: "Removing access please wait",
            showProcessPopup: true,
            inviteModal: false,
            inviteMail: [],
            successEmails: [],
            existEmails: [],
            errorEmails: [],
            emailValue: '',
            validationDisplay: "none",
            validationColor: "red",
            validationMessage: ""
        }, () => {
            this.props.setShareAssetsModal(false)
        }))
        const alreadysharedwith = this.state.alreadysharedwith.filter(s => s.shared_to !== this.state.deleteShareEmail)
        API.deleteSharedData(this.state.deleteshareSId).then((res) => {
            if (res.status == 200) {
                res.json().then((data) => {
                    this.setState((state) => ({
                        ...state,
                        itemName: "",
                        processPopupIcon: "COMPLETE",
                        processAction: "DELETE",
                        processMessage: data.message,
                        alreadysharedwith,
                        showProcessPopup: true,
                        deleteShareEmail: '',
                        deleteshareSId: '',
                        accessRemoved: [],
                    }))
                })
            } else {
                res.json().then((data) => {
                    this.setState((state) => ({
                        ...state,
                        itemName: "",
                        processPopupIcon: "ERROR",
                        processAction: "DELETE",
                        processMessage: data.message,
                        showProcessPopup: true,
                        deleteShareEmail: '',
                        deleteshareSId: '',
                        accessRemoved: [],
                    }))
                })

            }
        }, (e) => {
            this.setState((state) => ({
                ...state,
                itemName: "",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
                deleteShareEmail: '',
                deleteshareSId: '',
                accessRemoved: [],
            }))
        })
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null
        }))
    }

    sendInvite = async() => {
        if (this.props.linkedMap || await checkURLExist(`${BLOB_URL}/${this.state.activeTeam.isOwner ? this.props.user.blob_container : this.state.activeTeam.teamOwner.container}/FlightImages/collection-${this.props.task.image_collection_id}/reports/${this.props.task.tasktype === 2 && this.props.task.task_purpose === 'Counting' ? "Detection" : "Defect"}-${this.props.task.task_id}.pdf?${this.state.activeTeam.isOwner ? this.props.user.sas_token : this.state.activeTeam.teamOwner.st}`) || await checkURLExist(`${BLOB_URL}/${this.state.activeTeam.isOwner ? this.props.user.blob_container : this.state.activeTeam.teamOwner.container}/FlightImages/collection-${this.props.task.image_collection_id}/reports/Thermal-${this.props.task.task_id}.pdf?${this.state.activeTeam.isOwner ? this.props.user.sas_token : this.state.activeTeam.teamOwner.st}`)) {
            let access_level = this.props.defaultAccessLevel || this.state.accessType == true ? 'READ' : 'DOWNLOAD'
            this.state.inviteMail.forEach((email, key) => {
                API.inviteUserToShareAssets(email, this.props.task.image_collection_id, access_level, this.props.task.project_id, this.props.task.plan_id, this.props.task.collection_name, this.props.task.task_id).then((response) => {
                    if (response.status === 201) {
                        response.json().then((data) => {
                            this.setState((prevState) => ({
                                ...prevState,
                                validationDisplay: "block",
                                validationMessage: data.message,
                                validationColor: "green",
                                alreadysharedwith: [...this.state.alreadysharedwith, data.shared_data]
                            }))
                        })
                        this.state.successEmails.push(email)
                    } else if (response.status === 202) {
                        response.json().then((data) => {
                            this.setState((prevState) => ({
                                ...prevState,
                                validationDisplay: "block",
                                validationMessage: data.message,
                                validationColor: "red"
                            }))
                        })
                        this.state.existEmails.push(email)
                    }
                })
                    .catch((error) => {
                        this.state.errorEmails.push(email)
                        this.setState((prevState) => ({
                            ...prevState,
                            inviteErrorMsg: error.Error,
                            validationDisplay: "block",
                            validationMessage: error.Error,
                            validationColor: "red"
                        }))
                    })
            })
        } else {
            this.setState((prevState) => ({
                ...prevState,
                inviteErrorMsg: "Kindly generete report first using download option.",
                validationDisplay: "block",
                validationMessage: "Kindly generete report first using download option.",
                validationColor: "red"
            }))
        }

    };

    onChangeAccess = (e, key, sid) => {
        let access = e.target.value
        this.state.alreadysharedwith[key].access_level.title = access
    }

    handleInputChange = (event) => {
        let emailValue = event.target.value
        if (emailValue === this.props.user.username) {
            this.setState((prevState) => ({
                ...prevState,
                validationDisplay: "block",
                validationColor: "red",
                validationMessage: "You can not send invitation to yourself."
            }))
        } else {
            if (emailValue.includes(' ')) {
                this.setState((prevState) => ({
                    ...prevState,
                    validationDisplay: "block",
                    validationColor: "red",
                    validationMessage: "space not allowed"
                }))
            } else {
                this.setState((state) => ({
                    ...state,
                    emailValue: emailValue,
                }), () => {
                    if (this.state.emailValue.includes(',')) {
                        this.setState((prevState) => ({
                            ...prevState,
                            inviteMail: [],
                        }), () => {
                            let emails = this.state.emailValue.split(',')
                            emails.map((email, key) => {
                                if (validator.isEmail(email)) {
                                    this.state.inviteMail.push(email)
                                    this.setState((prevState) => ({
                                        ...prevState,
                                        validationDisplay: "block",
                                        validationColor: "red",
                                        validationMessage: ""
                                    }))
                                } else {
                                    this.setState((prevState) => ({
                                        ...prevState,
                                        validationDisplay: "block",
                                        validationColor: "red",
                                        validationMessage: "please enter valid Email address"
                                    }))
                                }
                            })
                        })

                    } else {
                        if (validator.isEmail(this.state.emailValue)) {
                            this.state.inviteMail[0] = this.state.emailValue
                            this.setState((prevState) => ({
                                ...prevState,
                                validationDisplay: "block",
                                validationColor: "red",
                                validationMessage: ""
                            }))
                        } else {
                            this.setState((prevState) => ({
                                ...prevState,
                                validationDisplay: "block",
                                validationColor: "red",
                                validationMessage: "please enter valid Email address"
                            }))
                        }
                    }
                })
            }
        }
    }



    render() {
        return <>

            {/* -------------- Activity Popup ----------------------- */}
            <ActivityPopup
                item={this.state.itemName}
                open={this.state.showProcessPopup}
                icon={this.state.processPopupIcon}
                action={this.state.processAction}
                msg={this.state.processMessage}
                close={() => this.closeActivityPopup()}
                onClickOk={() => this.deleteSharedData()}
            />

            <AnimatedModal
                isOpen={this.props.shareInviteModal}
                height="450px"
                width="700px"
            >
                <div style={{ display: "flex", height: "100%" }}>
                    <div style={{ width: "35%", backgroundColor: "#EAEAEA", display: "flex", flexDirection: "column", height: "100%", justifyContent: "center" }}>
                        <img src={shareInviteMailIcon} style={{ margin: "8%" }} />
                    </div>
                    <img style={{ position: "absolute", top: '7px', right: "7px", width: '20px', height: '20px', cursor: "pointer" }} src={closeBtn} onClick={() => {
                        this.props.setShareAssetsModal(false)
                        this.setState((state) => ({
                            ...state,
                            inviteModal: false,
                            inviteMail: [],
                            successEmails: [],
                            existEmails: [],
                            errorEmails: [],
                            emailValue: '',
                            accessType: false,
                            validationDisplay: "none",
                            validationColor: "red",
                            validationMessage: ""
                        }))
                    }} />
                    <div style={{ width: "65%", height: "100%" }}>
                        <div
                            style={{
                                textAlign: "left",
                                fontSize: "18px",
                                fontWeight: "500",
                                color: "#3C3C3C",
                                margin: '20px 50px 5px 35px'
                            }}
                        >
                            Share Task
                        </div>
                        {/* <form> */}
                        <div
                            style={{ textAlign: "center", margin: "0px 50px 0px 35px" }}
                        >

                            <div
                                style={{
                                    textAlign: "left",
                                    margin: "0px 0px 2px 2px",
                                    fontSize: "12px",
                                    color: "#3C3C3C",
                                }}
                            >
                                Invite users to view task.
                            </div>
                            <label
                                style={{
                                    display: "flex",
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    lineHeight: "22px",
                                    alignItems: "center",
                                    textTransform: "capitalize",
                                    color: "@fontColorSecondary !important",
                                    marginBottom: "4px",
                                }}
                            >

                                <input
                                    // type='email' multiple
                                    // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                    style={{ width: '300px', fontSize: '11px', lineHeight: '13px' }}
                                    onChange={(event) => this.handleInputChange(event)}
                                    value={this.state.emailValue}
                                    name="username"
                                    className="add-user-form-text"
                                    placeholder="Enter list of emails separated by comma."
                                />
                            </label>
                            <div style={{ textAlign: "left", color: this.state.validationColor, fontSize: "12px", margin: "2px", height: "18px" }}>{this.state.validationMessage}</div>
                        </div>
                        {this.props.defaultAccessLevel ? <></> : <>
                            <div style={{ textAlign: "center", margin: "0px 50px 10px 35px", display: 'flex', fontSize: '12px' }}>
                                <div style={{ marginRight: '10px', width: "22%", display: "flex", justifyContent: "space-between", alignItems: 'center', marginLeft: "2px" }}><input type='checkbox' name="access_type" value={'READ'} checked={this.state.accessType == true} onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        accessType: !this.state.accessType
                                    }))
                                }} /> Read Only</div>
                            </div>
                        </>}
                        <div className="teamsInvite" style={{ display: "flex", justifyContent: "space-between", padding: "0 50px 0px 35px", width: "65%", }}>
                            <div style={{ textAlign: "center" }}>
                                <button
                                    className="defaultButtonSuccess"
                                    onClick={() => {
                                        this.sendInvite()
                                    }}
                                >Share</button>
                            </div>
                        </div>
                        {/* </form> */}
                        <hr style={{ margin: '15px 50px 15px 35px' }}></hr>

                        <div
                            style={{
                                textAlign: "left",
                                margin: "0 0 10px 0",
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#3C3C3C",
                                margin: '20px 50px 10px 35px'
                            }}
                        >
                            Already Shared
                        </div>
                        <div style={{ padding: this.state.alreadysharedwith.length === 0 ? undefined : '0px 45px 0px 35px', display: this.state.alreadysharedwith.length === 0 ? "flex" : undefined, justifyContent: this.state.alreadysharedwith.length === 0 ? 'center' : undefined, alignItems: this.state.alreadysharedwith.length === 0 ? "center" : undefined, height: '145px', overflow: this.state.loadingSharedList ? undefined : 'auto', width: '99%' }}>

                            {
                                this.state.loadingSharedList ? <LoaderComponent /> : this.state.alreadysharedwith.length === 0 ? <p style={{ fontSize: '14px' }}>Nothing is shared yet.</p> :
                                    this.state.alreadysharedwith.map((shared, key) =>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                                            <div style={{ width: '90%', overflow: 'hidden', textAlign: 'left' }}>
                                                <div style={{ lineHeight: '18px', fontSize: '14px', }}>{
                                                    shared.shared_to
                                                    // .length > 30 ?
                                                    //   `${shared.shared_to.substring(0, 30)}...` :
                                                    //   `${shared.shared_to}`
                                                }</div>
                                                <div style={{ display: 'flex', }}>
                                                    <div style={{ fontSize: '10px', display: "flex", alignItems: "center", color: '#666666' }}>{shared.access_level.title === 'READ' ? 'View Access' : 'Download Access'}</div>
                                                    {shared.status === 'Invited' ? <div style={{ marginLeft: '10px', fontSize: '12px', color: '#2989cf' }}>(Pending)</div> : <></>}
                                                </div>
                                            </div>
                                            <div style={{ width: '10%', textAlign: 'right', }}>
                                                <img style={{ width: '16px', height: '16px', cursor: "pointer" }} src={closeBtn} onClick={() => {
                                                    this.setState((state) => ({
                                                        ...state,
                                                        accessType: false,
                                                        deleteShareEmail: shared.shared_to,
                                                        deleteshareSId: shared.sid
                                                    }), () => {
                                                        this.setState((state) => ({
                                                            ...state,
                                                            itemName: "Share Access",
                                                            processPopupIcon: "WARNING",
                                                            processAction: "DELETE",
                                                            processMessage: 'Are you sure you want to remove this Share Access ?',
                                                            showProcessPopup: true,
                                                        }))
                                                    })
                                                }} />
                                            </div>
                                        </div>
                                    )
                            }
                        </div>

                    </div>

                </div>
            </AnimatedModal></>
    }

}

export default ShareAssetsPopup;