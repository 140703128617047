import React, { useEffect, useState } from 'react'
import * as API from "../api.js";
import { AnimatedModal } from '../ReusableComponents/AnimatedModal.js';
import lockIcon from './assets/lock.svg'
import eyeIcon from './assets/eye.svg'
import eyeOffIcon from './assets/eye-off.svg'
import mobileIcon from '../assets/Icons/mobileIcon.png'
import LoaderComponent from '../ReusableComponents/LoaderComponent.js';
let timerInterval = null


const ChangeMobileNumber = ({ phone, onMobileNumberChanged, user, onClose, sendOTPbyDefault }) => {
    const [otpTimer, setOtpTimer] = useState({
        otpTimer: '',
        timeOutFlag: false
    })

    const [sendingOTP, setSendingOTP] = useState(sendOTPbyDefault)

    const [otpData, setOtpData] = useState({
        otp: '',
        errorOtp: '',
        showOtp: '',
        verifyOtpLoader: false,
        phone: phone
    })

    useEffect(() => {
        if (sendOTPbyDefault && !(otpData.otpSent)) {
            changePhoneNumberReqOTP()
        }
    }, [])


    const updateTimer = (min, sec) => {
        setOtpTimer({
            otpTimer: min < 1 ? `Resend OTP in ${min} : ${sec} sec` : `Resend OTP in ${min} : ${sec} min`,
            timeOutFlag: false
        })
    }

    const resetTimer = () => {
        if (timerInterval) {
            clearInterval(timerInterval);
            setOtpTimer({
                otpTimer: "",
                timeOutFlag: true
            })
        }

    }

    const timer = async () => {
        API.getOtpTimer(user.username).then(async (data) => {
            // let timerData = await data.json();
            if (data.status == 200) {
                let timerData = await data.json();
                let minutes = Number(timerData.remainTime.split(':')[0])
                let seconds = Number(timerData.remainTime.split(':')[1])
                timerInterval = setInterval(() => {
                    let min = `0${minutes}`
                    let sec = seconds < 10 ? `0${seconds}` : seconds
                    updateTimer(min, sec)
                    seconds--;
                    if (seconds < 0) {
                        minutes--;
                        seconds = 59;
                        if (minutes < 0) {
                            resetTimer()
                        }
                    }
                }, 1000);
            } else {
                window.location.replace('/login');
            }
            return
        })
    }

    const changePhoneNumberReqOTP = (e) => {
        setSendingOTP(true)
        if (e) e.preventDefault();
        if (otpData.phone === '' || otpData.phone?.length !== 10 || otpData.phone === "1111111111") {
            setOtpData({
                ...otpData,
                errorOtp: 'Please enter valid mobile number.'
            })
        } else {
            API.changePhoneNumberReqOTP(otpData.phone).then(async (data) => {
                if (data.status == 200) {
                    setSendingOTP(false)
                    await timer()
                    setOtpData({ ...otpData, otpSent: true })
                } else {
                    setSendingOTP(false)
                    setOtpData({ ...otpData, errorOtp: (await data.json()).message || 'Something went wrong while sending OTP' })
                }
            }, (e) => {
                console.log(e);
            })
        }

    }

    const updateNumberWithOTP = () => {
        if (otpData.otp == '') {
            setOtpData({
                ...otpData,
                errorOtp: 'Please enter OTP'
            })
        } else {
            setOtpData({
                ...otpData,
                verifyOtpLoader: true,
            })
            API.updateNumberWithOTP(otpData.phone, otpData.otp).then((verifydata) => {
                if (verifydata.status == 200) {
                    onMobileNumberChanged(otpData.phone)
                    setOtpData({
                        ...otpData,
                        verifyOtpLoader: false,
                    })
                } else if (verifydata.status == 401) {
                    setOtpData({
                        ...otpData,
                        verifyOtpLoader: false,
                        errorOtp: 'OTP expired'
                    })
                } else if (verifydata.status == 400) {
                    setOtpData({
                        ...otpData,
                        verifyOtpLoader: false,
                        errorOtp: 'Invalid OTP'
                    })
                } else {
                    setOtpData({
                        ...otpData,
                        verifyOtpLoader: false,
                        errorOtp: 'Something went wrong while sending OTP'
                    })
                }
            }, (e) => {
                console.log(e);
                setOtpData({
                    ...otpData,
                    verifyOtpLoader: false,
                    errorOtp: 'Something went wrong while sending OTP'
                })
            })
        }
    }


    return <AnimatedModal
        isOpen={true}
        onRequestClose={onClose}
        height="350px"
        width="400px"
    >
        <div style={{ width: '100%', textAlign: 'center', fontSize: '16px', fontWeight: '500', marginTop: '25px' }}>Verify Mobile Number</div>
        <hr style={{ width: '96%', color: 'grey', marginTop: '10px' }} />
        {sendingOTP && sendOTPbyDefault ?
            <LoaderComponent
                message={"Sending OTP..."}
                height={"50%"}

            />
            : <>
                <div style={{ width: '100%', textAlign: 'center', lineHeight: '20px', marginTop: '10px', fontSize: '14px', fontWeight: '400' }}>
                    {!(otpData.otpSent && !(otpTimer.timeOutFlag)) ? "Please Enter your mobile Number." : 'OTP has been sent to mobile number.'}
                    {/* <br /><span style={{ color: 'red' }}>Please do not refresh page</span> */}
                </div>
                <div class="login-error" id="otpErrorMsg" style={{ height: '30px', fontSize: '14px', color: 'red', marginTop: '5px' }}>{otpData.errorOtp}</div>
                <div style={{ textAlign: 'center' }}>
                    {!(otpData.otpSent && !(otpTimer.timeOutFlag)) ?
                        <>
                            <img style={{ position: 'absolute', marginTop: '21px', marginLeft: '25px', width: "23px" }} src={mobileIcon} />
                            <input
                                value={otpData.phone}
                                autoFocus
                                onKeyDown={(e) => { if (e.key === 'Enter') changePhoneNumberReqOTP(); }}
                                class="text-input login-text-input" id="otp" style={{ width: '18rem' }} name="Mobile Number"
                                type={"number"}
                                onChange={(e) => {
                                    setOtpData({
                                        ...otpData,
                                        errorOtp: '',
                                        phone: e.target.value
                                    })
                                }}
                                placeholder="Mobile Number" maxlength={10} minLength={10} required />
                        </>
                        : <>
                            <img style={{ position: 'absolute', marginTop: '21px', marginLeft: '25px' }} src={lockIcon} />
                            <input
                                autoFocus
                                value={otpData.otp}
                                onKeyDown={(e) => { if (e.key === 'Enter') updateNumberWithOTP(); }}
                                class="text-input login-text-input" id="otp" style={{ width: '18rem' }} name="otp"
                                type={otpData.showOtp ? "text" : "password"} onChange={(e) => {
                                    setOtpData({
                                        ...otpData,
                                        errorOtp: '',
                                        otp: e.target.value
                                    })
                                }}
                                placeholder="OTP" maxlength="6" required />
                            <img className="login-password-wrapper-img" id="view-password" style={{ cursor: 'pointer', top: '155px', left: '310px' }}
                                src={otpData.showOtp ? eyeOffIcon : eyeIcon} onClick={() => {
                                    setOtpData({
                                        ...otpData,
                                        showOtp: !otpData.showOtp
                                    })
                                }} />
                        </>
                    }
                </div>
                <div style={{ height: '25px', fontSize: '14px', marginTop: '10px' }}>
                    {otpTimer.otpTimer}
                </div>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    {!(otpData.otpSent && !(otpTimer.timeOutFlag)) ? <button type="button" class="loginButton" name="otpButton" id="otpButton" onClick={changePhoneNumberReqOTP}>Send OTP</button> :
                        <button type="button" class="loginButton" name="resendButton" id="resendButton" onClick={() => {
                            updateNumberWithOTP()
                        }}>Verify</button>}
                </div>
            </>}
    </AnimatedModal>
}

export default ChangeMobileNumber;