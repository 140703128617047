import React, { useEffect, useState } from 'react'
import ShareAssetsPopup from '../../ReusableComponents/ShareAssetsPopup';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup';
import AppConstants from '../../AppConstants';
import * as API from '../../api'
import { BOTH, ELEVATION, VOLUME } from './ReusableComp/TaskProcessView';
import { PICSTORK_FEATURES } from '../../Teams';
import { getLimitedName } from '../../ReusableComponents/reusableFunctions';
import dateFormat from 'dateformat';
const DetectionTaskDetails = (props) => {
    const [accColor, setAccColor] = useState("grey");
    const [detector, setDetector] = useState(null)
    const [noOfDetection, setNoOfDetection] = useState(0);
    const [taskStartPopup, setTaskStartPopup] = useState(props.openStartTaskPopup)
    const taskStatusBGColor = props.task.status == AppConstants.TASK.STATUS.COMPLETED ? '#009900' : props.task.status == AppConstants.TASK.STATUS.PENDING ? '#2989CF1A' : props.task.status == AppConstants.TASK.STATUS.FAILED ? '#F02020' : '#ffcc00'
    const taskStatusColor = props.task.status == AppConstants.TASK.STATUS.COMPLETED ? 'white' : props.task.status == AppConstants.TASK.STATUS.PENDING ? '#2989cf' : props.task.status == AppConstants.TASK.STATUS.FAILED ? 'white' : 'white'
    const getDetectorDetails = () => {
        API.getDetectorDetails(props.task.task_id).then((result) => {
            if (result.detector)
                setDetector(result.detector)
        })
    }

    const [shareTask, setShareTask] = useState(false);
    const [legendDetails, setLegendDetails] = useState({ trainingAreaColor: "", testingAreaColor: "", accuracyAreaColor: "", totalAnnotations: 0, classLabels: [], detectorAccuracy: 0 })
    useEffect(() => {
        if (props.task.tasktype == 1)
            getDetectorDetails()
    }, [taskStartPopup])

    useEffect(() => {
        API.getDetections(props.task.task_id, []).then(
            (data) => {
                props.taskImageType(data.image_data[0].file_name)
                let totalDetections = 0;
                data.ret.forEach((item) => {
                    for (const key in item.result.detections) {
                        if (item.result.detections.hasOwnProperty(key)) {
                            totalDetections += item.result.detections[key].count;
                        }
                    }
                })
                setNoOfDetection(totalDetections)
            }
        )
    }, [])
    useEffect(() => {
        const acc = parseInt(("" + legendDetails?.detectorAccuracy).split("%")[0])
        if (isNaN(acc)) setAccColor("grey")
        if (acc <= 25) setAccColor("red")
        else if (acc <= 50) setAccColor("orange")
        else if (acc <= 100) setAccColor("green")
        else return
    }, [legendDetails.detectorAccuracy])
    const getMeasurementType = (mes) => {
        let ans = ""
        switch (mes) {
            case VOLUME:
                ans += "Volume,"
                break;
            case ELEVATION:
                ans += "Elevation,"
                break;
            case BOTH:
                ans += "Volume, Elevation,"
                break;
        }
        return ans + " Area"
    }
    return <>
        {/* -------------- Activity Popup ----------------------- */}
        <ActivityPopup
            open={taskStartPopup}
            icon={'COMPLETED'}
            title={"Started task"}
            height={190}
            msg={'Your task is pending and added to the execution queue. It will be processed as soon as resource will be available.'}
            close={() => {
                setTaskStartPopup(false)
                props.closeStartTaskPopup()
            }}
        />
        {/* -----------------------------Buttons---------------------------------- */}
        <div style={{ display: 'flex', margin: "1%", width: '98%', height: '32px', alignItems: 'center', justifyContent: 'space-between' }}>
            <div className="services-content-title" style={{ display: "flex", width: '20%' }}>
                Task Details
            </div>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                <div style={{ display: 'flex', alignItems: "baseline" }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {(props.task.sharedBy && props.task.sharedBy.isShared) || props.task.video_task ?
                            <></> :
                            <ButtonWithIcon
                                text="Share Task"
                                isDisabled={!(props.permissions?.[PICSTORK_FEATURES.TASKS].SHARE && props.task.status === AppConstants.TASK.STATUS.COMPLETED)}
                                onClick={() => props.task.status != AppConstants.TASK.STATUS.COMPLETED ? false : setShareTask(props.task)}
                            />
                        }
                        {!props.task.video_task && <ButtonWithIcon
                            text="Summary Report"
                            isDisabled={!(props.permissions?.[PICSTORK_FEATURES.REPORTS].READ && props.task.status === AppConstants.TASK.STATUS.COMPLETED)}
                            onClick={() => { if (props.task.status == AppConstants.TASK.STATUS.COMPLETED) props.changeView(1) }}
                        />}

                        <ButtonWithIcon
                            text="Detail Report"
                            isDisabled={!(props.permissions?.[PICSTORK_FEATURES.REPORTS].READ && props.task.status === AppConstants.TASK.STATUS.COMPLETED)}
                            onClick={() => {
                                if (props.task.status == AppConstants.TASK.STATUS.COMPLETED)
                                    props.changeView(2)
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>


        {/* Details */}
        <div style={{ display: "flex", alignItems: "center", height: props.task.linked_map ? "93%" : "90%", width: props.task.linked_map ? "" : "98%", background: "#fff", boxShadow: "0px 0px 10px rgba(0,0,0,0.25)" }}>
            <div style={{ display: "flex", flexDirection: "column", width: "100%", padding: '30px 50px', height: "100%", }}>

                {/* Normal details */}
                <div style={{ width: "700px", display: 'grid', gridTemplateColumns: "repeat(2,1fr)", rowGap: "30px" }}>


                    {shareTask ? <ShareAssetsPopup
                        shareInviteModal={shareTask ? true : false}
                        user={props.user}
                        defaultAccessLevel={'READ'}
                        task={shareTask}
                        linkedMap={props.task.linked_map}
                        setShareAssetsModal={setShareTask} /> : <></>}



                    {/* Task name */}
                    <div style={{ width: "400px", display: "flex", flexDirection: "column" }}>
                        <div className='task-key' >Task Name</div>

                        <div className='task-value'>{getLimitedName(props.task.task_name, 30)}
                            {(props.task.sharedBy && props.task.sharedBy.isShared) ?
                                <span style={{ color: '#2989cf', fontSize: '10px' }}>
                                    ( sharedBy, {props.task.sharedBy.sharedBy.first_name + " " + props.task.sharedBy.sharedBy.last_name})
                                </span>
                                : <></>}
                        </div>
                    </div>

                    {/* Created date */}
                    <div style={{ display: "flex", flexDirection: "column", width: "400px", textAlign: "left" }}>
                        <div className='task-key'>Created Date</div>
                        <div className='task-value'>{dateFormat(props.task.date_created, "dd mmm yyyy")}</div>
                    </div>

                    {/* Imageset */}
                    <div style={{ width: "400px", display: "flex", flexDirection: "column" }}>
                        <div className='task-key'>Imageset</div>
                        <div className='task-value long-text-fix' >{(props.task.collection_name.split("_$")[0])}</div>
                    </div>

                    {/* No. of Images */}
                    <div style={{ display: "flex", flexDirection: "column", width: "400px", textAlign: "left" }}>
                        <div className='task-key'>No. of Images</div>
                        <div className='task-value'>{props.task.images}</div>
                    </div>

                    {/* Task purpose */}
                    <div style={{ width: "400px", display: "flex", flexDirection: "column" }}>
                        <div className='task-key' >{'Detection type'}</div>
                        <div className='task-value'>{props.task.task_purpose}</div>
                    </div>

                    {/* Status At */}
                    {
                        (props.task.status == AppConstants.TASK.STATUS.COMPLETED
                            || props.task.status == AppConstants.TASK.STATUS.FAILED) &&
                        <div style={{ display: "flex", flexDirection: "column", width: "400px", textAlign: "left" }}>
                            <div className='task-key'>{`${props.task.status} at`}</div>
                            <div className='task-value'>{dateFormat(props.task.status_update_timestamp, 'dd mmm yyyy')}</div>
                        </div>
                    }

                    {/* Measurement type */}
                    {
                        props.task.tasktype == 2 && !props.task.video_task && <div style={{ display: "flex", flexDirection: "column", width: "400px", }}>
                            <div className='task-key' >Measurement type</div>
                            <div className='task-value'>{
                                getMeasurementType(props.task?.task_parameters?.measurement_type)
                            }</div>
                        </div>
                    }

                    {/* Output type */}
                    {
                        props.task.tasktype == 2 && props.task?.task_parameters?.output_type && <div style={{ display: "flex", flexDirection: "column", width: "400px", }}>
                            <div className='task-key' >Output type</div>
                            <div className='task-value' style={{ textTransform: 'capitalize' }}>{
                                (props.task?.task_parameters?.output_type).toLowerCase()
                            }</div>
                        </div>
                    }


                    {/* Status */}
                    <div style={{ width: "400px", display: "flex", flexDirection: "column" }}>
                        <div className='task-key'>Status</div>
                        <div className="task-status" style={{ width: 'fit-content', color: taskStatusColor, background: taskStatusBGColor, fontWeight: 600, margin: "" }}>{props.task.status}</div>
                    </div>


                    {/* No of detections */}
                    {!props.task.video_task && <div style={{ display: "flex", flexDirection: "column", marginTop: "13px", width: "400px", textAlign: "left", }}>
                        <div style={{ fontSize: "11px", color: "#666666" }}>No of Detections</div>
                        <div style={{ fontSize: "13px" }}>{noOfDetection}</div>
                    </div>}
                </div>

                {/* Detectors/Class list */}
                <div style={{ display: "", width: "100%", height: "60%", justifyContent: "", marginTop: "30px" }}>
                    {props.task.tasktype == 4 ?
                        <div style={{ display: "flex", width: "400px", height: "100%" }}>
                            <div style={{ overflow: "", width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                                <div className='task-value'>Class labels</div>
                                <div style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                    fontSize: "13px",
                                    lineHeight: "20px",
                                    color: "#3c3c3c",
                                    padding: "0px 0px 4px 0px",
                                    overflowY: "scroll",
                                    height: "50%",
                                    minHeight: "100px",
                                    maxHeight: "200px"
                                }}>
                                    {props.task.type.map((obj, i) => {
                                        const color = legendDetails.classLabels.find(region => region.type === obj)?.color;
                                        return (
                                            <button
                                                className="aerocapture-detection-type-list"
                                            // style={{ fontSize: "12px", margin: "2px 4px", backgroundColor: color, color: "white" }}
                                            >
                                                {obj.length >= 20 ? obj.substring(0, 21) : obj}
                                            </button>
                                        );
                                    })}
                                </div>
                            </div>
                        </div> : <>{props.task.models ? <div>
                            <div style={{ marginTop: "1%" }} className='task-value'>Detectors</div>
                            <div style={{ display: "flex", width: '100%', justifyContent: 'left', overflow: 'auto' }}>
                                <div style={{ width: "98%", overflow: "auto", height: "26vh" }}>{
                                    props.task.models && props.task.models.map((model, m) => {
                                        return <>
                                            <div className="itemKey" style={{ padding: "2px 0px", fontSize: '12px', }}>{model.display_name}</div>
                                            <div className="rpas-model-info-primary-details-value1" style={{ marginTop: '-4px' }}>{
                                                // detectionType.toString()
                                                model.objects.map((type) => {
                                                    return <button className="aerocapture-detection-type-list" style={{ padding: "0px 8px" }}>{type}</button>
                                                })
                                            }</div>
                                            <div style={{ width: "98%", margin: "7px 5px 5px 5px", borderTop: "1px solid grey" }} />
                                        </>
                                    })
                                }</div>
                            </div>
                        </div> : <></>}
                        </>
                    }
                </div>
            </div>

        </div >
    </>
}

export default DetectionTaskDetails