import React, { Component, createRef } from 'react';
import Help from "../../sidebaricon/Help.png";
import Settings from "../../sidebaricon/Settings.png";
import dashboardActive from '../../icons/AeroCapture.svg';
import service from "../../sidebaricon/Services.png";
import AppConstants from '../../AppConstants';
import Images from './Images.js';
import DetailedReport from './TaskDetailedReport';
import SummaryReport from './TaskSummaryReport';
import ImageSlider from './TaskReportImageSlider';
import ScreenDivider from './ReusableComp/ScreenDivider';
import TaskDetails from './ReusableComp/TaskDetails';
import VerticalStepper from '../../ReusableComponents/Stepper/VerticalStepper';
import createTaskDone from '../assets/icons/stepperIcons/createTaskDone.png'
import createTaskActive from '../assets/icons/stepperIcons/createTaskActive.png'
import createTaskInactive from '../assets/icons/stepperIcons/createTaskInactive.png'
import uploadImagesDone from '../assets/icons/stepperIcons/uploadImagesDone.png'
import uploadImagesActive from '../assets/icons/stepperIcons/uploadImagesActive.png'
import uploadImagesInactive from '../assets/icons/stepperIcons/uploadImagesInactive.png'
import reportsDone from '../assets/icons/stepperIcons/reportsDone.png'
import reportsActive from '../assets/icons/stepperIcons/reportsActive.png'
import reportsInactive from '../assets/icons/stepperIcons/reportsInactive.png'
import processDone from '../assets/icons/stepperIcons/processDone.png'
import processActive from '../assets/icons/stepperIcons/processActive.png'
import processInactive from '../assets/icons/stepperIcons/processInactive.png'
import CreateTaskView from './ReusableComp/CreateTaskView';
import SelectImageSet from './ReusableComp/SelectImageSet';
import TaskProcessView from './ReusableComp/TaskProcessView';
import * as API from '../../api.js';
import dateFormat from 'dateformat';
import { Popup, getLimitedName, getLocalStorageValue, getPicstorkDefaultPlan, isTaskEditable, skipDetectionTaskTour } from '../../ReusableComponents/reusableFunctions';
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup';
import ReactJoyride, { STATUS } from 'react-joyride';
import { AnimatedModal } from '../../ReusableComponents/AnimatedModal';
import TourGraphics from '../../assets/Images/tourGraphics.png';
import OrthoDetectionReport from './OrthoDetectionReport';
import { ActiveRole, getActiveTeam, getFeaturesPermissions, PICSTORK_FEATURES } from '../../Teams';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';
import VideoDetectionResult from './VideoDetectionResult.js';

class DetectionTask extends Component {
    constructor() {
        super();
        this.detectionAreaToolRef = React.createRef(null)
        this.state = {
            classes: [],
            expand: true,
            currentStep: 1,
            joyrideTrigger: false,
            showProcessPopup: false,
            processPopupIcon: null,
            processMessage: null,
            annotated: 0,
            unannotated: 0,
            selectedReport: null,
            triggerCreateTaskTour: false,
            currentlyActiveTour: 0,
            runTour: false,
            showTourPopup: false,
            showTour: false,
            linked_map: null,
            openStartTaskPopup: false,
            analyticsCreditExceed: false,
            allStepComplete: false,
        };

    }
    isUserHasEditAccess = () => {
        return (this.state.permissions?.[PICSTORK_FEATURES.TASKS].EDIT)
    }


    componentDidUpdate = (prevProps, prevStates) => {
        if (
            this.state.task?.task_id
            &&
            this.props.location.state.task
            &&
            this.state.task.task_id !== this.props.location.state.task.task_id
        ) this.initData()

        if (prevProps.location.state.notificationId != this.props.location.state.notificationId) window.location.reload()
    }

    setStepData = async (onlySetSteps) => {
        let { task, collection } = this.state
        const isEditableTask = this.isUserHasEditAccess()
        task.state = task.state == 4 && task.status == AppConstants.TASK.STATUS.FAILED ? 3 : task.state
        let currentStep = onlySetSteps ? this.state.currentStep : task.state ? task.state : 1
        this.setState((state) => ({
            ...state,
            currentStep,
            steps: [
                {
                    stepName: 'Create task',
                    stepNumber: 1,
                    description1: `Task : ${task ? getLimitedName(task.task_name, 18) : "-"}`,
                    description2: `Created on ${task ? dateFormat(task.date_created, "dd mmm yyyy") : "-"}`,
                    completed: task.state > 1,
                    actionButton: "Create Task",
                    enable: task && task.task_id,
                    stepIcon: [createTaskActive, createTaskDone, createTaskInactive],
                    expand: true
                },
                {
                    stepName: 'Images',
                    stepNumber: 2,
                    description1: `Count : ${task ? task.images : '0'}`,
                    description2: `Created on ${collection && collection ? dateFormat(collection.created_on, 'dd mmm yyyy') : "-"}`,
                    completed: task.state > 2,
                    actionButton: "Setup",
                    enable: isEditableTask && currentStep == 2 && task.images > 0,
                    stepIcon: [uploadImagesActive, uploadImagesDone, uploadImagesInactive],
                    expand: true
                },
                {
                    stepName: 'Setup',
                    stepNumber: 3,
                    description1: `Status : ${task && (task.state > 3 || task.status == AppConstants.TASK.STATUS.FAILED) ? task.status : '-'}`,
                    description2: `Last updated on ${task && (task.state > 3 || task.status == AppConstants.TASK.STATUS.FAILED) ? dateFormat(task.status_update_timestamp, "dd mmm yyyy") : '-'}`,
                    completed: task.status != AppConstants.TASK.STATUS.FAILED && task.state > 3,
                    actionButton: task.status == AppConstants.TASK.STATUS.FAILED ? 'Restart' : "Start",
                    enable: this.state.permissions?.[PICSTORK_FEATURES.TASKS].CREATE && currentStep == 3 && task.models && task.models.length > 0 && task.task_purpose && task.task_parameters?.output_type,
                    stepIcon: [processActive, processDone, processInactive],
                    expand: true
                },
                {
                    stepName: 'Report',
                    stepNumber: 4,
                    description1: task.status == AppConstants.TASK.STATUS.COMPLETED ? AppConstants.TASK.STATUS.COMPLETED : task.status == AppConstants.TASK.STATUS.IN_PROGRESS ? AppConstants.TASK.STATUS.IN_PROGRESS : '-',
                    description2: '',
                    completed: task.status == AppConstants.TASK.STATUS.COMPLETED || task.state == 4,
                    actionButton: null,
                    enable: isEditableTask && task.status == AppConstants.TASK.STATUS.COMPLETED || task.state == 4,
                    stepIcon: [reportsActive, reportsDone, reportsInactive],
                    expand: true
                },
            ],
        }))
    }

    getTask = () => {
        API.getAeroCaptureTask(this.state.task.task_id).then(async (data) => {
            this.setState({ task: data, linked_map: data.linked_map, collection: data?.image_collection_id ? { id: data.image_collection_id, collection_name: data.collection_name, created_on: data.imageset_created_on } : undefined }, () => this.setStepData(false))
        }, (e) => {
            console.log(e);
        })
    }

    initData = () => {
        let { user, type, task, collection, project, plan } = this.props.location.state;
        this.setState({
            user,
            type,
            task: task || JSON.parse(localStorage.getItem('created_task')),
            project,
            plan,
            collection,
        }, async () => {
            try {
                const permissions = await getFeaturesPermissions([PICSTORK_FEATURES.TASKS, PICSTORK_FEATURES.IMAGECOLLECTIONS, PICSTORK_FEATURES.ANNOTATIONS, PICSTORK_FEATURES.REPORTS])
                const activeTeam = await getActiveTeam()
                this.setState({
                    permissions,
                    activeTeam
                }, async () => {

                    if (this.state.task && this.state.task.task_id) {
                        this.getTask()
                    }
                    else {
                        if (getLocalStorageValue('detectionTaskTour') == null) this.setState((state) => ({
                            ...state,
                            showTourPopup: true
                        }))
                        this.setState((state) => ({
                            ...state,
                            steps: [
                                {
                                    stepName: 'Create task',
                                    stepNumber: 1,
                                    description1: `Task : -`,
                                    description2: `Created on : -`,
                                    completed: false,
                                    enable: false,
                                    actionButton: "Create Task",
                                    stepIcon: [createTaskActive, createTaskDone, createTaskInactive],
                                    expand: true
                                },
                                {
                                    stepName: 'Images',
                                    stepNumber: 2,
                                    description1: `Count : 0`,
                                    description2: '',
                                    completed: false,
                                    enable: false,
                                    actionButton: "Setup",
                                    stepIcon: [uploadImagesActive, uploadImagesDone, uploadImagesInactive],
                                    expand: true
                                },
                                {
                                    stepName: 'Setup',
                                    stepNumber: 3,
                                    description1: `Status : -`,
                                    description2: `Last updated : -`,
                                    completed: false,
                                    enable: false,
                                    actionButton: "Start",
                                    stepIcon: [processActive, processDone, processInactive],
                                    expand: true
                                },
                                {
                                    stepName: 'Report',
                                    stepNumber: 4,
                                    description1: '-',
                                    description2: '-',
                                    completed: false,
                                    enable: false,
                                    actionButton: null,
                                    stepIcon: [reportsActive, reportsDone, reportsInactive],
                                    expand: true
                                },
                            ],
                        }))
                    }
                })
            }
            catch (e) {
                window.location.replace("/")
            }
        })
    }

    componentDidMount() {
        document.title = "Detection task";
        this.initData()
    }

    closeActivityPopup = () => {
        this.setState({ showProcessPopup: false })
    }

    createTask = async (step) => {
        let { task, project } = this.state
        const plan = await getPicstorkDefaultPlan(project)
        this.setState({ plan })
        if (task.task_name && task.task_name != "" && project.project_name && project.project_name != "") {
            API.createTask({
                ...task,
                project_name: project.project_name,
                project_id: project.id,
                plan_id: plan.id,
                plan_name: plan.plan_name,
                taskType: "detection",
                status: AppConstants.TASK.STATUS.CREATING,
                type: [],
                state: step + 1
            }).then((createdTask) => {
                localStorage.setItem('created_task', JSON.stringify(createdTask.task))
                this.setState({
                    task: createdTask.task,
                    currentStep: step + 1,
                    selectedImageForAnnotation: undefined,
                    steps: this.state.steps.map((s) => {
                        if (s.stepNumber == step) {
                            s.completed = true
                            s.description1 = `Task : ${task ? getLimitedName(task.task_name, 15) : "-"}`
                            s.description2 = `Created on ${this.state.task ? dateFormat(this.state.task.date_created, "dd mmm yyyy") : "-"}`
                        }
                        return s
                    })
                })
            }).catch((e) => {
                this.setState({
                    showProcessPopup: true,
                    processPopupIcon: 'ERROR',
                    processMessage: e.message,
                    steps: this.state.steps.map((s) => {
                        if (s.stepNumber == step) {
                            s.completed = false
                        }
                        return s
                    })
                })
            })

        } else {
            console.log("Project or plan is missing");
        }
    }

    updateTask = () => {
        let { task } = this.state
        if (this.isUserHasEditAccess() && isTaskEditable(task.status))
            API.updateTask(task.task_id, AppConstants.TASK.STATUS.PENDING, AppConstants.SERVICES.PICSTORK).then((result) => {
                task.status = AppConstants.TASK.STATUS.PENDING
                task.models = this.state.task.models
                this.setState({ task }, () => this.setStepData(false))
            }).catch((e) => {
                API.updateTaskDetails(task.task_id, { state: 3 })
                console.log(e)
                this.setState({
                    analyticsCreditExceed: e?.creditExceeds,
                    showProcessPopup: !e?.creditExceeds,
                    processPopupIcon: 'ERROR',
                    processMessage: e.message
                })
            })
    }

    handleJoyrideCallback = data => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED].includes(status)) {
            if (this.state.currentlyActiveTour == 1) localStorage.setItem('createTaskTour2', 2)
            else if (this.state.currentlyActiveTour == 2 && getLocalStorageValue('processTour2') == 1) localStorage.setItem('processTour2', 2)
            this.setState({
                runTour: false,
            })
        } else if ([STATUS.SKIPPED].includes(status)) {
            skipDetectionTaskTour()
            this.setState({
                runTour: false,
            })
        }
    };

    openCancelTaskPopup = () => {
        this.setState((state) => ({
            processPopupIcon: "WARNING",
            showProcessPopup: true,
            processMessage: 'Are you sure you want to cancel this task ?',
            itemName: 'Task'
        }))
    }

    cancelTask = async () => {
        API.updateTaskDetails(this.state.task.task_id, { state: 3, status: AppConstants.TASK.STATUS.CREATING }).then((updatedTask) => {
            this.setState({ task: { ...updatedTask.task }, showProcessPopup: false }, () => this.setStepData(false))
        }).catch((e) => {
            console.log("Error : ", e);
        })
    }

    updateTaskDetails = (state) => {
        let { task, collection } = this.state

        API.updateTaskDetails(task.task_id, { state, models: task.models, collection_id: collection ? collection.id : null, task_purpose: task.task_purpose, linked_map: this.state.linked_map, task_parameters: task.task_parameters, fps: task.fps }).then((updatedTask) => {
            this.setState({ task: updatedTask.task, }, () => {
                if (updatedTask.task.state == 4)
                    this.updateTask()
                else
                    this.setStepData(false)
            })
        }).catch((e) => {
            console.log("Error : ", e);
        })
    }

    startTask = () => {
        API.startAerocaptureTask(this.state.task.task_id).then((data) => {
            let task = this.state.task
            task.status = AppConstants.TASK.STATUS.PENDING
            this.setState({ task, openStartTaskPopup: true })
        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                ...state,
                itemName: "Task",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: null,
                showProcessPopup: true,
            }))
        })
    }
    step2 = async (step) => {
        let newTask = null;
        if (this.detectionAreaToolRef.current) {
            newTask = await this.detectionAreaToolRef.current.saveDetectionAreas()
        }

        this.setState({
            itemName: "Task",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: "Please wait while we are scanning images...",
            showProcessPopup: true,
            task: newTask ? newTask : this.state.task
        }, () => {
            if (this.state.task.image_collection_id) {
                this.setState({ showProcessPopup: false, }, () => {
                    this.setState({ openStartTaskPopup: step + 1 == 4 }, () => {
                        this.setState({
                            currentStep: step + 1,
                            selectedImageForAnnotation: undefined,
                            steps: this.state.steps.map((s) => {
                                if (s.stepNumber == step)
                                    s.completed = true
                                return s
                            })
                        })
                    })
                })
            } else {

                API.copyTaskCollection(this.state.task.task_id, this.state.collection.id).then(result => {
                    this.setState({ collection: result.collection, showProcessPopup: false, task: { ...this.state.task, image_collection_id: this.state.collection.id } }, () => {
                        this.updateTaskDetails(step + 1)
                        this.setState({ openStartTaskPopup: step + 1 == 4 }, () => {
                            this.setState({
                                currentStep: step + 1,
                                selectedImageForAnnotation: undefined,
                                steps: this.state.steps.map((s) => {
                                    if (s.stepNumber == step)
                                        s.completed = true
                                    return s
                                })
                            })
                        })
                    })
                }).catch(e => {
                    console.log(e)
                    this.setState((state) => ({
                        ...state,
                        itemName: "Task",
                        processPopupIcon: "ERROR",
                        processAction: "ADD",
                        processMessage: e.message,
                        showProcessPopup: true,
                    }))
                })
            }
        })

    }
    render() {
        let { user, type } = this.state
        let topBar = <div className="top-bar-text">Detection Task <ActiveRole /> </div>
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
                {
                    icon: dashboardActive,
                    text: "PicStork",
                    path: "/picstork/dashboard",
                    selected: true,
                },
                {
                    icon: Settings,
                    text: "Settings",
                    path: "/profile-settings",
                },
                {
                    icon: Help,
                    text: "Help",
                    path: "/feedback",
                },
            ],
            user,
            type
        }
        let leftSide = {
            render:
                <VerticalStepper
                    stepHeight={'110px'}
                    taskType={2}
                    expanded={this.state.expand}
                    steps={this.state.steps}
                    currentStep={this.state.currentStep}
                    onClickStep={(step) => {
                        this.setState({
                            currentStep: step,
                            selectedReport: null,
                            selectedImageForAnnotation: undefined,
                        }, () => this.setStepData(true))
                    }}
                    onClickExpandStep={(step) => {
                        this.setState({
                            steps: this.state.steps.map((s) => {
                                if (s.stepNumber == step)
                                    s.expand = !s.expand
                                return s
                            })
                        })
                    }}
                    setTourComplete={(t) => {
                        if (t === 1) this.setState({ triggerCreateTaskTour: true })
                        else this.setState({ triggerCreateTaskTour: false })
                    }}
                    joyrideTrigger={this.state.showTour ? true : false}
                    onClickActionButton={(step) => {
                        if (step == 1) {
                            this.setState({
                                steps: this.state.steps.map((s) => {
                                    if (s.stepNumber == step)
                                        s.completed = true;
                                    return s
                                })
                            }, () => {
                                this.createTask(step)
                            })
                        } else if (step === 2) {
                            this.step2(step)
                        } else if (step == 3) {
                            const { task } = this.state
                            if (!task?.task_parameters?.measurement_type) {
                                task.task_parameters.dsm_url = null;
                                task.task_parameters.dtm_url = null;
                            }
                            this.setState({ task }, () => {
                                this.updateTaskDetails(step + 1)
                                this.setState({ allStepComplete: true })
                            })
                        }
                        else {
                            this.updateTaskDetails(step + 1)
                            this.setState({ openStartTaskPopup: step + 1 == 4 }, () => {
                                this.setState({
                                    currentStep: step + 1,
                                    selectedImageForAnnotation: undefined,
                                    steps: this.state.steps.map((s) => {
                                        if (s.stepNumber == step)
                                            s.completed = true
                                        return s
                                    })
                                })
                            })
                        }
                    }}
                />
            ,
            style: {
                width: AppConstants.STEPPER.STEPPERWIDTH,
                padding: "5px 10px",
                background: AppConstants.COLORS.STEPPERCOLOR,
                height: '100%',
                overflow: 'auto'
            }
        }

        let rightSide = {
            render:
                <>
                    {/* -------------- Activity Popup ----------------------- */}
                    <ActivityPopup
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        msg={this.state.processMessage}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => { this.cancelTask() }}
                    />

                    <AnimatedModal
                        isOpen={this.state.analyticsCreditExceed}
                        height="150px"
                        width="460px">
                        <div style={{
                            position: "absolute",
                            padding: "30px",
                            height: "100%",
                            width: "100%",
                        }}>
                            <div style={{
                                borderBottom: "1px solid #dee2e6",
                                height: "calc(100% - 20px)",
                            }}>
                                <div style={{ fontSize: "15px", color: "#3c3c3c" }}>
                                    {this.state.processMessage}
                                </div>
                            </div>
                            <div style={{
                                position: "absolute",
                                right: "0px",
                                margin: "10px 30px 0 0",
                            }} >
                                <ButtonWithIcon
                                    isBtnActive
                                    text={"Buy"}
                                    width={"70px"}
                                    onClick={() => { }}
                                    linkData={{
                                        pathname: "/buy-credit",
                                        state: {
                                            user: user,
                                            creditType: AppConstants.SUBSCRIPTOPTIONS.ITEMS.ANALYTICS_CREDITS,
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </AnimatedModal>

                    {/* --------------------------------------------------------------- */}
                    {this.state.currentStep === 1 ?
                        <CreateTaskView
                            activeTeam={this.state.activeTeam}
                            permissions={this.state.permissions}
                            task={this.state.task}
                            taskType={2}
                            plan={this.state.plan || { id: this.state.task && this.state.task.plan_id }}
                            project={this.state.project || { id: this.state.task && this.state.task.project_id }}
                            user={this.state.user}
                            setTourComplete={(t) => {
                                if (t === 1) this.setState({ triggerCreateTaskTour: false })
                            }}
                            startTour={this.state.triggerCreateTaskTour}
                            taskCreationDone={(project, task) => {
                                this.setState({
                                    task,
                                    project,
                                    steps: this.state.steps.map((step => step.stepNumber === 1 ? { ...step, enable: task.task_name && project.id } : step)),
                                    tourSteps: [{
                                        disableBeacon: true,
                                        target: '#button-step1',
                                        content: (<div style={AppConstants.TEXTSTYLING}><p>Click here to create task</p></div>),
                                        title: (<div style={AppConstants.TITLESTYLING}>Create Task</div>),
                                        placement: 'right',
                                        styles: {
                                            options: {
                                                width: 250,
                                            },
                                        },
                                    }],
                                    currentlyActiveTour: 1,
                                }, () => {
                                    setTimeout(() => {
                                        this.setState({
                                            runTour: task.task_name && project.id && getLocalStorageValue('createTaskTour2') == 1 ? true : false
                                        })
                                    }, AppConstants.TRIGGER_DELAY)
                                })
                            }} /> :
                        //step two
                        this.state.currentStep === 2 ?
                            (this.state.collection && this.state.collection.id ?
                                <Images
                                    ref={this.detectionAreaToolRef}
                                    fromTrainingTask={this.props.location.state.fromTrainingTask}
                                    permissions={this.state.permissions}
                                    data={
                                        {
                                            collection: this.state.collection,
                                            plan: this.state.plan || { id: this.state.task.plan_id },
                                            project: this.state.project || { id: this.state.task.project_id },
                                            user: this.state.user,
                                            task: this.state.task
                                        }
                                    }
                                    updateImagesCount={(status, count, linked_map) => {
                                        if (this.state.task.state > 2 && count === 0) {
                                            this.updateTaskDetails(2)
                                        }
                                        let stepData = this.state.steps
                                        stepData[1].description1 = `Count : ${count}`
                                        stepData[1].enable = count != 0
                                        stepData[1].completed = this.state.task.state > 3
                                        this.setState({
                                            steps: stepData,
                                            linked_map
                                        })
                                    }}
                                    allStepComplete={this.state.allStepComplete}
                                    updateTask={(task) => {
                                        if (task) {
                                            this.setState({ task })
                                        }
                                    }}
                                    deleteImgPermission={this.state.steps[1].completed}
                                    taskType={2}
                                    setCollection={(collection) => {
                                        if (this.isUserHasEditAccess()) {
                                            let stepData = this.state.steps
                                            stepData[1].description1 = `Count : 0`
                                            stepData[1].enable = false
                                            this.setState({
                                                steps: stepData,
                                                linked_map: false,
                                                collection: collection
                                            })
                                        }
                                    }}
                                    updateTaskState={(state) => this.setState({ state })}
                                    goToNextStep={() => this.step2(2)}
                                /> :
                                <SelectImageSet
                                    permissions={this.state.permissions}
                                    setCollection={(collection) => {
                                        this.setState({ collection: collection })
                                    }}
                                    taskType={2}
                                    plan={this.state.plan || { id: this.state.task.plan_id }}
                                    data={{
                                        user: this.state.user,
                                        type: this.state.type,
                                        plan: this.state.plan || { id: this.state.task.plan_id },
                                        project: this.state.project || { id: this.state.task.project_id },
                                        task: this.state.task,
                                    }}
                                />
                            ) :

                            //step 3
                            this.state.currentStep === 3 ?
                                <TaskProcessView
                                    permissions={this.state.permissions}
                                    classes={this.state.task.type ? this.state.task.type : []}
                                    plan={this.state.plan || { id: this.state.task.plan_id }}
                                    project={this.state.project || { id: this.state.task.project_id }}
                                    taskType={2}
                                    collection={this.state.collection}
                                    task={this.state.task}
                                    models={this.props.location.state.models} // this is passed to props from training task, for select detector by default
                                    onChangeDetails={(data) => {
                                        if (this.isUserHasEditAccess() && isTaskEditable(this.state.task.status)) {
                                            let task_data = this.state.task
                                            task_data.models = data.models
                                            task_data.task_purpose = data.task_purpose || task_data.task_purpose
                                            task_data.task_parameters = data.task_parameters || task_data.task_parameters
                                            task_data.fps = data.fps
                                            if (!task_data.video_task) {
                                                task_data.fps = null;
                                            }
                                            let steps_data = this.state.steps
                                            steps_data[2].enable = (data.models && data.models.length > 0 && data.task_purpose && data.task_parameters?.output_type) ? true : false
                                            this.setState({
                                                task: task_data,
                                                steps: steps_data,
                                                tourSteps: [{
                                                    disableBeacon: true,
                                                    target: '#button-step3',
                                                    content: (<div style={AppConstants.TEXTSTYLING}><p>Click here to start task</p></div>),
                                                    title: (<div style={AppConstants.TITLESTYLING}>Start Task</div>),
                                                    placement: 'right',
                                                    styles: {
                                                        options: {
                                                            width: 250,
                                                        },
                                                    },
                                                }],
                                            }, () => {
                                                setTimeout(() => {
                                                    this.setState({
                                                        currentlyActiveTour: 2,
                                                        runTour: data.models && data.models.length > 0 && data.task_purpose && getLocalStorageValue('processTour2') == 1 ? true : false
                                                    })
                                                }, AppConstants.TRIGGER_DELAY)
                                            })
                                        }
                                    }
                                    }
                                /> :

                                //step 
                                this.state.currentStep === 4 ?
                                    !this.state.selectedReport ?
                                        <TaskDetails
                                            permissions={this.state.permissions}
                                            user={this.state.user}
                                            closeStartTaskPopup={() => {
                                                this.setState({ openStartTaskPopup: false })
                                            }}
                                            openStartTaskPopup={this.state.openStartTaskPopup}
                                            task={this.state.task}
                                            classes={this.state.classes}
                                            changeView={(type) => {
                                                this.setState({ selectedReport: type == 1 ? 'summary' : 'detailed' })
                                            }}
                                            cancelTask={this.openCancelTaskPopup}
                                            taskImageType={(name) => { this.setState({ taskImgName: name }) }}
                                        /> : this.state.selectedReport == 'summary' ?
                                            <SummaryReport
                                                permissions={this.state.permissions}
                                                user={this.state.user}
                                                type={this.state.type}
                                                task={this.state.task}
                                                plan={this.state.plan || { id: this.state.task && this.state.task.plan_id }}
                                                project={this.state.project || { id: this.state.task && this.state.task.project_id }}
                                                changeView={(type) => {
                                                    this.setState({ selectedReport: type == 1 ? null : 'detailed' })
                                                }}
                                            /> : this.state.selectedReport == 'detailed' ?
                                                this.state.task.linked_map ?
                                                    <>
                                                        <OrthoDetectionReport
                                                            permissions={this.state.permissions}
                                                            showAnnotationTool
                                                            selectedImageForAnnotation={this.state.selectedImageForAnnotation}
                                                            user={this.state.user}
                                                            task={this.state.task}
                                                            onClassAdded={(classes) => {
                                                                this.setState({
                                                                    task: { ...this.state.task, type: classes }
                                                                })
                                                            }}
                                                            updateTask={(task) => {
                                                                this.setState({
                                                                    task,
                                                                    steps: this.state.steps.map((step => step.stepNumber === 3 ? { ...step, completed: false } : step)),
                                                                })
                                                            }}
                                                            changeView={(type) => {
                                                                this.setState({ selectedReport: type == 1 ? null : 'summary' })
                                                            }}
                                                            setAnnotationDone={() => this.setState({ selectedImageForAnnotation: false })}
                                                        />
                                                    </> :
                                                    //Add cinditon here this.state.task.video_task ?
                                                    this.state.task.video_task ? <VideoDetectionResult
                                                        permissions={this.state.permissions}
                                                        data={{ user: this.state.user, task: this.state.task }}
                                                        onClassListUpdated={(classes) => {
                                                            this.setState({
                                                                task: { ...this.state.task, type: classes }
                                                            })
                                                        }}
                                                        changeView={(type) => {
                                                            this.setState({ selectedReport: type == 1 ? null : 'detailed' })
                                                        }}
                                                    /> :
                                                        <DetailedReport
                                                            permissions={this.state.permissions}
                                                            user={this.state.user}
                                                            type={this.state.type}
                                                            task={this.state.task}
                                                            plan={this.state.plan || { id: this.state.task && this.state.task.plan_id }}
                                                            project={this.state.project || { id: this.state.task && this.state.task.project_id }}
                                                            changeView={(type, data) => {
                                                                this.setState({ imageSliderData: data, selectedReport: type == 1 ? null : type == 2 ? 'summary' : 'slider' })
                                                            }}
                                                        /> :


                                                <ImageSlider
                                                    permissions={this.state.permissions}
                                                    data={this.state.imageSliderData}
                                                    onClassListUpdated={(classes) => {
                                                        this.setState({
                                                            task: { ...this.state.task, type: classes }
                                                        })
                                                    }}
                                                    changeView={(type) => {
                                                        this.setState({ selectedReport: type == 1 ? null : 'detailed' })
                                                    }}
                                                />
                                    :
                                    <></>
                    }
                </>
        }

        return (<>
            {this.state.user ?
                <ScreenDivider
                    sidebarConfigurations={sidebarConfigurations}
                    leftSide={leftSide}
                    rightSide={rightSide}
                    topBar={topBar}
                    steps={this.state.steps}
                /> :
                <></>
            }

            {/* -------------- Tour confirmation popup ----------------------- */}

            <AnimatedModal
                height="360px"
                width="500px"
                zIndex={5}
                onRequestClose={this.closeTaskPopup}
                isOpen={this.state.showTourPopup}
            >
                <div className="content-text" style={{ padding: '30px 30px 10px 30px' }}>
                    <div className="services-content-title" style={{ fontSize: "20px", textAlign: 'left' }}>
                        Getting started with Detection
                    </div>
                    <div style={{ textAlign: 'left', fontSize: '12px', marginTop: '10px' }}>
                        We would like to guide you through the process, would you like to take a tour ?
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', height: "190px", alignItems: 'center' }}>
                        <img style={{ height: '180px' }} src={TourGraphics} />
                    </div>

                    <div style={{ width: '100%', marginTop: '10px', display: 'flex', justifyContent: 'end' }}>
                        <button
                            className="addcustomer-button-empty-dashboard popup-btn-right btn-ok"
                            onClick={() => {
                                this.setState({
                                    showTourPopup: false,
                                    showTour: true,
                                    joyrideTrigger: true,
                                })
                            }}
                        >
                            Yes
                        </button>
                        <button style={{ marginLeft: "20px", padding: '8px 30px 7px 30px' }}
                            className="add-user-form-submit-button"
                            onClick={() => {
                                this.setState((state) => ({
                                    ...state,
                                    showTourPopup: false,
                                    showTour: false
                                }), () => {
                                    skipDetectionTaskTour()
                                });
                            }}
                        >
                            No
                        </button>
                    </div>
                </div>

            </AnimatedModal>
            {/* --------------------------------------------------------------- */}
            <ReactJoyride
                continuous={true}
                run={this.state.runTour}
                hideBackButton={true}
                hideCloseButton={true}
                showSkipButton={true}
                steps={this.state.tourSteps}
                disableOverlayClose={true}
                floaterProps={AppConstants.FLOATER_PROPS}
                locale={AppConstants.LOCALE_BUTTONS}
                callback={(e) => this.handleJoyrideCallback(e)}
                styles={{
                    buttonNext: AppConstants.NEXT_BUTTON,
                    buttonSkip: AppConstants.SKIP_BUTTON,
                    spotlight: AppConstants.SPOTLIGHT,
                    options: AppConstants.MAIN_OPTIONS
                }}
            />
        </>);
    }
}

export default DetectionTask

