/*
- this component is used to show images on the map

*/

import React, { Component, useEffect } from "react";
import planIcon from '../icons/map-marker.svg'
import Geocode from "react-geocode";
import LeafletMap from '.././ReusableComponents/map'
import polygonActive from '../assets/toolbarIcons/polygonActive.png'
import pointerInactive from '../assets/toolbarIcons/pointerInactive.png'
import pointerActive from '../assets/toolbarIcons/pointerActive.png'
import polygonInactive from '../assets/toolbarIcons/polygonInactive.png'
import L, { layerGroup } from "leaflet";
import { Modal } from "react-bootstrap";
import "leaflet-editable"
import ReactTooltip from 'react-tooltip'
import * as turf from '@turf/turf'
import * as API from "../api";
import { ActivityPopup } from "./ActivityPopup";
import deleteIcon from '../assets/toolbarIcons/bin.png'
import Export from '../assets/toolbarIcons/exportImages.png';
import redFlag from "../assets/Icons/redFlag.png";

import {
    editEnableEvent,
    drawingEndEvent,
    onUpdateLayer,
} from "../ReusableComponents/map/leafletMeasure.js"
import {
    GoogleApiWrapper,
    LoadingContainer,
} from "google-maps-react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { DRONENAKSHA_FEATURES } from "../Teams";
import AppConstants from "../AppConstants";
Geocode.setApiKey("AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI");
Geocode.setLanguage("en");


let marker = [];
var circles = []

class inspectOnMapDroneNaksha extends Component {

    constructor(props) {
        super(props);
        //this.initMap = this.initMap.bind(this);
        this.state = {
            baseLayer: '',
            map: '',
            pointer: false,
            selectedMarkers: [],
            showProcessPopup: false,
            processPopupIcon: "",
            processAction: "",
            processMessage: "",
            polygon: false,
            selectedImages: [],
            currentlySelectedImages: [],
            showAddImagesetPopup: false,
            collection_name: "",
            error: null,
            csvGCP: [],
            selectedGCP: [],
            markedGCP: [],
        };
    }

    setBaseLayerToState = (baseLayer) => {
        this.setState({ baseLayer })
    }

    mapInitialized = () => {
        const { map } = this.state
        drawingEndEvent(map, this.savePolygonToFeauture)
        onUpdateLayer(map, this.selectImages)
        this.imagesGroup = L.featureGroup().addTo(map);
        this.drawfeatureGroup = L.featureGroup()
        this.getCsvGCP()
        editEnableEvent(map, ((activePolygon) => {
            if (activePolygon.getLatLngs()[0].length < 2) {
                if (this.state.activePolygon) {
                    this.deselectImages(this.state.activePolygon, false)
                }
                this.setState({ activePolygon, polygon: true })
            }
        }))
    }

    inspectGCP = () => {
        let { map } = this.state
        if (this.state.csvGCP && this.state.csvGCP.length > 0) {
            for (let i = 0; i < this.state.csvGCP.length; i++) {
                let center = [Number(this.state.csvGCP[i].latitude), Number(this.state.csvGCP[i].longitude)]
                let radius = Number(this.state.csvGCP[i].radius)
                let label = this.state.csvGCP[i].gcp_name
                // Add the circle for the gcp to the map.
                const cityCircle = L.circle(center, {
                    title: this.state.csvGCP[i].pk_id,
                    color: "#3c3c3c",
                    strokeOpacity: 0.8,
                    weight: 2,
                    fillColor: "#3c3c3c",
                    fillOpacity: 0.8,
                    center: center,
                    radius: radius,
                    zIndex: -1,
                    position: "absolute"
                }).addTo(map)
                var myIcon = L.icon({
                    iconUrl: redFlag,
                    iconSize: [20, 20],
                    iconAnchor: [0, 20],
                    shadowSize: [68, 95],
                    shadowAnchor: [22, 94]
                });
                const marker = L.marker(center, { icon: myIcon }).addTo(map)
                marker.bindTooltip(`<b>GCP : ${label}</b><br>${center[0]},<br> ${center[1]}`)
                circles[i] = cityCircle
            }
            map.setView(new L.LatLng(Number(this.state.csvGCP[0].latitude), Number(this.state.csvGCP[0].longitude)), 16);
        }
    }

    getCsvGCP = async () => {
        API.getCSV_GCP(this.props.collection_id).then((data) => {
            this.setState((state) => ({
                ...state,
                csvGCP: data.gcp_points,
                center: [data.gcp_points[0] ? data.gcp_points[0].latitude : 0, data.gcp_points[0] ? data.gcp_points[0].longitude : 0]
            }))
            API.getGCP(this.props.collection_id).then((data) => {
                if (data.collectionGCP) {
                    this.setState((state) => ({
                        ...state,
                        markedGCP: data.collectionGCP,
                    }));
                } else {
                    this.setState((state) => ({
                        ...state,
                        markedGCP: [],
                    }));
                }
                this.inspectGCP()
                this.inspectOnMap(true)
            })
        })
    }

    setMapCenterFromImage = () => {
        const { map } = this.state
        var i = 0;
        let inspect = this.props.inspect
        for (i = 1; i < inspect.length; i++) {
            if (inspect[i].latitude == null || inspect[i].longitude == null) {
            } else {
                map.setView(new L.LatLng(inspect[i].latitude, inspect[i].longitude), 16);
                break;
            }
        }
        if (i == inspect.length)
            map.setView(new L.LatLng(20.008482, 73.756834), 16);
    }

    deleteMarkers = async () => {
        if (marker.length > 0) {
            marker.map((sMarker) => {
                sMarker.remove(null)
            })
        }
    }

    getCollectionGCP = () => {
        let collectionId = this.props.collection_id
        API.getGCP(collectionId).then((data) => {
            if (data.collectionGCP) {
                this.setState((state) => ({
                    ...state,
                    markedGCP: data.collectionGCP,
                }));
            } else {
                this.setState((state) => ({
                    ...state,
                    markedGCP: [],
                }));
            }
        })
    }

    // Create new imageset and export selected images into it
    createCollection = () => {
        this.setState((state) => ({
            ...state,
            showAddImagesetPopup: false,
            itemName: "Imageset",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: null,
            showProcessPopup: true,
        }))
        API.createImageCollection(this.props.project_id, this.props.plan_id, this.state.collection_name).then((data) => {
            if (data.collection) {
                this.setState((state) => ({
                    ...state,
                    processMessage: "Exporting images to new Imageset, please wait...",
                    showProcessPopup: true,
                }), () => {
                    API.exportImagesToNewCollection(data.collection.id, this.state.currentlySelectedImages, this.state.selectedGCP, AppConstants.SERVICES.DRONENAKSHA).then((data) => {
                        this.setState((state) => ({
                            ...state,
                            itemName: "Imageset",
                            processPopupIcon: "COMPLETE",
                            processAction: "ADD",
                            processMessage: "Images exported successfully",
                            showProcessPopup: true,
                        }))
                    }).catch(err => {
                        console.log(err)
                    })
                })
            } else if (data.status == 409) {
                this.setState((state) => ({
                    ...state,
                    itemName: "Imageset",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Imageset already exist with same name.",
                    showProcessPopup: true,
                }))
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "Imageset",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Something went wrong",
                    showProcessPopup: true,
                }))
            }
        }, (e) => {
            // console.log(e);
            this.setState((state) => ({
                ...state,
                itemName: "Imageset",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: "Something went wrong",
                showProcessPopup: true,
            }))
        })
    }
    // GetSelection = (layer) => {
    // 	var pts = bbTeam.toGeoJSON()
    // 	ptsWithin2 = turf.within(pts, layer.toGeoJSON());
    // 	alert('Found ' + ptsWithin2.features.length + ' features');
    // 	console.log(JSON.stringify(ptsWithin2));
    // 	drawSelection(ptsWithin2);
    // };

    clickOnImageMarker = async (index) => {
        if (this.state.pointer) { //this will select the images
            if (this.selectedImages[index]) {
                await this.deselectImage(index)
                this.selectedImagesThroughPointer[index] = undefined
            } else {
                await this.selectImage(index)
                this.selectedImagesThroughPointer[index] = this.props.inspect[index]
            }
            this.selectedImages.forEach((a) => console.log(a))
        } else { //this will open the image
            this.props.onClickOnPoint(Number(index))
        }
    }

    inspectOnMap = async (firstTime = false) => {
        if (firstTime) this.setMapCenterFromImage();
        await this.deleteMarkers()
        let { map } = this.state
        this.props.inspect.forEach((image, index) => {
            if (image.latitude == null || image.longitude == null || image.latitude == "" || image.longitude == "") {
            } else {
                let lat = Number(image.latitude)
                let lng = Number(image.longitude)
                let latlng = { lat, lng };
                let icon = {
                    iconUrl: planIcon,
                    iconSize: [45, 45],
                    iconAnchor: [22, 22]
                };
                let markedGCP = this.state.markedGCP && this.state.markedGCP.length > 0 ? this.state.markedGCP.findIndex((data) => { return data.image_name == image.file_name }) : -1
                let iconstyle = this.props.icon == 'plan' ? icon : {
                    color: 'black',
                    fillColor: markedGCP > -1 ? '#ffe733' : image.checked == true ? 'red' : '#2989Cf',
                    fillOpacity: 1,
                    radius: 7,
                    weight: 1,
                    title: image.file_name,
                }
                let label = this.props.icon == 'plan' ? image.plan_name ? image.plan_name : "" : image.file_name

                var tempMarker = null
                if (this.props.icon == 'plan') {
                    let marker = L.icon(iconstyle);
                    const tempMarker = L.marker([lat, lng], { icon: marker }).addTo(map).on('click', () => {
                        this.props.onClickOnPoint(Number(index))
                    });
                    tempMarker.bindTooltip(label)
                }
                else {
                    tempMarker = L.circleMarker(latlng, iconstyle).on('click', () => this.clickOnImageMarker(index));
                    tempMarker.bindTooltip(label)
                    this.imagesGroup.addLayer(tempMarker)
                }
                marker[index] = tempMarker
            }
        })
    }

    confirmDelete = () => {
        let selected = []
        this.selectedImages.forEach((image) => {
            if (image) selected.push(image.file_name);
        })
        if (selected.length > 0)
            this.setState((state) => ({
                ...state,
                itemName: "Images",
                processPopupIcon: "WARNING",
                processAction: "DELETE",
                processMessage: `Are you sure you want to delete selected ${selected.length} Images ?`,
                showProcessPopup: true,
            }))
    }

    deleteImages = async () => {
        this.setState((state) => ({
            ...state,
            itemName: "Images",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))
        let selected = []
        this.selectedImages.forEach((image) => {
            if (image) selected.push(image.file_name);
        })
        API.deleteImages(this.props.collection_id, selected, AppConstants.SERVICES.DRONENAKSHA).then((data) => {
            this.state.selectedMarkers.forEach((key) => {
                marker[key].remove()
            });
            if (this.state.activePolygon)
                this.state.activePolygon.remove()
            this.setState((state) => ({
                ...state,
                itemName: "Images",
                processPopupIcon: "COMPLETE",
                processAction: "DELETE",
                processMessage: null,
                showProcessPopup: true,
            }))
            this.props.getImages()
        }, (e) => {
            this.setState((state) => ({
                ...state,
                itemName: "Images",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }

    savePolygonToFeauture = (e) => {
        this.drawfeatureGroup.addLayer(e.layer)
        e.layer.on("click", () => {
            if (this.state.activePolygon) {
                this.deselectImages(this.state.activePolygon, false)
                this.state.activePolygon.setStyle({ fillColor: '#2989Cf', color: "#2989Cf" });
            }
            e.layer.enableEdit()
            this.selectImages(e.layer)
        })
        e.layer.on("contextmenu", async () => {
            if (this.state.activePolygon) {
                const layer = this.state.activePolygon
                this.deselectImages(layer, false)
            }
            this.setState({ activePolygon: e.layer }, () => {
                this.deselectImages(e.layer)
            })
        })
        this.selectImages(e.layer)
        this.setState({ polygon: false })
    }
    selectImage = async (key) => {
        marker[key].setStyle({ fillColor: 'red' });
        let markersData = this.state.selectedMarkers
        markersData.push(key)
        this.setState({ selectedMarkers: markersData })
        this.selectedImages[key] = this.props.inspect[key]
        this.setState({ selectedImages: this.selectedImages })
    }
    deselectImage = async (key) => {
        marker[key].setStyle({ fillColor: '#2989Cf' });
        let markersData = this.state.selectedMarkers
        markersData.splice(markersData.indexOf(key), 1)
        this.setState({ selectedMarkers: markersData })
        this.selectedImages[key] = null
        this.setState({ selectedImages: this.selectedImages })
    }
    deselectImages = (layer, removePolygon = true) => {
        if (layer.getLatLngs()[0].length > 2) {
            marker.forEach(async (point,key) => {
                let pointsInsidePolygon = turf.within(point.toGeoJSON(), layer.toGeoJSON())
                if (pointsInsidePolygon.features.length > 0) {
                    this.deselectImage(key)
                } 
            })
            if (removePolygon) {
                layer.remove()
                this.setState({ activePolygon: false })
            }
            else {
                layer.disableEdit()
                layer.setStyle({ fillColor: '#2989Cf', color: "#2989Cf" });
            }
        }
    }

    selectImages = (layer) => {
        if (layer.getLatLngs()[0].length > 2) {
            layer.setStyle({ fillColor: 'red', color: "red" });
            this.setState({ activePolygon: layer })
            this.selectedImages = []
            marker.forEach(async (point,key) => {
                let pointsInsidePolygon = turf.within(point.toGeoJSON(), layer.toGeoJSON())
                if (pointsInsidePolygon.features.length > 0) {
                    this.selectImage(key)
                } else {
                    if (!(this.selectedImagesThroughPointer[key])) {
                        let markedGCP = this.state.markedGCP && this.state.markedGCP.length > 0 ? this.state.markedGCP.findIndex((data) => { return data.image_name == point.options.title }) : -1
                        marker[key].setStyle({ fillColor: markedGCP > -1 ? '#ffe733' : '#2989Cf' });
                    }
                    else this.selectImage(key)
                }
            })
            if (this.state.csvGCP.length > 0)
                this.selectGCPs(layer)
        } else {
            this.state.activePolygon.enableEdit()
        }
    }

    selectGCPs = (layer) => {
        if (layer.getLatLngs()[0].length > 2) {
            let selectedGCPs = []
            circles.forEach(async (point) => {
                let pointsInsidePolygon = turf.within(point.toGeoJSON(), layer.toGeoJSON())
                if (pointsInsidePolygon.features.length > 0) {
                    let gcp = this.state.csvGCP.find((data) => { return data.pk_id == point.options.title })
                    let markedGCPData = this.state.markedGCP.filter((gcp) => { return gcp.gcp_point_id == point.options.title })
                    selectedGCPs.push({ "gcp": gcp, "markedGCP": markedGCPData })
                }
            })
            this.setState({ selectedGCP: selectedGCPs })
        }
    }

    handleChange = (e) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(e.target.value)) {
            this.setState({ error: "Spacial characters are not allowed." });
        } else if (e.target.value.includes(' ')) {
            this.setState({ error: "Spaces are not allowed." });
        } else {
            this.setState({ error: null, collection_name: e.target.value });
        }
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
        }))
    }

    componentWillMount() {
        marker = []
        this.selectedImages = []
        this.selectedImagesThroughPointer = []
    }

    render() {
        return (
            <>
                <div style={{ height: this.props.height ? this.props.height : '100vh', width: this.props.width ? this.props.width : '100vw', position: 'relative' }}>
                    {/* -------------- Activity Popup ----------------------- */}
                    <ActivityPopup
                        item={this.state.itemName}
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        action={this.state.processAction}
                        msg={this.state.processMessage}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => this.deleteImages()}
                    />
                    {/* --------------------------------------------------------------- */}

                    {/* ------- Create collection popup ------ */}
                    <Modal
                        style={{ marginTop: '150px' }}
                        show={this.state.showAddImagesetPopup}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                showAddImagesetPopup: false,
                                collection_name: ''
                            }));
                        }}
                    >
                        <Modal.Body >
                            <div style={{ marginBottom: "30px" }}>
                                <div className="services-content-title" >{`Create an Imageset`}</div>
                                <label className="font-color-secondary" style={{ fontSize: "12px" }}>{`Selected images : ${this.state.selectedImages.filter((image) => image).length}`}</label>
                                <label className="font-color-secondary" style={{ fontSize: "12px" }}>To export images you need to create an Imageset.</label>
                                <input type="text" value={this.state.collection_name} className="add-user-form-text" placeholder="eg. Imageset_1"
                                    onChange={this.handleChange} />
                                <div style={{
                                    color: "red",
                                    fontSize: "12px",
                                    padding: "5px 0 0 3px"
                                }}>{this.state.error}</div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        showAddImagesetPopup: false,
                                    }));
                                }}
                            >
                                cancel
                            </button>
                            {this.state.collection_name ?
                                <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    onClick={this.createCollection}
                                >
                                    Export
                                </button> :
                                <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    style={{ opacity: '0.5', cursor: 'default' }}
                                >
                                    Export
                                </button>}

                        </Modal.Footer>
                    </Modal>
                    {/* -------------------------------------- */}

                    {this.props.icon !== 'plan' ?
                        <div style={{ position: "absolute", right: "10px", zIndex: 1, height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", zIndex: 1, height: "150px", width: "40px", background: "white", borderRadius: "7px", alignItems: "center" }}>
                                <div style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        this.setState({ pointer: !this.state.pointer })
                                    }}>
                                    <img src={this.state.pointer ? pointerActive : pointerInactive} data-tip data-for='pointer' width='20px' height="20px" />
                                    <ReactTooltip offset={{ left: 7 }} id="pointer" place="left" type="light" effect="solid"><span>Select images using pointer</span></ReactTooltip>
                                </div>
                                <div style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        if (this.state.polygon) {
                                            this.setState({ polygon: false })
                                            this.state.activePolygon.disableEdit()
                                        } else {
                                            this.state.map.editTools.startPolygon()
                                        }
                                    }}>
                                    {this.state.polygon ? <img src={polygonActive} data-tip data-for='polygon' width='20px' height="20px" /> : <img data-tip data-for='polygon' src={polygonInactive} width='20px' height="20px" />}
                                    <ReactTooltip offset={{ left: 7 }} id="polygon" place="left" type="light" effect="solid"><span>Select images using polygon</span></ReactTooltip>
                                </div>
                                <div style={this.props.inspect.length > 0 && !this.props.cannotDelete && this.state.selectedMarkers.length > 0 ? { cursor: "pointer" } : { opacity: 0.4 }}
                                    onClick={() => {
                                        if (this.props.inspect.length > 0 && !this.props.cannotDelete && this.state.selectedMarkers.length > 0)
                                            this.confirmDelete()
                                    }}>
                                    <img src={deleteIcon} data-tip data-for='delete' width='20px' height="20px" />
                                    <ReactTooltip offset={{ left: 7 }} id="delete" place="left" type="light" effect="solid"><span>Delete images</span></ReactTooltip>
                                </div>
                                <div style={this.props.inspect.length > 0 && this.state.selectedMarkers.length > 0 && this.props.permissions[DRONENAKSHA_FEATURES.MAPS]?.DOWNLOAD ? { cursor: "pointer" } : { opacity: 0.4 }}
                                    onClick={async () => {
                                        let selectedImages = []
                                        this.selectedImages.forEach((image) => {
                                            if (image) selectedImages.push(image);
                                        })
                                        if (this.props.inspect.length > 0 && this.state.selectedMarkers.length > 0 && this.props.permissions[DRONENAKSHA_FEATURES.MAPS]?.DOWNLOAD)
                                            this.setState({ collection_name: "", currentlySelectedImages: selectedImages, showAddImagesetPopup: true, error: null })
                                    }}>
                                    <img src={Export} data-tip data-for='export' width='22px' height="22px" />
                                    <ReactTooltip offset={{ left: 7 }} id="export" place="left" type="light" effect="solid"><span>Export images</span></ReactTooltip>
                                </div>
                            </div>
                        </div> : <></>}

                    <LeafletMap
                        setBaseLayerToState={this.setBaseLayerToState}
                        initCenter={this.state.center ? this.state.center : [20.008482, 73.756834]}
                        bounds={this.state.bounds}
                        map={this.state.map}
                        maxZoom={24}
                        initZoom={16}
                        handleMapClick={this.handleMapClick}
                        handleMoveStart={this.handleMoveStart}
                        setMap={(map) => {
                            this.setState({ map: map }, () => {
                                this.mapInitialized()
                            })
                        }}
                    >
                        <div className='content-text' style={{ fontSize: "12px", fontWeight: "400", zIndex: 1, left: "10px", top: "10px", position: "absolute", color: "white" }}>
                            <div>Selected Images : {this.state.selectedImages.filter((image) => image).length}</div>
                        </div> </LeafletMap>
                </div>
            </>
        );
    }
}


export default GoogleApiWrapper({
    apiKey: "AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI",
    libraries: ["drawing", "places"],
    LoadingContainer: LoadingContainer,
})(inspectOnMapDroneNaksha);