import React, { useEffect, useState } from 'react';
import { AnimatedModal } from '../ReusableComponents/AnimatedModal';
import Close from "../icons/blackClose.png"
import DropDownWithButton from '../ReusableComponents/DropDownWithButton';
import ButtonWithIcon from '../ReusableComponents/ButtonWithIcon';
import { getLimitedName } from '../ReusableComponents/reusableFunctions';
import * as API from "../api"
import { ActivityPopup } from '../ReusableComponents/ActivityPopup';
import warningIcon from "../icons/warningIcon.png"
import { setActiveRole } from '.';
const PlannerTaskDetails = ({ task, closeTaskDetails, formatAMPM, setAssignedTasks, user }) => {
    const [status, setStatus] = useState(task.status)
    const updateTask = async (taskStatus) => {
        setStatus(taskStatus)
        API.updateAssignedTask({ ...task, status: taskStatus }).then(res => setAssignedTasks(prev => prev.map(task => {
            if (task.id === res.updatedTask.id) {
                return res.updatedTask
            } else return task
        })))
    }
    const setInputDate = (dateString) => {
        const dateObj = new Date(dateString);

        // Get the date value in the format YYYY-MM-DD
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        const dateValue = `${year}-${month}-${day}`;
        return dateValue
    }
    const [activityPopUp, setActivityPopUp] = useState({ itemName: "Warning", showProcessPopup: false, processPopupIcon: "", msg: "You dont have access to this project", })
    return <AnimatedModal
        width="600px"
        height="520px"
        isOpen={true}
        onRequestClose={closeTaskDetails}
    >
        <ActivityPopup
            action=""
            title="Warning"
            open={activityPopUp.showProcessPopup}
            icon={activityPopUp.processPopupIcon}
            msg={activityPopUp.msg}
            close={() => setActivityPopUp(prev => ({ ...prev, showProcessPopup: false }))}
            onClickOk={() => setActivityPopUp(prev => ({ ...prev, showProcessPopup: false }))}
        />
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            {/* top bar */}
            <div>
                <div style={{ backgroundColor: "white", padding: "10px", fontSize: "23px", color: "black", borderBottom: "1px solid #dbdbdb", textAlign: "center" }}>Task Details
                    <div style={{ position: "absolute", right: "17px", top: "17px", width: "20px", height: "20px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={closeTaskDetails}><img style={{ width: "100%", height: "100%" }} src={Close} /></div>
                </div>

            </div>
            <div style={{ padding: "20px 44px", display: "flex", flexDirection: "column", height: "100%", backgroundColor: "rgb(243 243 243)" }}>

                {/* description */}
                <div style={{ display: "flex", flexDirection: "column", alignItems: "start", marginBottom: "",backgroundColor: "", borderRadius: "5px", }}>
                    <h5 style={{ fontSize: "11px",color:"rgb(60 60 60 / 61%)",margin:"0" }}>Description</h5>
                    <p style={{ width: "100%", wordWrap:"break-word",fontWeight:"",color:"black", borderRadius: "5px", textAlign: "left" }} >{task.description ? task.description : "-"}</p>
                </div>
                {/* first part */}
                <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(147px, 1fr))", justifyItems: "center", rowGap: "20px", columnGap: "70px" }}>

                    {/* time and date */}
                    <div style={{ width: "100%",backgroundColor: "", borderRadius: "5px", }}>
                        <h5 style={{ fontSize: "11px", textAlign: "left",color:"rgb(60 60 60 / 61%)",margin:"0" }}>Time and Date</h5>
                        <div style={{ width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textAlign: "left",fontWeight:"",color:"black", borderRadius: "5px" }}>{`${setInputDate(task.due_date)}, ${formatAMPM(task.due_date)}`}</div>
                    </div>
                    {(task?.assignedBy?.first_name || task?.assignedBy?.last_name) && <div style={{ width: "100%",backgroundColor: "", borderRadius: "5px", }}>
                        <h5 style={{ fontSize: "11px", textAlign: "left",color:"rgb(60 60 60 / 61%)",margin:"0" }}>Assigned By</h5>
                        <div style={{ width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", border: "none", outline: "none", textAlign: "left",fontWeight:"",color:"black", borderRadius: "5px" }} >{`${task?.assignedBy?.first_name ? task?.assignedBy?.first_name : ""} ${task?.assignedBy?.last_name ? task?.assignedBy?.last_name : ""}`}</div>
                    </div>}
                    {task.team.team_name && <div style={{ width: "100%",backgroundColor: "", borderRadius: "5px", }}>
                        <h5 style={{ fontSize: "11px", textAlign: "left",color:"rgb(60 60 60 / 61%)",margin:"0" }}>Team Name</h5>
                        <div style={{ width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", border: "none", outline: "none", textAlign: "left",fontWeight:"",color:"black", borderRadius: "5px" }} >{task.team.team_name ? task.team.team_name : "-"}</div>
                    </div>}

                    {/* second part */}

                    {task?.type && <div style={{ width: "100%",backgroundColor: "", borderRadius: "5px", }}>
                        <h5 style={{ fontSize: "11px", textAlign: "left",color:"rgb(60 60 60 / 61%)",margin:"0" }}>Type</h5>
                        <div style={{ width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", border: "none", outline: "none", textAlign: "left",fontWeight:"",color:"black", borderRadius: "5px" }} >{task.type ? task.type : "-"}</div>
                    </div>}
                    {task?.project?.project_name && <div style={{ width: "100%",backgroundColor: "", borderRadius: "5px",backgroundColor: "", borderRadius: "5px", }}>
                        <h5 style={{ fontSize: "11px", textAlign: "left",color:"rgb(60 60 60 / 61%)",margin:"0" }}>Project</h5>
                        <div style={{ width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", border: "none", outline: "none", textAlign: "left",fontWeight:"",color:"black", borderRadius: "5px" }} >{task.project.project_name ? task.project.project_name : "-"}</div>
                    </div>}
                    {task?.plan?.plan_name && <div style={{ width: "100%",backgroundColor: "", borderRadius: "5px",  }}>
                        <h5 style={{ fontSize: "11px", textAlign: "left",color:"rgb(60 60 60 / 61%)",}}>Plan</h5>
                        <div style={{ width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", border: "none", outline: "none", textAlign: "left",fontWeight:"",color:"black", borderRadius: "5px" }} >{task.plan.plan_name ? task.plan.plan_name : "-"}</div>
                    </div>}

                    {/* third part */}
                    {task?.collection?.collection_name && <div style={{ width: "100%", borderRadius: "5px",  }}>
                        <h5 style={{ fontSize: "11px", textAlign: "left",color:"rgb(60 60 60 / 61%)",backgroundColor: "",margin:"0" }}>Image Set</h5>
                        <div style={{ width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", border: "none", outline: "none", textAlign: "left",fontWeight:"",color:"black", borderRadius: "5px" }} >{task?.collection?.collection_name ? getLimitedName(task.collection.collection_name, 20) : "-"}</div>
                    </div>}
                    {task?.task?.task_name && <div style={{ width: "100%", borderRadius: "5px",  }}>
                        <h5 style={{ fontSize: "11px", textAlign: "left",color:"rgb(60 60 60 / 61%)",backgroundColor: "",margin:"0" }}>Task</h5>
                        <div style={{ width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", border: "none", outline: "none", textAlign: "left",fontWeight:"",color:"black", borderRadius: "5px" }} >{task?.task?.task_name ? task.task.task_name : "-"}</div>
                    </div>}
                    <div style={{ width: "100%" }}>
                        <h5 style={{ fontSize: "11px", textAlign: "left",color:"rgb(60 60 60 / 61%)",margin:"0" }}>Status</h5>
                        <div style={{ backgroundColor: "white", outline: "1px solid #c6c6c6", padding: "0px 10px", borderRadius: "5px", textAlign: "left" }}>
                            <DropDownWithButton width="100%" selectedItem={status} list={[{ name: "To do", onClick: () => updateTask("To do") }, { name: "In Progress", onClick: () => updateTask("In Progress") }, { name: "Completed", onClick: () => updateTask("Completed") }]} />
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "end", marginTop: "auto" }}>
                    {task.linkData?.pathname ? (
                        task.projectRole ?
                            <ButtonWithIcon text="View Task"
                                onClick={() => {
                                    setActiveRole(task.projectRole)
                                }}
                                linkData={{ ...task?.linkData, state: { ...task?.linkData.state, user } }}
                                isBtnActive fontSize={"15px"} />
                            :
                            <ButtonWithIcon text="View Task" onClick={() => setActivityPopUp(prev => ({ ...prev, showProcessPopup: true }))} fontSize={"15px"} isBtnActive />
                    ) : <></>}
                </div>
            </div>
        </div>
    </AnimatedModal>
}
export default PlannerTaskDetails