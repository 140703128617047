import React from "react";
import { FILETYPES } from "./Constants";
import DropDownWithButton from "../../../ReusableComponents/DropDownWithButton";
import { PICSTORK_FEATURES } from "../../../Teams";

const ImportExportRegions = (props) => {
    return <div style={{ display: "flex" }}>
        <DropDownWithButton
            isDisabled={!props.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].EDIT || props.task?.sharedBy?.access_level}
            selectedItem="Import" width="94px" isButton
            list={[
                {
                    name: "GeoJSON",
                    onClick: () => props.importRegions(FILETYPES.JSON)
                },
                //  {
                //     name: "Shape File",
                //     onClick: () => props.importRegions(FILETYPES.ZIP)
                // }
            ]}
        />

        <DropDownWithButton
            selectedItem="Export" width="94px"
            isButton
            isDisabled={!(props.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].DOWNLOAD && props.regions)}
            list={[
                {
                    name: "GeoJSON",
                    onClick: () => props.exportRegions("geoJson")
                },
                // {
                //     name: "Shape File",
                //     onClick: () => props.exportRegions("shapeFile")
                // }
            ]}
        />
    </div>
}

export default ImportExportRegions