import { CHANGE_PLACE } from "./mapTypes";

const initialState = {
 center: {
  lat: 19.9975,
  lng: 73.7898,
}
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PLACE:
     return {
        ...state,
        center: action.payload,
      };
    default:
      return state;
  }
};
export default mapReducer;
