import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player/file'
import LoaderComponent from '../../../ReusableComponents/LoaderComponent'
const VideoPlayer = ({ url, onVideoLoad }) => {
    const [error, setError] = useState('')
    return (
        error ?
            <div style={{
                width: '100%',
                height: '100%',
                background: 'black',
                color: '#ffffff94',
                fontSize: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div style={{ maxWidth: "400px", textAlign: 'center' }}>{error}</div>
            </div>
            :
            url ? (
                <ReactPlayer
                    url={url}
                    controls
                    width="100%"
                    height="100%"
                    playing
                    onError={(event) => {
                        console.log(event.target.error)
                        setError('Sorry, this video format is not supported by your browser. Please try using a different browser or convert the video to a more common format like MP4.')
                    }}
                    onReady={() => {
                        if (onVideoLoad) {
                            onVideoLoad()
                        }
                    }}
                />
            ) : (
                <LoaderComponent height={'100%'} width={'100%'} />
            )
    )
}

export default VideoPlayer