import React, { Component } from 'react';
import * as API from '../../api.js';
import Loader from '../../icons/loader.svg';
import Users from '../../usersActive.png';
import service from "../../sidebaricon/Services.png";
import SideBar from '../../SideBar';
import dateFormat from 'dateformat';
import AppConstants from '../../AppConstants.js';

export default class SupportEmails extends Component {

    state = {
        user: {},
        type: "",
        currentpage: 1,
        userperpage: 10,
        counts: 0,
        key: " ",
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        createIcon: "",
        popupMessage: "",
        addemailPopup: false,
        record: null,
        status: true,
        analytics: [],
        showAddemailPopup: false,
        statusFilter: "all",
        editTaskPopup: false,
        cancel: false,
        loading: true
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    onChangeListener = (e) => {
        let t = e.target;
        this.setState((state) => ({
            ...state,
            [t.name]: t.value
        }))
    }

    openAddemailPopup = () => {
        this.setState(state => ({
            ...state,
            record: null,
            status: true,
            addemailPopup: true
        }))
    }

    handleSwitchChange = (event) => {
        let t = event.target;
        this.setState((state) => ({
            ...state,
            [t.name]: t.checked,
        }));
    };

    componentWillMount() {
        document.title = "User analytics - Admin";
        if (this.props.location.state) {
            let { user, type, user_info, page_name } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type,
                user_info,
                page_name
            }), async () => {
                this.getUserPageAnalytics();
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type,
                    analytics: []
                }))
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    search(key) {
        this.setState((state) => ({
            ...state,
            key,
        }),
            () => this.getUserPageAnalytics());
    }

    getUserPageAnalytics = async () => {
        const { user_info, page_name } = this.state;
        let pages = AppConstants.PAGES
        await API.getUserPageAnalytics(user_info.userid, page_name).then(async (data) => {
            let analytics = data.userPageAnalytics
            analytics.forEach(element => {
                let data = pages.find((data) => { return data.PAGE_NAME == element.page_name })
                element.route = data ? data.ROUTE : null
                element.name = data ? data.DISPLAY_NAME : null
            });
            this.setState((state) => ({
                ...state,
                analytics: analytics,
                loading: false,
            }))
        }, (e) => {
            console.log(e);
        })
    }

    render() {
        const { id, user, type, user_info, page_name } = this.state;
        // const lastIndex = currentpage * userperpage;
        // const firstpage = lastIndex - userperpage;
        // const currentuser = userdisplay.slice(firstpage, lastIndex);
        // const totalpages = userdisplay.length / userperpage;
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },

                {
                    icon: Users,
                    text: "User Analytics",
                    path: "/analytictool/user_statistics",
                    selected: true
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode
        }
        return (
            this.state.user && this.state.user.first_name ? <div className="wrapper">
                <SideBar config={sidebarConfigurations} parentSidebarSwitch={this.parentSidebarSwitch} />
                <div className="right-content"
                    style={{ zIndex: 0 }}
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <div className="top-bar" style={{ display: "flex" }}><div className="top-bar-text">User Page Analytics - {page_name}</div>
                    </div>
                    <div className="main-content" style={{ height: "93%" }}>
                        {this.state.loading ? (
                            <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                                <img src={Loader} style={{ height: "20vh", width: "100vw", margin: "auto" }} />
                            </div>) : (
                            <div>
                                <div style={{ margin: "1% 1%", display: "flex", width: "100%", justifyContent: "left" }}>
                                    <div className="services-content-title">{user_info.first_name} {user_info.last_name} <span style={{ fontSize: "12px", color:"#2989cf" }}> - {user_info.username}</span></div>
                                </div>
                                <div style={{ height: "80vh", overflowY: 'auto' }}>
                                    <table className="customers-table" style={{ marginTop: '0px' }}>
                                        <tr className="customers-table-header-row">
                                            <th>No.</th>
                                            <th>Page Name</th>
                                            <th>Route</th>
                                            <th>In time</th>
                                            <th>In IP</th>
                                            <th>Out time</th>
                                            <th>Out IP</th>
                                            <th>Duration</th>
                                        </tr>
                                        {this.state.analytics.length === 0 ?
                                            <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                                <td colspan="8">No Records Available</td>
                                            </tr> :
                                            this.state.analytics.map((record, i) =>
                                                <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                    <td>{i + 1}.</td>
                                                    <td>{record.name}</td>
                                                    <td>{record.route}</td>
                                                    <td>{dateFormat(record.date_timestamp, 'dd mmm yyyy hh:MM:ss TT')}</td>
                                                    <td>{record.in_ip ? record.in_ip : '-'}</td>
                                                    <td>{record.out_time ? dateFormat(record.out_time, 'dd mmm yyyy hh:MM:ss TT') : '-'}</td>
                                                    <td>{record.out_ip ? record.out_ip : '-'}</td>
                                                    <td>{record.duration > 60 ? Math.trunc(record.duration / 60) + ' M : ' + Math.trunc(record.duration % 60) + ' s' : Math.round(record.duration) + ' s'}</td>
                                                </tr>
                                            )}
                                    </table>
                                </div>
                            </div>
                        )
                        }
                    </div>
                </div>
            </div> : <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
            </div>
        );
    }
}
