import React, { Component } from "react";
import * as API from "../api.js";
import { Link, StaticRouter, Redirect } from "react-router-dom";
import aeromeghLogo from './assets/aeromeghLogo.png'
import emailIcon from './assets/email.svg'
import userIcon from './assets/user.svg'
import lockIcon from './assets/lock.svg'
import tickLockIcon from './assets/ticklock.svg'
import googleIcon from './assets/google.png'
import Cookies from 'universal-cookie';
import Loader from '../icons/loader.svg'
import microsoftIcon from './assets/microsoft.png'
import { ActivityPopup } from '../ReusableComponents/ActivityPopup.js';
import CookiePolicyPopup from "./CookiePolicyPopup.js";

const cookie = new Cookies()
const query = new URLSearchParams(window.location.search);

class userSignup extends Component {
    state = {
        first_name: "",
        last_name: "",
        password: "",
        passwordVerify: "",
        address: "",
        email: "",
        error: "",
        registrationType: "individual",
        checked: false,
        signUpSuccess: false,
        loading: false,
    }

    componentWillMount() {
        if (query.get("token")) {
            this.setState((state) => ({
                ...state,
                loading: true,
            }))
            cookie.set("session_IAMAero", query.get("token"), {
                path: "/"
            });
            window.location.replace("/")
        }
        document.title = "Aeromegh - Signup";
    }

    componentDidMount() {
        setTimeout(() => {
            if (query.get("error")) {
                let msg = query.get("error") == 0 ? "Google authentication failed, please try again." : "Microsoft authentication failed, please try again."
                this.setState((state) => ({
                    ...state,
                    itemName: "Login",
                    processPopupIcon: "ERROR",
                    processAction: "",
                    processMessage: msg,
                    showProcessPopup: true,
                }))
            }
        }, 1000)

    }

    onFormSubmit = (formData) => {
        formData.preventDefault()
        if (this.state.password === this.state.passwordVerify) {
            API.signUp(this.state).then((e) => {
                this.setState({ signUpSuccess: true })
            })
                .catch((e) => {
                    e.then(error => {
                        this.setState({
                            error: undefined, error: error.message || "Something went wrong..."
                        })
                    })

                })
        } else {
            this.setState({
                error: "Passwords do not match"
            })
        }
    }

    googleLogin = () => {
        window.location.replace(`${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_HOST}${process.env.REACT_APP_SERVER_PORT ? process.env.REACT_APP_SERVER_PORT : ''}/auth/google`)
    }

    microsoftLogin = () => {
        window.location.replace(`${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_HOST}${process.env.REACT_APP_SERVER_PORT ? process.env.REACT_APP_SERVER_PORT : ''}/auth/microsoft`)
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
        }))
    }
    
    render() {
        return (
            <div>
                <ActivityPopup
                    item={this.state.itemName}
                    open={this.state.showProcessPopup}
                    icon={this.state.processPopupIcon}
                    action={this.state.processAction}
                    msg={this.state.processMessage}
                    close={() => this.closeActivityPopup()}
                    onClickOk={() => this.closeActivityPopup()}
                >

                </ActivityPopup>

                {this.state.loading ?
                    <div style={{ height: "100vh", width: "100vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{
                            height: "30vh",
                            width: "100vw",
                            margin: "auto"
                        }} />
                    </div>
                    :
                    <div className="authPage">
                        <CookiePolicyPopup />
                        {this.state.signUpSuccess ? <div className="cardLogin login-card" style={{ height: '32rem', widthL: '23rem', paddingTop: '40px' }} >
                            <img src={aeromeghLogo} className="login-logo" />
                            <p className="greet login-greet" style={{ marginBottom: '5px' }}>Thanks for registering</p>
                            <div>
                                <div className="content-text" style={{ padding: '60px 40px', fontSize: "16px" }}>
                                    An account activation link has been sent to your email.
                                </div>
                                <div class="spacer" style={{ height: '4.9vh' }}> </div>
                                <Link to={{
                                    pathname: "/login",
                                }}>
                                    <button style={{ cursor: 'pointer' }} className="loginButton" name="loginButton"
                                    >LOGIN</button>
                                </Link>
                                <div class="spacer" style={{ height: '4.9vh' }}> </div>
                            </div>
                        </div> : <><form className="cardLogin login-card" id="signupfrm" style={{ height: '39rem', width: '23rem', paddingTop: "0px" }} onSubmit={this.onFormSubmit}>
                            <img src={aeromeghLogo} className="login-logo" />
                            <p className="greet login-greet" style={{ marginBottom: '5px' }}>SIGN UP TO AEROMEGH</p>
                            <div className="login-error1">{this.state.error || <></>} </div>
                            <div className="add-user-form-row1">
                                <div className="add-user-form-row1-left">
                                    <div className="add-user-form-text-wrapper1">
                                        <img id="signup-fname-icon" src={userIcon} />
                                        <input
                                            autoFocus
                                            onChange={(e) => {
                                                this.setState({
                                                    error: undefined,
                                                    first_name: e.target.value
                                                })
                                            }} className="add-user-form-text-login loginSignupInput" id="first_name" name="first_name" type="text"
                                            placeholder="First Name" required pattern="([a-zA-Z]{3,30}\s*)+" style={{ width: '128px' }}
                                            title="First Name must contain only upper case and lower case characters, at least 3 character, maximum 29 characters" />
                                    </div>
                                </div>
                                <div className="add-user-form-row1-right">
                                    <div className="add-user-form-text-wrapper1">
                                        <img id="signup-lname-icon" src={userIcon} />
                                        <input onChange={(e) => {
                                            this.setState({
                                                error: undefined,
                                                last_name: e.target.value
                                            })
                                        }} className="add-user-form-text-login loginSignupInput" id="last_name" name="last_name" type="text"
                                            placeholder="Last Name" required pattern="([a-zA-Z]{3,30}\s*)+" style={{ width: '128px' }}
                                            title="Last Name must contain only upper case and lower case characters, at least 3 character, maximum 29 characters" />
                                    </div>
                                </div>
                            </div>
                            <div className="add-user-form-row1">
                                <div className="add-user-form-text-wrapper1">
                                    <img id="signup-email-icon" src={emailIcon} />
                                    <input onChange={(e) => {
                                        this.setState({
                                            error: undefined,
                                            email: e.target.value.toLowerCase()
                                        })
                                    }} className="add-user-form-text-login loginSignupInput" id="email" name="email" type="email" placeholder="E-mail"
                                        style={{ width: '17.6rem' }}
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required maxLength={100}/>
                                </div>
                            </div>
                            <div className="add-user-form-row1">
                                <div className="add-user-form-text-wrapper1">
                                    <img id="signup-pass-icon" src={lockIcon} />
                                    <input onChange={(e) => {
                                        this.setState({
                                            error: undefined,
                                            password: e.target.value
                                        })
                                    }} className="add-user-form-text-login loginSignupInput" id="password" name="password" type="password" placeholder="Password"
                                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" style={{ width: '17.6rem' }}
                                        title="Password must contain at least 1 upper case character, at least 1 lower case character, at least 1 digit and 8 total characters"
                                        required />
                                </div>
                            </div>


                            <div className="add-user-form-row1">
                                <div className="add-user-form-text-wrapper1">
                                    <img id="signup-cpass-icon" src={tickLockIcon} />
                                    <input onChange={(e) => {
                                        this.setState({
                                            error: undefined,
                                            passwordVerify: e.target.value
                                        })
                                    }} className="add-user-form-text-login loginSignupInput" id="cpassword" name="password" type="password"
                                        placeholder="Confirm Password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" style={{ width: '17.6rem' }}
                                        title="Password must contain at least 1 upper case character, at least 1 lower case character, at least 1 digit and 8 total characters"
                                        required />
                                </div>
                            </div>
                            {/* </div> */}
                            <div className="signup" style={{ margin: 'auto', color: '#898989' }}>
                                <input type="checkbox" className="signup" id="tnc" name="tnc"
                                    onChange={() => {
                                        this.setState({
                                            error: undefined,
                                            checked: !this.state.checked
                                        })
                                    }}
                                    style={{ display: 'flex' }} />I agree to <a className="signup"
                                        href="https://aeromegh.com/index.php/end-user-license-agreement-eula/" target="_blank"
                                        style={{ color: '#898989' }}><u>terms and conditions</u></a>
                            </div>
                            <div className="spacer" style={{ height: '1.9vh' }}> </div>
                            <button type="submit" className={(!this.state.checked || this.state.first_name === "" || this.state.last_name === "" || this.state.email === "" || this.state.password === "" || this.state.passwordVerify === "") ? "loginButtonDisabled" : "loginButton"} name="loginButton" id="loginButton"
                                disabled={(!this.state.checked || this.state.first_name === "" || this.state.last_name === "" || this.state.email === "" || this.state.password === "" || this.state.passwordVerify === "")}>Sign Up</button>
                            <div className="spacer" style={{ height: '1.9vh' }}> </div>
                            <div className="signup" style={{ marginLeft: 'auto', marginRight: '41px' }}>
                                Already have an account?
                                <Link className="login-signup-button" to={{
                                    pathname: "/login",
                                }} style={{ marginLeft: '4px' }}><u>Login</u></Link>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <div className="signup" style={{ display: 'flex', width: '18rem', alignItems: 'center', textAlign: 'center', fontSize: '12px', justifyContent: 'center', marginTop: '10px' }}>
                                    <hr style={{ width: '4rem', borderTop: '1px solid #c1c1c1', marignRight: '10px' }} />
                                    <span style={{ margin: '0px -10px' }}>
                                        Or Signup with
                                    </span>
                                    <hr style={{ width: '4rem', borderTop: '1px solid #c1c1c1', marignLeft: '10px' }} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ display: 'flex', width: '18rem', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                                    <div className="signup sigin-oauth"
                                        style={{ display: 'flex', border: '1px solid #c1c1c1', height: '40px', cursor: 'pointer', width: '8.5rem', alignItems: 'center', textAlign: 'center', justifyContent: 'center', marginTop: '5px', borderRadius: '3px' }}
                                        onClick={() => {
                                            this.googleLogin()
                                        }}
                                    >
                                        <img src={googleIcon} style={{ height: '17px', width: '17px', marginRight: '10px' }} />
                                        Google
                                    </div>

                                    <div className="signup sigin-oauth"
                                        style={{ display: 'flex', border: '1px solid #c1c1c1', height: '40px', cursor: 'pointer', width: '8.5rem', alignItems: 'center', textAlign: 'center', justifyContent: 'center', marginTop: '5px', borderRadius: '3px' }}
                                        onClick={() => {
                                            this.microsoftLogin()
                                        }}
                                    >
                                        <img src={microsoftIcon} style={{ height: '17px', width: '17px', marginRight: '10px' }} />
                                        Microsoft
                                    </div>
                                </div>
                            </div>
                        </form></>}

                    </div>
                }
            </div>
        );
    }
}

export default userSignup
