// react
import React, { useEffect } from 'react';
import L from "leaflet"
import "leaflet-draw";
import "leaflet-editable"
import "leaflet.path.drag"
import { EXTENDBOUNDS, MAP_URLS } from '../../AppConstants';

// leaflet

function SingleMap(props) {
  useEffect(() => {
    var map = L.map('map', {
      maxZoom: props.maxZoom ? props.maxZoom : 28,
      zoomControl: false,
      editable: true,
      center: props.initCenter,
      zoom: 16,
    })
    map.createPane("tooltips")
    map.getPane('tooltips').style.zIndex=10000
    props.setMap(map)
    if (props.bounds) {
      let bounds = [[props.bounds[1] - EXTENDBOUNDS, props.bounds[0] - EXTENDBOUNDS], [props.bounds[3] + EXTENDBOUNDS, props.bounds[2] + EXTENDBOUNDS]]
      map.fitBounds(bounds);
    }
    else {
      map.fitBounds([[26.798175480657605, 82.7132375946003], [26.82286941676843, 82.75360145561896]]);
    }
    let baseLayer = L.tileLayer(props.initBaseLayerURL || MAP_URLS.SATELITE, {
      attribution: 'Imagery ©2022 CNES / Airbus, Landsat / Copernicus, Maxar Technologies, Map data ©2022',
      maxZoom: props.maxZoom,
      center: props.initCenter,
      zoom: 18,
      tileSize: 256,
    }).addTo(map);
    if (props.setBaseLayerToState) props.setBaseLayerToState(baseLayer);
    if (props.handleMapClick) map.on('click', props.handleMapClick);
    if (props.handleMoveStart) map.on('movestart', props.handleMoveStart);

  }, [])


  // render component
  return (
    <>
      {props.children}
      <div style={props.style}
        id="map">
        {props.innerMapChildren ? props.innerMapChildren : <></>}
      </div>
    </>
  )

}

export default SingleMap
